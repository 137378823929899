import {
  // Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  // TextField,
} from '@mui/material';
import { t } from 'i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';
import React, { memo, useEffect, useState } from 'react';
import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormCheckboxField from 'components/ui/form/FormCheckboxField';
import FormSelectField from 'components/ui/form/FormSelectField';
import FormTextField from 'components/ui/form/FormTextField';

import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';

const validationSchema = yup.object({
  name: yup.string().min(3).required(),
  type: yup.string().required(),
  page: yup.string().required(),
});

export default memo(function ModalNewFieldMaker({
  open,
  setOpen,
  refetch,
  selected,
  setSelected,
}) {
  const handleClose = () => {
    setOpen(false);
    setSelected({});
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [options, setOptions] = useState([
    { name: '', _id: new Date().toString() },
  ]);
  const sendNotification = useNotification();
  const axiosPrivate = useAxiosPrivate();

  const formik = useFormik({
    initialValues: {
      name: null,
      type: null,
      isrequired: '0',
      page: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      handleSave(values);
    },
  });
  const { setValues } = formik;

  useEffect(() => {
    if (selected?.type && selected?.id) {
      setValues({ name: selected?.name, type: selected?.type });
      if (selected?.type === 'SELECT' && selected?.options?.length) {
        setOptions(selected?.options);
      }
    } else {
      setOptions([{ name: '', _id: new Date().toString() }]);
    }

    return () => {
      // cleanup
    };
  }, [selected, setValues]);

  const handleSave = async (values) => {
    setIsSubmitting(true);

    const noEmpty = options
      ?.filter(({ name }) => name)
      ?.map(({ name, id }) => ({ name, id }));

    const body =
      formik?.values?.type !== 'SELECT'
        ? values
        : { ...values, options: noEmpty };
    try {
      const response = await axiosPrivate.post(
        `/admin/variables/${selected?.id ? 'update/' + selected?.id : 'store'}`,
        JSON.stringify(body),
        { headers: { 'Content-Type': 'application/json' } }
      );
      if (response && response.data && response.data.status) {
        refetch();
        setOpen(false);
        sendNotification({
          msg: response?.data?.message || response?.message,
          variant: 'success',
        });
      }
    } catch (error) {
      sendNotification({
        msg: error?.response?.data?.message || error?.message,
        variant: 'error',
      });
    }
    setIsSubmitting(false);
  };
  const onRemoveOption = (i) => {
    setOptions(options.filter((_, index) => index !== i));
  };
  const onOptionChange = (e, i) => {
    const { value } = e.target;
    setOptions((opts) =>
      opts?.map((opt, j) => (i === j ? { ...opt, name: value } : opt))
    );
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      PaperProps={{ className: '!base-border !shadow-none' }}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        id='alert-dialog-title'
        className='!py-3 text-center !bg-gray-100 !mb-4 !min-w-[620px]'
      >
        <span className='pr-5 text-base'>
          {t('settings.variables.addModal.title')}
        </span>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={{ xs: 2, sm: 3, lg: 3 }}
            rowSpacing={1}
            columns={{ xs: 12, sm: 12, lg: 12 }}
          >
            <Grid item={true} lg={6} sm={6} xs={12}>
              <FormTextField
                delay={0}
                duration={0}
                fieldName='name'
                label={t('common.fields.simpleName')}
                formik={formik}
              />
            </Grid>
            <Grid item={true} lg={6} sm={6} xs={12}>
              <FormSelectField
                delay={0}
                duration={0}
                fieldName='type'
                label={t('common.fields.type')}
                formik={formik}
                optionValueName='name'
                path={'/admin/variables/types'}
              />
            </Grid>

            <Grid item={true} lg={6} sm={6} xs={12}>
              <FormSelectField
                delay={0}
                duration={0}
                fieldName='page'
                label={t('common.fields.objectName')}
                formik={formik}
                optionValueName='id'
                path={'/admin/variables/pages'}
              />
            </Grid>
            <Grid item={true} lg={6} sm={6} xs={12}>
              <FormCheckboxField
                delay={0}
                duration={0}
                label={t('settings.variables.addModal.requiredField')}
                fieldName='isrequired'
                formik={formik}
              />
            </Grid>

            {/* {formik.values.type == '4' && (
              <div className='mt-3'>
                {options.map((option, index) => (
                  <div key={option?._id || option?.id}>
                    <div className='text-sm'>
                      {index + 1}-{t('common.fields.option')}
                    </div>
                    <div className='flex gap-2'>
                      <TextField
                        delay={0}
                        duration={0}
                        value={option?.name}
                        onChange={(e) => onOptionChange(e, index)}
                        fullWidth
                        isitemsizesmall={true}
                      />
                      <Button
                        size='small'
                        variant='outlined'
                        onClick={() => onRemoveOption(index)}
                        sx={{
                          height: 40,
                          marginTop: 1,
                          minWidth: 40,
                          borderColor: 'red',
                        }}
                      >
                        <i className='bi bi-trash-fill text-red-600' />
                      </Button>
                    </div>
                  </div>
                ))}
                <div className='item-value mb-2'>
                  <Button
                    startIcon={<i className='bi bi-plus' />}
                    size='small'
                    variant='outlined'
                    className='!text-gray-400 hover:!text-black'
                    sx={{ borderStyle: 'dashed' }}
                    type='submit'
                    onClick={() =>
                      setOptions([
                        ...options,
                        { name: '', _id: new Date().toString() },
                      ])
                    }
                  >
                    {t('common.button.addNew')}
                  </Button>
                </div>
              </div>
            )} */}
            <Grid item={true} sm={12} xs={12}>
              <FormActionButtons
                delay={0}
                duration={0}
                isSubmitting={isSubmitting}
                formType='dialog'
                setOpen={setOpen}
                reset={formik.resetForm}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
});
