import { memo, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';

import FormTreeSelectField from 'components/ui/form/FormTreeSelectField';
import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormSelectField from 'components/ui/form/FormSelectField';
import useFormSubmit from 'hooks/useFormSubmit';

const validationSchema = yup.object({
  status_id: yup.string().required('crm.block.column.addModal.validation.name'),
});

const LeadChangeStatusModal = (props) => {
  const { open, setOpen, refetchFn, leads } = props;
  const { t } = useTranslation();
  const { submit, isSubmitting } = useFormSubmit();

  const formik = useFormik({
    initialValues: {
      status_id: '',
      isfinished: 0,
      reject_id: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        leads,
      };

      submit(
        { type: 'post', contentType: 'simple' },
        newValues,
        // '/crm/block-item',
        '/crm/crm-lead/changestatus',
        values.name,
        null,
        true,
        handleFinish
      );
    },
  });

  const handleFinish = () => {
    refetchFn();
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
      PaperProps={{ className: '!base-border !shadow-none !max-w-[500px]' }}
    >
      <DialogTitle
        id='alert-dialog-title'
        className='!bg-gray-100 !text-center !mb-4'
      >
        <span>{t('common.button.changeLeadStatus')}</span>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={formik.handleSubmit} className='pt-2'>
          <Grid
            container
            spacing={{ xs: 1, sm: 1 }}
            rowSpacing={1}
            columns={{ xs: 12, sm: 12 }}
          >
            <Grid item={true} sm={12} xs={12} className='!items-center flex'>
              <div className='border rounded-md w-full px-2'>
                <FormTreeSelectField
                  delay={0}
                  duration={0}
                  parentFieldName='crm_block_id'
                  childFieldName='status_id'
                  label=''
                  childOptionField={'pipes'}
                  formik={formik}
                  parentLabel={'name'}
                  parentValue={'id'}
                  childLabel={'name'}
                  childValue={'id'}
                  variant='standard'
                  path='/crm/block'
                  size='small'
                  progress={false}
                  onChange={({ isfinished }) => {
                    if (!isfinished) {
                      formik.setFieldValue('reject_id', null);
                    }
                  }}
                />
              </div>
            </Grid>
            {formik?.values?.isfinished == 1 && (
              <Grid item={true} sm={12} xs={12}>
                <FormSelectField
                  delay={0}
                  label={t('common.fields.rejectReason')}
                  fieldName='reject_id'
                  formik={formik}
                  path={'/dictionary/rejectreasons'}
                />
              </Grid>
            )}

            <Grid item={true} sm={12} xs={12}>
              <div className='flex justify-between items-center'>
                <div>
                  {t('sidebar.admin.lead')}: {leads?.length}
                </div>
                <FormActionButtons
                  delay={0}
                  isSubmitting={isSubmitting}
                  formType='dialog'
                  setOpen={setOpen}
                  // disabled={selected?.id}
                  reset={formik.resetForm}
                />
              </div>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default memo(LeadChangeStatusModal);
