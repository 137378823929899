import { Grid, IconButton } from '@mui/material';
import { t } from 'i18next';
import { memo } from 'react';
import SimpleCheckbox from 'components/ui/simple-fields/checkbox/SimpleCheckbox';
import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormCurrencyField from 'components/ui/form/FormCurrencyField';
import FormNumberField from 'components/ui/form/FormNumberField';
import FormRadioGroupField from 'components/ui/form/FormRadioGroupField';
import FormTextField from 'components/ui/form/FormTextField';
import {
  repairTypeSelectOptions,
  residentTypeSelectOptions,
} from 'shared/selectOptionsList';
import CurrencySubContent from 'components/ui/text-formats/CurrencySubContent';

export default memo(function ShaxmatkaEditHome({
  editTypeMultiple,
  handleEditType,
  formik,
  isSubmitting,
  isMultipleSubmitting,
  handleCloseHomeDetail = () => {},
}) {
  return (
    <div className='sheet-actions-body'>
      <div className='absolute top-0 right-0'>
        <IconButton
          variant='onlyIcon'
          color='primary'
          onClick={() => handleCloseHomeDetail()}
        >
          <i className='bi bi-x' />
        </IconButton>
      </div>
      <Grid container spacing={2} rowSpacing={1} columns={{ xs: 12, sm: 12 }}>
        <Grid item={true} sm={12}>
          <SimpleCheckbox
            delay={0}
            duration={0}
            label={t('shaxmatka.changeMultiple')}
            value={editTypeMultiple}
            setValue={handleEditType}
          />
        </Grid>
      </Grid>
      <div className='sheet-form-action'>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={2}
            rowSpacing={1}
            columns={{ xs: 12, sm: 12 }}
          >
            {!editTypeMultiple && (
              <Grid item={true} sm={12} sx={{ marginBottom: '-0.5rem' }}>
                <FormTextField
                  delay={0}
                  label={t('common.fields.homeNumber')}
                  fieldName='number'
                  formik={formik}
                />
              </Grid>
            )}

            <Grid item={true} sm={12} sx={{ marginBottom: '-0.5rem' }}>
              <FormTextField
                delay={0}
                label={t('common.fields.rooms')}
                fieldName='rooms'
                formik={formik}
              />
            </Grid>

            <Grid item={true} sm={12} sx={{ marginBottom: '-0.5rem' }}>
              <FormNumberField
                delay={0}
                label={t('common.fields.homeAreAll')}
                fieldName='square'
                formik={formik}
                decimalScale={2}
              />
            </Grid>

            <Grid item={true} sm={12} sx={{ marginBottom: '-0.5rem' }}>
              <FormCurrencyField
                delay={0}
                fieldName='start'
                formik={formik}
                label={t('common.fields.startPricePercentage')}
                decimalScale={1}
              />
              {formik.values.isvalute == '1' && !isNaN(formik.values.start) && (
                <CurrencySubContent value={formik.values.start || '0'} />
              )}
            </Grid>

            <Grid item={true} sm={12}>
              <FormRadioGroupField
                delay={0}
                fieldName='islive'
                formik={formik}
                label={t('common.fields.residentType')}
                options={residentTypeSelectOptions}
              />
            </Grid>

            <Grid
              item={true}
              sm={12}
              sx={{ marginTop: '-0.5rem', marginBottom: '-0.5rem' }}
            >
              <FormRadioGroupField
                delay={0}
                fieldName='isrepaired'
                formik={formik}
                label={t('common.fields.repairType')}
                options={repairTypeSelectOptions}
              />
            </Grid>

            <Grid item={true} sm={12} xs={12}>
              <FormActionButtons
                delay={0}
                isSubmitting={isSubmitting || isMultipleSubmitting}
                onlySave
                className='mt-0'
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
});
