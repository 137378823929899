import React, { Fragment, memo } from 'react';
import ShaxmatkaRow from './ShaxmatkaRow';
import HOME_TYPE from 'shared/homeTypeList';
import { useTranslation } from 'react-i18next';

const ShaxmatkaPodezdItem = ({
  block,
  padezdItem,
  padezdIndex,
  blockIndex,
  toggleSelectionItem = () => {},
}) => {
  const { t } = useTranslation();
  const getRoomsByStatus = (statusCode) => {
    return padezdItem?.homes.length > 0
      ? padezdItem?.homes.filter((item) => item.status == statusCode).length
      : 0;
  };

  const getFullFloorsNumberByBlock = (block) => {
    let a = Array.from(new Set(block.homes.map((item) => item.stage)));
    if (a.includes('-1')) {
      a.push('0');
    }
    a.sort((x, y) => x - y);
    return a.reverse();
  };

  return (
    <Fragment>
      <div className={`my-2 mt-0 leading-4 ${!padezdIndex ? 'ml-10' : ''}`}>
        <span className='font-medium text-lg text-line-1'>
          {padezdItem?.name}
        </span>
        <span className='text-sm leading-4'>
          ({t('shaxmatka.homeType.free')}:{' '}
          {getRoomsByStatus(HOME_TYPE.ACTIVE.code)},{' '}
          {t('shaxmatka.homeType.ordered')}:{' '}
          {getRoomsByStatus(HOME_TYPE.TIME.code)},{' '}
          {t('shaxmatka.homeType.sold')}:{' '}
          {getRoomsByStatus(HOME_TYPE.ORDERED.code)})
        </span>
      </div>
      {getFullFloorsNumberByBlock(padezdItem).map((floorNumber, index) => (
        <div
          className={`sheet-row ${
            padezdIndex != 0 ? '!pl-0' : ''
          } floor-${floorNumber}`}
          key={`block-${padezdItem?.id}-row-${index}`}
          id={`block-${padezdItem?.id}-row-${index}`}
        >
          <ShaxmatkaRow
            block={block}
            homesData={padezdItem?.homes}
            blockIndex={blockIndex}
            padezdIndex={padezdIndex}
            floorNumber={floorNumber}
            size={new Set(padezdItem?.homes.map((item) => item.stage)).size}
            toggleSelectionItem={toggleSelectionItem}
          />
        </div>
      ))}
    </Fragment>
  );
};

export default memo(ShaxmatkaPodezdItem);
