const {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} = require('@mui/material');

const FilterAccordion = ({
  title = '',
  code = '',
  children,
  className,
  removeIcon,
}) => {
  return (
    <Accordion
      sx={{
        boxShadow: 'none',
      }}
      className={`dashboard-filter-accordion !my-2 ${className}`}
    >
      <AccordionSummary
        aria-controls={`${code}-content`}
        id={`${code}-header`}
        data-text={title}
        sx={{ border: '1px solid' }}
        expandIcon={<i className='bi bi-chevron-right expand-icon' />}
        className='!border !border-gray-300 hover:!border-gray-500 !px-2 !shadow-none'
      >
        {removeIcon ? null : <i className='bi bi-funnel filter-icon' />}
        <span className='accordion-title'>{title}</span>
      </AccordionSummary>
      <AccordionDetails className='dashboard-filter-accordion-details'>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
export default FilterAccordion;
