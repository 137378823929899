import { TextField } from '@mui/material';
import { motion } from 'framer-motion';
import { Fragment, memo } from 'react';
import { NumericFormat } from 'react-number-format';
import formLocalizedHelperText from 'utils/formLocalizedHelperText';
import { fadeUp } from 'utils/motion';

const FormCurrencyGenericField = ({
  delay = 0,
  duration = 0.5,
  fieldName,
  formik,
  label,
  prefix = '',
  suffix = '',
  readOnly = false,
  disabled = false,
  decimalScale = 3,
  variant = 'outlined',
  size = '',
  onChange = () => {},
}) => {
  return (
    <Fragment>
      {formik && formik.values && formik.touched && formik.errors && (
        <div className='relative'>
          <NumericFormat
            id={fieldName}
            name={fieldName}
            label={label}
            value={formik.values[fieldName]}
            delay={delay}
            onChange={(event) => {
              const formattedValue =
                event.target.value &&
                parseFloat(event.target.value.split(' ').join(''));
              formik.setFieldValue(fieldName, formattedValue, true);
              onChange(formattedValue);
            }}
            error={
              formik.touched[fieldName] && Boolean(formik.errors[fieldName])
            }
            helperText={
              formik.touched[fieldName] &&
              formLocalizedHelperText(formik.errors[fieldName])
            }
            component={motion.div}
            variants={fadeUp(30, 'tween', delay, duration)}
            initial='hidden'
            animate='show'
            viewport={{ once: true, amount: 0.25 }}
            color='formColor'
            variant={variant}
            fullWidth
            customInput={TextField}
            InputProps={{ readOnly: readOnly, disabled: disabled }}
            allowNegative={false}
            thousandSeparator={' '}
            decimalScale={decimalScale}
            prefix={prefix}
            size={size}
          />
          {suffix ? (
            <div className='absolute top-0 right-0 text-inherit h-full flex items-center w-10'>
              <div className='p-[2px] rounded-md bg-white text-sm'>
                {suffix}
              </div>
            </div>
          ) : null}
        </div>
      )}
    </Fragment>
  );
};

export default memo(FormCurrencyGenericField);
