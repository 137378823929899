import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import BaseTable from 'components/ui/tables/BaseTable';
import useTopPanel from 'hooks/useTopPanel';
import TargetAddEditModal from './ContractsAddEditModal';
import SimpleSearchInput from 'components/SearchInputNavbar';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';
import { generateDocument } from './downloader';

const Contracts = () => {
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    setComponent(
      <div className='component-title'>{t('settings.contracts.title')}</div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const [refetch, setRefetch] = useState(false);
  const [openStaffModal, setOpenStaffModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState({});
  const sendNotification = useNotification();

  const copyLinkData = useCallback((data) => {
    const fileName = data?.file;

    const getChequeData = async (url) => {
      try {
        const response = await axiosPrivate.get(`${url}`, {
          headers: { 'Content-Type': 'application/json' },
        });
        if (response && response.data) {
          await generateDocument(response.data, fileName);
        }
      } catch (error) {
        sendNotification({
          msg: error?.response?.data?.message || error?.message,
          variant: 'error',
        });
      }
    };

    getChequeData(`/admin/template/download/${data?.id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='component-list-wrapper'>
      <div className='component-list-header mb-2'>
        <div className='header-actions-container py-3 flex flex-row items-center'>
          <div className='header-actions filter-box flex items-center px-4 w-full'>
            <Button
              variant='base'
              color='primary'
              startIcon={<i className='bi bi-filter' />}
              className='!mr-2'
            >
              {t('common.button.filter')}
            </Button>
            <SimpleSearchInput inputKey='name' />
          </div>
          <div className='header-actions action-buttons-box py-3 px-4 flex items-center justify-center'>
            <Button
              variant='base'
              color='info'
              onClick={() => {
                setRefetch(true);
              }}
              disable={`${refetch}`}
            >
              <i
                className={`bi bi-arrow-repeat${
                  refetch ? ' animate-spin' : ''
                }`}
              />
            </Button>
            <Button
              variant='base'
              color='success'
              className='!mx-2'
              onClick={() => setOpenStaffModal(true)}
            >
              <i className='bi bi-plus-circle' />
            </Button>
            <Button
              variant='base'
              color='default'
              onClick={() => setOpen(true)}
            >
              <i className='bi bi-gear' />
            </Button>
          </div>
        </div>
      </div>

      <div className='component-table-wrapper relative !h-[calc(100vh-180px)]'>
        <BaseTable
          emitRefetch={{ refetch, setRefetch }}
          dataPath='admin/template/index'
          emitTableColumns={{ open, setOpen }}
          tableName='staff'
          actionCopyRowData={{
            handlerFn: copyLinkData,
            hasTooltip: true,
            tooltipProps: true,
          }}
          headCells={[
            { code: 'id', label: '#' },
            { code: 'name', label: t('common.table.name') },
            { code: 'object', label: t('common.table.objectName') },
            { code: 'from', label: t('common.table.from') },
            { code: 'till', label: t('common.table.till') },
            { code: 'lang', label: t('common.language.title') },
          ]}
          columns={[
            { code: 'id' },
            { code: 'name' },
            { code: 'object', type: 'nested', childStr: 'name' },
            { code: 'from' },
            { code: 'till' },
            { code: 'lang' },
          ]}
          actionModal={{
            setOpen: setOpenStaffModal,
            setData: setSelected,
            btnColor: 'warning',
            icon: 'bi bi-pencil-square',
          }}
          actionDelete={{
            link: 'admin/template/delete',
            method: 'post',
            paramId: true,
            key: 'id',
          }}
        />
      </div>
      {openStaffModal && (
        <TargetAddEditModal
          open={openStaffModal}
          setOpen={setOpenStaffModal}
          selected={selected}
          setSelected={setSelected}
          setRefetch={setRefetch}
        />
      )}
    </div>
  );
};

export default memo(Contracts);
