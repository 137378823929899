/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { t } from 'i18next';

import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormCurrencyField from 'components/ui/form/FormCurrencyField';
import FormSelectField from 'components/ui/form/FormSelectField';
import FormSimpleSelectField from 'components/ui/form/FormSimpleSelectField';
import FormCurrencyField2 from 'components/ui/form/FormCurrencyField2';

import FormTextField2 from 'components/ui/form/FormTextField2';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import ContactInputForm from './ContactInputForm';
import FormDateField2 from 'components/ui/form/FormDateField2';
import ChangeOnlyOne from './ChangeOnlyOne';
import useNotification from 'hooks/useNotification';
import DeleteConfirmDialog from 'components/ui/dialogs/DeleteConfirmDialog';

export default memo(function InfoSection({
  formik,
  handleAddPhone,
  handleRemoveContact,
  handleAddContact,
  isSubmitting,
  isLoadingLeadInfo,
  refetch,
  refetchLead,
  setRefetch,
  setRequiredFields,
}) {
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const [open, setOpen] = useState(false);
  const [contactId, setContactId] = useState(null);
  const [values, setValues] = useState(JSON.stringify(formik.values));
  const { id } = useParams();
  const sendNotification = useNotification();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['/crm/crm-settings/fields'],
    queryFn: async function () {
      const response = await axiosPrivate.get('/crm/crm-settings/fields');
      return response.data.data;
    },
    enabled: !hasError,
    onSuccess: (res) => {
      if (!id) {
        const fields = res?.map(({ id, name }) => ({
          field_id: id,
          value: '',
          field_name: name,
        }));
        formik.setFieldValue('fields', fields);
      }
    },
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const getFields = useQuery({
    queryKey: ['/crm/crm-lead/getfields/id', id, data, refetch],
    queryFn: async function () {
      const response = await axiosPrivate.get(`/crm/crm-lead/getfields/${id}`);
      return response.data.data;
    },
    enabled: !hasError && data?.length > 0 && Boolean(id) && refetch,

    onSuccess: (res) => {
      setRefetch(false);
      const fields = data?.map(({ id, name }) => {
        const item = res?.find((f) => f?.field_id === id);
        return {
          field_id: id,
          value: item?.value || '',
          field_name: name,
          initialValue: item?.value || '',
        };
      });

      formik.setFieldValue('fields', fields);
    },
    onError: () => {
      setHasError(true);
    },
    retry: false,
  });

  useEffect(() => {
    setValues(JSON.stringify(formik.values));
  }, [refetch]);

  const deleteContact = useMutation({
    mutationKey: ['crm/crm-lead/contactdelete/contactID'],
    mutationFn: (id) => axiosPrivate.post(`crm/crm-lead/contactdelete/${id}`),
    onSuccess: () => {
      sendNotification({
        msg: t('common.alerts.success.delete'),
        variant: 'success',
      });
      setContactId(null);
      refetchLead?.();
      setOpen(false);
    },
    onError: (error) => {
      sendNotification({
        msg: t('common.alerts.error.delete'),
        variant: 'error',
      });
    },
  });

  const handleAction = (action) => {
    if (action == 'cancel') {
      setOpen(false);
      setContactId(null);
    }
    if (action == 'delete') {
      deleteContact.mutate(contactId);
    }
  };

  return (
    <div className='add-edit-body-wrapper !pr-5 '>
      {isLoadingLeadInfo ? (
        <div className='circular-progress-box py-5'>
          <CircularProgress size={35} />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={{ xs: 0, sm: 0, lg: 0 }}
            columns={{ xs: 12, sm: 12, lg: 12 }}
          >
            <Grid item={true} sm={12} xs={12}>
              <div className='item-wrapper'>
                <div className='item-label'>{t('common.fields.staff')}</div>
                <div className='item-value'>
                  <FormSelectField
                    delay={0}
                    duration={0}
                    fieldName='user_id'
                    label=''
                    formik={formik}
                    path={'/dictionary/staffes'}
                    variant='standard'
                    size='small'
                    sx={{
                      '.MuiSelect-select': { textAlign: 'right' },
                    }}
                    isitemsizesmall={true}
                  />
                </div>
              </div>
            </Grid>

            <Grid item={true} sm={12} xs={12}>
              <div className='item-wrapper'>
                <div className='item-label'>{t('common.fields.budget')}</div>
                <div className='item-value'>
                  <FormCurrencyField
                    delay={0}
                    duration={0}
                    label=''
                    fieldName='budget'
                    formik={formik}
                    variant='standard'
                    size='small'
                    sx={{
                      '.MuiInputBase-input': { textAlign: 'right' },
                    }}
                  />
                </div>
              </div>
            </Grid>

            {formik.values?.reject_id ? (
              <Grid item={true} sm={12} xs={12}>
                <div className='item-wrapper'>
                  <div className='item-label'>
                    {t('common.fields.rejectReason')}
                  </div>
                  <div className='item-value'>
                    <FormSelectField
                      delay={0}
                      duration={0}
                      fieldName='reject_id'
                      label=''
                      formik={formik}
                      path={'/dictionary/rejectreasons'}
                      variant='standard'
                      size='small'
                      sx={{
                        '.MuiSelect-select': { textAlign: 'right' },
                      }}
                      isitemsizesmall={true}
                    />
                  </div>
                </div>
              </Grid>
            ) : null}

            {(id &&
              (getFields?.isLoading || getFields?.isFetching) &&
              !formik.values.fields) ||
            isLoading ||
            isFetching ? (
              <div className='circular-progress-box my-4'>
                <CircularProgress size={25} />
              </div>
            ) : (
              data?.map((field, i) => (
                <Grid item={true} sm={12} xs={12} key={i}>
                  <div className='item-wrapper'>
                    <div className='item-label'>{field?.name}</div>
                    <div className='item-value'>
                      {field?.type === 'SELECT' && (
                        <FormSimpleSelectField
                          delay={0}
                          duration={0}
                          withNestedField
                          fieldName={`fields.${i}.value`}
                          formik={formik}
                          options={field?.options}
                          variant='standard'
                          size='small'
                          itemValue='name'
                          sx={{
                            '.MuiSelect-select': { textAlign: 'right' },
                          }}
                          isitemsizesmall={true}
                        />
                      )}
                      {field?.type === 'TEXT' && (
                        <FormTextField2
                          delay={0}
                          duration={0}
                          fieldName={`fields.${i}.value`}
                          formik={formik}
                          options={field?.options}
                          path={'/crm/reject-type'}
                          variant='standard'
                          size='small'
                          sx={{
                            '.MuiInputBase-input': {
                              textAlign: 'right',
                            },
                          }}
                          isitemsizesmall={true}
                        />
                      )}
                      {field?.type === 'DATE' && (
                        <FormDateField2
                          delay={0}
                          duration={0}
                          fieldName={`fields.${i}.value`}
                          formik={formik}
                          variant='standard'
                          type='number'
                          size='small'
                          className='[&_.MuiInputBase-input.MuiInput-input]:!text-right'
                          InputProps={{
                            sx: {
                              textAlign: 'right',
                            },
                          }}
                        />
                      )}
                      {field?.type === 'NUMBER' && (
                        <FormCurrencyField2
                          delay={0}
                          duration={0}
                          fieldName={`fields.${i}.value`}
                          formik={formik}
                          variant='standard'
                          type='number'
                          size='small'
                          sx={{
                            '.MuiInputBase-input': { textAlign: 'right' },
                          }}
                        />
                      )}
                      {field?.type === 'NUMBER1' && (
                        <ChangeOnlyOne
                          delay={0}
                          duration={0}
                          fieldName={`fields.${i}.value`}
                          initialFieldValueName={`fields.${i}.initialValue`}
                          formik={formik}
                          readOnly
                          variant='standard'
                          type='number'
                          size='small'
                          sx={{
                            '.MuiInputBase-input': { textAlign: 'right' },
                          }}
                        />
                      )}
                    </div>
                  </div>
                </Grid>
              ))
            )}

            {formik.values.contacts &&
              formik.values.contacts.length > 0 &&
              formik.values.contacts.map((contact, contactInd) => (
                <Grid item={true} sm={12} xs={12} key={`contact-${contactInd}`}>
                  <div className='mt-2 bg-white -mx-1 p-2 rounded-[14px] leading-5 relative'>
                    <div className='item-wrapper contact-details'>
                      <i className='bi bi-person-circle contact-person-icon' />
                      <FormTextField2
                        delay={0}
                        duration={0}
                        label=''
                        fieldName={`contacts.${contactInd}.name`}
                        formik={formik}
                        variant='standard'
                      />
                    </div>
                    <div>
                      {contact.phones.map((item, index) => (
                        <div
                          className='item-wrapper contact-phones justify-between'
                          key={`contact-${contactInd}-phone-${index}`}
                        >
                          <div className='item-label contact-label !text-[#818181] !font-normal'>
                            {t('common.fields.phone')}
                          </div>
                          <ContactInputForm
                            index={index}
                            formik={formik}
                            contact={contact}
                            handleAddPhone={handleAddPhone}
                            contactInd={contactInd}
                          />
                        </div>
                      ))}
                    </div>

                    {formik.values.contacts.length > 1 && (
                      <div
                        className='close-btn-wrapper'
                        // onClick={() => handleRemoveContact(contactInd)}
                        onClick={() => {
                          setOpen(true);
                          setContactId(contact?.id);
                        }}
                      >
                        <IconButton
                          variant='onlyIcon'
                          color='error'
                          size='small'
                        >
                          <i className='bi bi-x' />
                        </IconButton>
                      </div>
                    )}
                  </div>
                  <DeleteConfirmDialog
                    open={open}
                    setOpen={setOpen}
                    handleAction={handleAction}
                    loading={deleteContact.isLoading}
                  />
                </Grid>
              ))}

            <Grid item={true} sm={12} xs={12}>
              <div className='-mx-1 mt-1.5'>
                <Button
                  color='secondary'
                  variant='contained'
                  fullWidth
                  onClick={() => handleAddContact()}
                  className='!bg-white !text-[#818181] !font-normal'
                >
                  <i className='bi bi-plus-circle mr-1' />{' '}
                  {t('common.fields.addContact')}
                </Button>
              </div>
            </Grid>
          </Grid>
          {JSON.stringify(formik.values) !== values ? (
            <div className='sticky bottom-0 bg-[#F2F3F7] z-10 mt-2 p-2 -mx-2 border-t'>
              <FormActionButtons delay={0} isSubmitting={isSubmitting} />
            </div>
          ) : null}
        </form>
      )}
    </div>
  );
});
