import React, { Fragment, memo } from 'react';
import { Button, Dialog, DialogTitle, Grid, IconButton } from '@mui/material';
import { t } from 'i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useFormSubmit from 'hooks/useFormSubmit';
import { useAppInfo } from 'context';
import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormCheckboxField from 'components/ui/form/FormCheckboxField';
import FormNumberField from 'components/ui/form/FormNumberField';
import FormSimpleSelectField from 'components/ui/form/FormSimpleSelectField';
import FormMultiSelectField from 'components/ui/form/FormMultiSelectField';
import FormTreeSelectField2 from 'components/ui/form/FormTreeSelectField2';

const validationSchema = yup.object({
  date: yup.number().required('change.tab.contract.validation.staffId'),
});

export default memo(function BotSettingsModal({
  data,
  open,
  setOpen,
  refetchFn = () => {},
}) {
  const onClose = () => setOpen(null);
  const [{ refetchTaskCount }] = useAppInfo();
  const { submit, isSubmitting } = useFormSubmit();
  const formik = useFormik({
    initialValues: {
      date: '',
      active: '1',
      contacts: [
        {
          blocks: [],
          staffs: [],
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (data?.length) {
        const body = { date: values.date + 'T00:00', ids: data };
        return;
        submit(
          { type: 'post', contentType: 'simple' },
          body,
          '/crm/task/time',
          'date task',
          null,
          true,
          () => {
            refetchFn();
            refetchTaskCount?.();
          }
        );
      }
    },
  });

  const taskBotDateTypeSelectOptions = [
    {
      code: 'till',
      label: t('common.typeList.dateType.till'),
    },
    {
      code: 'from',
      label: t('common.typeList.dateType.from'),
    },
    {
      code: 'payment_date',
      label: t('common.typeList.dateType.payment_date'),
    },
  ];
  const handleAddContact = () => {
    formik.setFieldValue(
      'contacts',
      [
        ...JSON.parse(JSON.stringify(formik.values.contacts)),
        {
          blocks: [],
          staffs: [],
        },
      ],
      true
    );
  };

  const handleRemoveContact = (contactInd) => {
    let newContacts = JSON.parse(JSON.stringify(formik.values.contacts)).filter(
      (item, index) => index != contactInd
    );
    formik.setFieldValue('contacts', [...newContacts], true);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      // maxWidth='lg'
      PaperProps={{ className: '!base-border !shadow-none !overflow-hidden' }}
    >
      <DialogTitle
        id='alert-dialog-title'
        className='!bg-[#CDCDCDC7] !text-center !border-b !border-b-[#E6E6E6]'
      >
        <span className='pr-5 font-semibold text-[21px] text-[#374957]'>
          {t('tasks.bot.title')}
        </span>
      </DialogTitle>
      <div className='py-4 px-8 min-w-[600px]'>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={{ xs: 2, sm: 2 }}
            rowSpacing={1}
            columns={{ xs: 12, sm: 12 }}
          >
            <Grid item={true} sm={12} xs={12}>
              <FormCheckboxField
                delay={0.1}
                label={t('tasks.bot.activateSettings')}
                fieldName='active'
                formik={formik}
              />
            </Grid>
            <Grid item={true} sm={6} xs={6}>
              <FormNumberField
                delay={0.2}
                label={t('common.table.day')}
                fieldName='days'
                formik={formik}
                disabled={
                  formik.values.date_type === 'payment_date' ||
                  formik.values.active == 0
                }
              />
            </Grid>
            <Grid item={true} sm={6} xs={6}>
              <FormSimpleSelectField
                delay={0.1}
                label={t('common.fields.dateType')}
                options={taskBotDateTypeSelectOptions}
                fieldName='date_type'
                formik={formik}
                changeFn={(value) =>
                  formik.setFieldValue('date_type', value, true)
                }
                itemValue='code'
                disabled={formik.values.active == 0}
                itemLabel='label'
              />
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              <FormCheckboxField
                delay={0.1}
                label={t('tasks.bot.autoBot')}
                fieldName='auto_bot'
                disabled={formik.values.active == 0}
                formik={formik}
              />
            </Grid>
            {formik?.values?.contacts?.map((contact, i) => (
              <Fragment key={i}>
                <Grid item={true} sm={5} xs={5}>
                  <div className='border w-full border-gray-400 mt-2 p-[5px] rounded-lg [&>*]:!m-0'>
                    <FormTreeSelectField2
                      fieldName={`contacts.${i}.blocks`}
                      label={`${t('boss.residentialComplex.title')} (${t(
                        'common.fields.blocks'
                      )})`}
                      childOptionField={'blocks'}
                      formik={formik}
                      parentLabel={'name'}
                      parentValue={'id'}
                      childLabel={'name'}
                      childValue={'id'}
                      variant='standard'
                      path='/dictionary/objects3'
                      size='small'
                      onChange={() => {}}
                      progress={false}
                    />
                  </div>
                </Grid>
                <Grid item={true} sm={1} xs={1} className='!pl-4'>
                  <div className='base-border mt-3 rounded-full bg-gray-50 h-8 w-8 flex items-center justify-center'>
                    <i className='bi bi-arrow-right' />
                  </div>
                </Grid>
                <Grid item={true} sm={5} xs={5}>
                  <FormMultiSelectField
                    delay={0}
                    fieldName={`contacts.${i}.staffs`}
                    label={t('common.fields.staffs')}
                    formik={formik}
                    path={'/dictionary/staffes'}
                    isitemsizesmall={true}
                    withNestedField
                  />
                </Grid>
                <Grid item={true} sm={1} xs={1}>
                  <div className='flex items-center h-full'>
                    <IconButton
                      onClick={() => handleRemoveContact(i)}
                      disabled={formik.values.contacts.length < 2}
                      variant='onlyIcon'
                      color='error'
                      size='small'
                    >
                      <i className='bi bi-x' />
                    </IconButton>
                  </div>
                </Grid>
              </Fragment>
            ))}
            <Grid item={true} sm={12} xs={12}>
              <Button
                color='secondary'
                variant='contained'
                fullWidth
                onClick={() => handleAddContact()}
                className='!bg-white !text-[#818181] !font-normal'
              >
                <i className='bi bi-plus-circle mr-1' />
                {/* {t('common.fields.addContact')} */}
              </Button>
            </Grid>
            <Grid item={true} sm={12} xs={12}>
              <div className='flex justify-end'>
                <FormActionButtons
                  delay={0.5}
                  isSubmitting={isSubmitting}
                  formType='dialog'
                  setOpen={setOpen}
                  reset={formik.resetForm}
                />
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </Dialog>
  );
});
