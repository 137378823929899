import React, { memo, useEffect } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';

import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormTextField from 'components/ui/form/FormTextField';
import FormSelectField from 'components/ui/form/FormSelectField';
import FormFileUploadField from 'components/ui/form/FormFileUploadField';
import FormSimpleSelectField from 'components/ui/form/FormSimpleSelectField';

import useFormSubmit from 'hooks/useFormSubmit';

const validationSchema = yup.object({
  name: yup.string().required('settings.contracts.validation.name'),
  lang: yup.string().required('settings.contracts.validation.lang'),
  from: yup.number().required('settings.contracts.validation.from'),
  till: yup.number().required('settings.contracts.validation.till'),
  object_id: yup.number().required('settings.contracts.validation.objectId'),
  file: yup.mixed().required('settings.contracts.validation.file'),
  isrepaired: yup.mixed().required('change.tab.contract.isrepaired'),
});

const TargetAddEditModal = (props) => {
  const { open, setOpen, setRefetch, selected, setSelected } = props;
  const { t } = useTranslation();
  const { submit, isSubmitting } = useFormSubmit();

  const languages = [
    {
      code: 'uz',
      label: t('common.language.short.uz'),
    },
    {
      code: 'uz_kr',
      label: t('common.language.short.uz_kr'),
    },
    {
      code: 'ru',
      label: t('common.language.short.ru'),
    },
  ];

  const repairedTypes = [
    {
      code: '1',
      label: t('common.typeList.repairType.repaired'),
    },
    {
      code: '0',
      label: t('common.typeList.repairType.notRepaired'),
    },
  ];
  const formik = useFormik({
    initialValues: {
      name: '',
      file: null,
      from: '',
      till: '',
      lang: '',
      object_id: '',
      isrepaired: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (selected?.id) {
        const item = { ...values };
        if (typeof values.file === 'string') {
          delete item.file;
        }

        submit(
          { type: 'postUpdate', contentType: 'formData' },
          item,
          '/admin/template',
          item?.name,
          selected?.id,
          false,
          handleFinishRequest
        );
      } else
        submit(
          { type: 'post', contentType: 'formData' },
          values,
          '/admin/template',
          values?.name,
          null,
          false,
          handleFinishRequest
        );
    },
  });

  useEffect(() => {
    if (selected) {
      formik.setValues({
        name: selected.name,
        file: selected.file,
        from: selected.from,
        till: selected.till,
        lang: selected.lang,
        object_id: selected.object_id,
        isrepaired: selected.isrepaired,
      });
    }
  }, [selected]);

  const handleFinishRequest = () => {
    setRefetch(true);
    handleClose();
  };

  const handleClose = () => {
    setSelected({});
    setOpen(false);
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
      PaperProps={{ className: '!base-border !shadow-none' }}
    >
      <DialogTitle
        id='alert-dialog-title'
        className='!bg-gray-100 !text-center !mb-2'
      >
        {selected && formik.values.name ? (
          <span>
            {t('settings.contracts.modal.editTitle', {
              value: formik.values.name,
            })}
          </span>
        ) : (
          <span>{t('settings.contracts.modal.addTitle')}</span>
        )}
      </DialogTitle>

      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={{ xs: 2, sm: 3, lg: 3 }}
            rowSpacing={1}
            columns={{ xs: 12, sm: 12, lg: 12 }}
          >
            <Grid item={true} lg={4} sm={6} xs={12}>
              <FormTextField
                delay={0.1}
                label={t('common.table.name')}
                fieldName='name'
                formik={formik}
              />
            </Grid>
            <Grid item={true} lg={4} sm={6} xs={12}>
              <FormSelectField
                delay={0}
                duration={0}
                fieldName='object_id'
                label={t('common.fields.objectName')}
                formik={formik}
                path={'/admin/object/index'}
                isitemsizesmall={true}
              />
            </Grid>
            <Grid item={true} lg={4} sm={6} xs={12}>
              <FormSimpleSelectField
                delay={0}
                fieldName='isrepaired'
                label={t('shaxmatka.table.isrepaired')}
                formik={formik}
                options={repairedTypes}
                itemValue='code'
                itemLabel='label'
                isitemsizesmall={true}
              />
            </Grid>
            <Grid item={true} lg={4} sm={6} xs={12}>
              <FormSimpleSelectField
                delay={0}
                fieldName='lang'
                label={t('common.language.title')}
                formik={formik}
                options={languages}
                itemValue='code'
                itemLabel='label'
                isitemsizesmall={true}
              />
            </Grid>

            <Grid item={true} lg={4} sm={6} xs={12}>
              <FormTextField
                delay={0.2}
                label={t('common.table.from')}
                fieldName='from'
                type='number'
                formik={formik}
              />
            </Grid>
            <Grid item={true} lg={4} sm={6} xs={12}>
              <FormTextField
                delay={0.2}
                label={t('common.table.till')}
                fieldName='till'
                type='number'
                formik={formik}
              />
            </Grid>
            <Grid item={true} lg={4} sm={6} xs={12}>
              <FormFileUploadField
                delay={0.2}
                accept='.doc, .docx'
                fieldName='file'
                formik={formik}
                label={t('common.xlsxUploader.clickToUpload')}
                btnLabel={t('xlsxUploader.clickToUpload')}
                fileSize={1}
              />
            </Grid>

            <Grid item={true} sm={12} xs={12}>
              <FormActionButtons
                delay={0.6}
                isSubmitting={isSubmitting}
                formType='dialog'
                setOpen={setOpen}
                reset={formik.resetForm}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default memo(TargetAddEditModal);
