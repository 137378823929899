import React, { memo } from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import { t } from 'i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useFormSubmit from 'hooks/useFormSubmit';
import { useAppInfo } from 'context';
import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormSelectField from 'components/ui/form/FormSelectField';
import FormTextField from 'components/ui/form/FormTextField';
import FormDateField from 'components/ui/form/FormDateField';

const validationSchema = yup.object({
  staff_id: yup.number().required('change.tab.contract.validation.staffId'),
  type_id: yup.number().required('task.type.validation.typeId'),
  date: yup.string().required('lead.addEditModal.validation.date'),
});

export default memo(function TaskCreateModal({ open, setOpen, leads }) {
  const [{ refetchTaskCount }] = useAppInfo();
  const { submit, isSubmitting } = useFormSubmit();
  const formik = useFormik({
    initialValues: {
      staff_id: '',
      type_id: '',
      title: '',
      date: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (leads?.length) {
        const body = { ...values, leads };
        submit(
          { type: 'post', contentType: 'simple' },
          body,
          '/crm/crm-lead/addtask',
          'add task',
          null,
          true,
          () => {
            refetchTaskCount?.();
            setOpen(null);
            formik.resetForm();
          }
        );
      }
    },
  });

  const onClose = () => {
    setOpen(null);
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='lg'
      PaperProps={{ className: '!base-border !shadow-none !overflow-hidden' }}
    >
      <DialogTitle
        id='alert-dialog-title'
        className='!bg-[#CDCDCDC7] !text-center !border-b !border-b-[#E6E6E6]'
      >
        <span className='pr-5 font-semibold text-[21px] text-[#374957]'>
          {t('common.button.createTask')}
        </span>
      </DialogTitle>
      <div className='py-4 px-8 min-w-[600px]'>
        <form onSubmit={formik.handleSubmit}>
          <div className='flex justify-center flex-col gap-4'>
            <FormSelectField
              delay={0}
              label={t('common.table.taskType')}
              fieldName={'type_id'}
              formik={formik}
              path={'/crm/task/types'}
            />
            <FormSelectField
              delay={0}
              label={t('common.fields.staff')}
              fieldName={'staff_id'}
              formik={formik}
              path={'/dictionary/staffes'}
            />
            <FormDateField
              delay={0}
              label={t('common.fields.date')}
              formik={formik}
              fieldName='date'
            />
            <FormTextField
              delay={0}
              // label={t('common.fields.name')}
              fieldName='title'
              rows={3}
              multiline={true}
              formik={formik}
            />
            <div className='flex justify-between items-center'>
              <div>
                {t('sidebar.admin.lead')}: {leads?.length}
              </div>
              <FormActionButtons
                delay={0}
                isSubmitting={isSubmitting}
                formType='dialog'
                setOpen={setOpen}
                reset={formik.resetForm}
              />
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  );
});
