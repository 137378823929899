import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import useAxiosPrivate from 'hooks/useAxiosPrivate';

const TagsMultiSelect = () => {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const [selectedBlocks, setSelectedBlocks] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    data: tags,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: '/crm/tags/index',
    queryFn: async function () {
      const response = await axiosPrivate.get('/crm/tags/index');
      return response.data.data;
    },
    enabled: !hasError,
    onError: () => {
      setHasError(true);
    },
    retry: false,
  });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    clearAll();
    setSearchParams(searchParams);
    value?.forEach((v, i) => {
      searchParams.set(`tags[${i}]`, v);
    });
    setSearchParams(searchParams);
  };

  const handleDeleteItem = (value) => {
    let leftSelectedBlocks = selectedBlocks.filter((item) => item != value);
    clearAll();
    leftSelectedBlocks.forEach((item, index) =>
      searchParams.set(`tags[${index}]`, item)
    );
    setSearchParams(searchParams);
  };

  const clearAll = () => {
    let entries = Object.fromEntries(searchParams);
    Object.keys(entries).forEach((item) => {
      if (item.includes('tags[')) searchParams.delete(item);
    });
  };

  useEffect(() => {
    if (tags) {
      const entries = Object.fromEntries(searchParams);
      const selected = [];
      Object.entries(entries).forEach(([key, val]) => {
        if (key.includes('tags[')) selected.push(val);
      });
      setSelectedBlocks(selected);
    }
  }, [tags, searchParams]);

  const handleClearSelectBox = () => {
    clearAll();
    setSelectedBlocks([]);
    setSearchParams(searchParams);
  };

  return (
    <FormControl fullWidth color='formColor'>
      <InputLabel id='blocks-filter-label'>
        {t('common.fields.tags')}
      </InputLabel>
      <Select
        labelId='blocks-filter-label'
        id='blocks-filter-multiple-chip'
        multiple
        value={selectedBlocks}
        onChange={handleChange}
        input={
          <OutlinedInput
            id='blocks-filter-select-multiple-chip'
            variant='filterField'
            label={t('common.filter.blocks')}
          />
        }
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                key={`block-${value}`}
                label={value}
                variant='tableBadge'
                color='primary'
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                onDelete={() => handleDeleteItem(value)}
              />
            ))}
          </Box>
        )}
        color='formColor'
        variant='outlined'
        MenuProps={{
          id: 'blocks-filter-select-menu',
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
          disableScrollLock: true,
        }}
        endAdornment={
          <InputAdornment position='end' className='custom-endAdornment'>
            <IconButton
              onClick={() => handleClearSelectBox()}
              size='small'
              variant='onlyIcon'
              sx={{
                display: selectedBlocks.length > 0 ? '' : 'none',
              }}
              className='select-box-end-adornment-button'
            >
              <i className='bi bi-x end-adornment-close-button' />
            </IconButton>
          </InputAdornment>
        }
      >
        {isLoading || isFetching ? (
          <div className='circular-progress-box'>
            <CircularProgress size={25} />
          </div>
        ) : tags && tags.length > 0 ? (
          tags.map((item, index) => (
            <MenuItem value={item?.tag} key={`block-${index + 1}`}>
              {item?.tag}
            </MenuItem>
          ))
        ) : (
          <div>
            <span className='no-data-found-wrapper select-box'>
              <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
              {t('common.global.noDataFound')}
            </span>
          </div>
        )}
      </Select>
    </FormControl>
  );
};

export default TagsMultiSelect;
