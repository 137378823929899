/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Menu,
  MenuItem,
} from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import formLocalizedHelperText from 'utils/formLocalizedHelperText';

const FormTreeSelectField2 = ({
  size,
  label,
  formik,
  parentLabel,
  parentValue,
  childLabel,
  childValue,
  childOptionField,
  fieldName,
  path,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);
  const [objects, setObjects] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getFieldValue = () => {
    if (fieldName && fieldName.includes('.')) {
      let a = fieldName.split('.');
      let b = formik.values;
      for (let i = 0; i < a.length; i++) {
        b = b?.[a?.[i]];
      }
      return b;
    }
    return formik.values?.[fieldName] || '';
  };

  const { setFieldValue } = formik;

  const { isLoading, isFetching } = useQuery({
    queryKey: path,
    queryFn: async function () {
      const response = await axiosPrivate.get(path);
      return response.data.data;
    },
    onSuccess: (data) => {
      setObjects(data);
    },
    enabled: !hasError,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const onSelectAll = (children, e) => {
    const { checked } = e?.target;
    const ids = children?.map((item) => item?.[childValue]);
    if (checked) {
      const values = getFieldValue();
      setFieldValue(fieldName, [...new Set([...ids, ...values])]);
    } else {
      const values = getFieldValue();
      setFieldValue(
        fieldName,
        values?.filter((block) => !ids?.includes(block))
      );
    }
  };

  const onSelectBlock = (id) => {
    const values = getFieldValue();
    if (Array.isArray(values)) {
      if (values?.includes(id)) {
        setFieldValue(
          fieldName,
          values?.filter((block) => block != id)
        );
      } else {
        setFieldValue(fieldName, [id, ...values]);
      }
    }
  };

  return (
    <FormControl fullWidth color='formColor' className='!m-0'>
      <div
        className={`border w-full ${
          formik.touched[fieldName] && Boolean(formik.errors[fieldName])
            ? '!border-red-600'
            : '!border-gray-400'
        } mt-2 p-[5px] rounded-lg mb-1`}
      >
        <Button
          id='basic-button'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant='outlined'
          fullWidth
          size={size}
          sx={{
            border: 0,
            borderRadius: 0,
            paddingRight: 0,
            borderColor: '#000',
            justifyContent: 'space-between',
            '&:hover, &focus': {
              border: 0,
            },
          }}
          endIcon={
            <i className='bi bi-caret-down-fill !text-[12px] !pr-[8px]' />
          }
          className={`${
            formik.touched[fieldName] && Boolean(formik.errors[fieldName])
              ? '!text-red-600'
              : '!text-black'
          } !justify-between !relative !pl-0`}
        >
          <div className='flex justify-start !max-w-[calc(100%-30px)] gap-2 relative !overflow-hidden !text-ellipsis !whitespace-nowrap !pr-[15px]'>
            {getFieldValue()?.length ? (
              <div className='px-2 rounded-full bg-green-600 text-white'>
                {getFieldValue()?.length || 0}
              </div>
            ) : (
              ''
            )}
            {getFieldValue()?.length
              ? t('common.filter.selectedObjects')
              : label || t('common.filter.select')}
          </div>
        </Button>
      </div>
      {formik.touched[fieldName] && Boolean(formik.errors[fieldName]) ? (
        <span className='text-red-600 text-[12px]'>
          {formLocalizedHelperText(formik.errors[fieldName])}
        </span>
      ) : null}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className='!shadow-none'
        PaperProps={{
          sx: { marginTop: '4px', width: '300px' },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: { padding: 0 },
        }}
      >
        {isLoading || isFetching ? (
          <div className='circular-progress-box'>
            <CircularProgress size={25} />
          </div>
        ) : objects && objects.length > 0 ? (
          objects.map((item, index) => (
            <Accordion
              // onChange={() => onClickParent(item?.[parentValue])}
              className='!shadow-none !p-0 !m-0'
              key={item?.[parentValue]}
            >
              <AccordionSummary
                className='flex-row-reverse  !p-0 !m-0'
                expandIcon={<i className='bi bi-caret-down-fill !px-3' />}
                onClick={(e) => e.stopPropagation()}
                aria-controls='panel1-content'
                id='panel1-header'
              >
                <MenuItem
                  value={item?.id}
                  key={`object-${index + 1}`}
                  fullWidth
                  className={`!w-full flex !justify-between !h-full !m-0 !text-[12px] !normal-case`}
                >
                  {item?.[parentLabel]}
                  <Checkbox
                    onClick={(e) => {
                      e?.stopPropagation();
                    }}
                    checked={item?.[childOptionField]?.every((ch) =>
                      getFieldValue()?.includes(ch?.id)
                    )}
                    indeterminate={
                      !item?.[childOptionField]?.every((ch) =>
                        getFieldValue()?.includes(ch?.id)
                      ) &&
                      item?.[childOptionField]?.some((ch) =>
                        getFieldValue()?.includes(ch?.id)
                      )
                    }
                    onChange={(e) => onSelectAll(item?.[childOptionField], e)}
                  />
                </MenuItem>
              </AccordionSummary>
              <AccordionDetails className='!p-0 !pl-10'>
                {item?.[childOptionField]?.map((child) => {
                  return (
                    <div
                      className='flex'
                      key={`block-${index}-${child?.[childValue]}`}
                    >
                      <div className='!min-w-[18px]'>
                        {getFieldValue()?.includes(child?.[childValue]) ? (
                          <i className='bi bi-check' />
                        ) : (
                          ''
                        )}
                      </div>
                      <MenuItem
                        className={`grow !text-[12px] !normal-case`}
                        sx={{
                          background: child?.color || '#87898E',
                          color: 'white',
                          fontWeight: 600,
                          '&:hover': {
                            opacity: 0.9,
                            background: child?.color || '#87898E',
                            color: 'black',
                          },
                          '&:focus': {
                            opacity: 0.9,
                            background: child?.color || '#87898E',
                            color: 'black',
                          },
                        }}
                        value={child?.[childValue]}
                        onClick={() =>
                          onSelectBlock(child?.[childValue], child)
                        }
                      >
                        {child?.[childLabel]}
                      </MenuItem>
                    </div>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <div>
            <span className='no-data-found-wrapper select-box'>
              <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
              {t('common.global.noDataFound')}
            </span>
          </div>
        )}
      </Menu>
    </FormControl>
  );
};

export default memo(FormTreeSelectField2);
