import React, { memo, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useTopPanel from 'hooks/useTopPanel';
import SearchInputNavbar from 'components/SearchInputNavbar';
import ModalNewFieldMaker from './ModalNewFieldMaker';
import BaseTable from 'components/ui/tables/BaseTable';

const Target = () => {
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();
  const [refetch, setRefetch] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setComponent(
      <div className='component-title'>{t('settings.variables.title')}</div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <div className='component-list-wrapper'>
      <div className='component-list-header mb-2'>
        <div className='header-actions-container py-3 flex flex-row items-center'>
          <div className='header-actions filter-box flex items-center rounded-lg w-full'>
            <Button
              variant='base'
              color='primary'
              startIcon={<i className='bi bi-filter' />}
              className='!mr-2'
            >
              {t('common.button.filter')}
            </Button>
            <SearchInputNavbar fullWidth />
          </div>
          <div className='header-actions action-buttons-box py-3 pl-4 rounded-lg flex items-center justify-center ml-4'>
            <Button
              variant='base'
              color='info'
              onClick={() => {
                setRefetch(true);
              }}
              disable={`${refetch}`}
            >
              <i
                className={`bi bi-arrow-repeat${
                  refetch ? ' animate-spin' : ''
                }`}
              />
            </Button>
            <Button
              variant='base'
              className='!mx-2'
              onClick={() => setIsOpenModal(true)}
            >
              <i className='bi bi-plus-circle' />
            </Button>
            <Button variant='base' onClick={() => setOpen(true)}>
              <i className='bi bi-gear' />
            </Button>
          </div>
        </div>
      </div>

      <div className='component-table-wrapper relative h-[calc(100vh-175px)]'>
        <BaseTable
          emitRefetch={{ refetch, setRefetch }}
          dataPath='admin/variables/index'
          emitTableColumns={{ open, setOpen }}
          tableName='staff'
          headCells={[
            { code: 'id', label: '#' },
            { code: 'name', label: t('common.table.name') },
            { code: 'page', label: t('common.table.objectName') },
            { code: 'type', label: t('common.fields.type') },
            {
              code: 'isrequired',
              label: t('settings.variables.addModal.requiredField'),
            },
          ]}
          columns={[
            { code: 'id' },
            { code: 'name' },
            { code: 'page' },
            { code: 'type' },
            { code: 'isrequired' },
          ]}
          // actionModalEdit={{
          //   setOpen: setIsOpenModal,
          //   setItemId: setSelected,
          // }}
          noActions
        />
      </div>
      {isOpenModal && (
        <ModalNewFieldMaker
          open={isOpenModal}
          setOpen={setIsOpenModal}
          selected={selected}
          setSelected={setSelected}
          refetch={() => setRefetch(true)}
        />
      )}
    </div>
  );
};

export default memo(Target);
