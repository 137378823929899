import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ConfirmationAllLeadModal = ({
  open,
  setOpen,
  setOpenAction,
  confirmAllChanges,
  setConfirmAllChanges,
  countOfLeads,
}) => {
  const { t } = useTranslation();
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    if (open) setConfirmAllChanges(false);
  }, []);

  const handleClose = () => {
    setOpen('');
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
      PaperProps={{ className: '!base-border !shadow-none !max-w-[500px]' }}
    >
      <DialogTitle
        id='alert-dialog-title'
        className='!bg-gray-100 !text-center !mb-4'
      >
        <span>
          {confirmation
            ? t('crm.allLeadsChange.confirmation2Title', {
                value: countOfLeads,
              })
            : t('crm.allLeadsChange.confirmationTitle')}
        </span>
      </DialogTitle>

      <DialogContent>
        {confirmation ? (
          <div>
            <FormControlLabel
              control={<Checkbox />}
              checked={confirmAllChanges}
              onChange={(e) => {
                setConfirmAllChanges(e.target.checked);
              }}
              label={t('crm.allLeadsChange.confirmation2label', {
                value: countOfLeads,
              })}
            />
            <div className='flex justify-end gap-4'>
              <Button
                variant='outlined'
                className='whitespace-nowrap'
                disabled={!confirmAllChanges}
                onClick={() => {
                  setOpenAction(true);
                  setConfirmAllChanges(true);
                }}
              >
                {t('common.button.accept')}
              </Button>
              <Button
                className='whitespace-nowrap'
                color='secondary'
                onClick={() => setConfirmation(false)}
              >
                {t('common.button.cancel')}
              </Button>
            </div>
          </div>
        ) : (
          <div className='flex justify-end gap-4'>
            <Button
              variant='outlined'
              className='whitespace-nowrap'
              onClick={() => {
                setOpenAction(true);
                setConfirmAllChanges(false);
              }}
            >
              {t('crm.allLeadsChange.acceptOnlyPage')}
            </Button>
            <Button
              className='whitespace-nowrap'
              color='secondary'
              onClick={() => setConfirmation(true)}
            >
              {t('crm.allLeadsChange.acceptAll')}
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default memo(ConfirmationAllLeadModal);
