import { Fragment, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import {
  Button,
  Checkbox,
  LinearProgress,
  MenuItem,
  Pagination,
  Select,
  TableHead,
} from '@mui/material';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

import setTableCellType from 'utils/setTableCellType';
import TableColumnToggleDrawer from 'components/TableColumnToggleDrawer';
import useColumnToggle from 'hooks/useColumnToggle';
import addActiveInTableCell from 'utils/addActiveInTableCell';
import LeadChangeStatusModal from './LeadChangeStatusModal';
import ConfirmationAllLeadModal from './ConfirmationAllLeadModal';
import StaffChangeModal from './StaffChangeModal';
import TaskCreateModal from './TaskCreateModal';
import useMenu from 'hooks/useMenu';

export default memo(function TableList() {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasError, setHasError] = useState(false);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [confirmAllChanges, setConfirmAllChanges] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [actionType, setActionType] = useState('');
  const { search } = useLocation();
  const { hasChild } = useMenu();

  const view = (code) => hasChild('CRM', code);

  const headCells = [
    { code: 'name', label: t('lead.view.name') },
    { code: 'contactone', label: t('common.fields.name') },
    { code: 'crm_block_item', label: t('common.filter.crm_block_item_id') },
    { code: 'budget', label: t('common.fields.budget') },
  ];
  const columns = [
    { code: 'name' },
    { code: 'contactone', type: 'nested', childStr: 'name' },
    { code: 'crm_block_item', type: 'nested', childStr: 'name' },
    { code: 'budget' },
  ];
  const { tableData, tableHeadCells, tableColumnCells, setTableHeadCells } =
    useColumnToggle(
      'crm-table-1',
      addActiveInTableCell(headCells),
      addActiveInTableCell(columns)
    );
  const [queryPath, setQueryPath] = useState('');
  const {
    error,
    data: rows,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['/crm/crm-lead/table/list-all-page', queryPath],
    queryFn: async function () {
      if (queryPath) {
        const response = await axiosPrivate.post(queryPath);
        return response?.data?.data;
      } else return {};
    },
    // onSettled: () => {
    //   setRefetch(false);
    // },
    onSuccess: () => {
      setSelectedRows([]);
    },
    enabled:
      !hasError &&
      !!searchParams.get('perpage') &&
      !!searchParams.get('crm_block'),
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const { data: allIds } = useQuery({
    queryKey: ['/crm/crm-lead/table/', queryPath, rows?.total],
    queryFn: async function () {
      if (queryPath) {
        const response = await axiosPrivate.post(
          `/crm/crm-lead/table/${searchParams.get('crm_block')}${
            search || ''
          }&perpage=${rows?.total}&page=1`
        );
        return response?.data?.data?.data?.map((d) => ({ id: d?.id })) || [];
      } else return {};
    },
    // onSettled: () => {
    //   setRefetch(false);
    // },
    enabled: !hasError && !!rows?.total,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const ids = useMemo(() => {
    if (!rows?.data?.length) return [];
    return rows?.data?.map(({ id }) => id);
  }, [rows?.data]);

  useEffect(() => {
    if (queryPath && queryPath.length > 0) {
      refetch();
    }
  }, [queryPath]);

  useEffect(() => {
    createQueryPath();
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.get('perpage')) {
      searchParams.set('perpage', 200);
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const handleChangePage = (event, newPage) => {
    searchParams.set('page', newPage);
    setSearchParams(searchParams);
  };

  const handleChangePageSize = (event) => {
    const { value } = event.target;
    searchParams.set('perpage', value);
    setSearchParams(searchParams);
  };

  const createQueryPath = () => {
    let newQueryPath = `/crm/crm-lead/table/${searchParams.get('crm_block')}`;
    let localSearchParams = Object.fromEntries([...searchParams]);
    const search = Object.keys(localSearchParams)?.filter(
      (key) => key !== 'staff' && key !== 'block'
    );
    search.forEach((item, index) => {
      if (item !== 'staff' && item !== 'block')
        if (index === 0) {
          newQueryPath += `?${item}=${localSearchParams[item]}`;
        } else {
          newQueryPath += `&${item}=${localSearchParams[item]}`;
        }
    });
    setQueryPath(newQueryPath);
  };

  const onChange = (e) => {
    const { checked, name } = e?.target;
    const id = Number(name);
    const setData = new Set(selectedRows);
    if (checked) {
      setData.add(id);
    } else {
      setData.delete(id);
    }
    setSelectedRows(Array.from(setData));
  };

  const onChangeAll = (e) => {
    const { checked } = e?.target;
    if (checked) {
      setSelectedRows(ids);
    } else {
      setSelectedRows([]);
    }
  };

  const onChangeActionsAll = (e) => {
    setSelectedRows([]);
  };

  const isAllChecked = useMemo(() => {
    if (!ids?.length) return false;
    return ids?.every((el) => selectedRows?.includes(el));
  }, [selectedRows, ids]);

  const leads = useMemo(() => {
    if (isAllChecked) {
      if (confirmAllChanges) {
        return allIds;
      }
      return ids?.map((id) => ({ id }));
    }
    return selectedRows?.map((id) => ({ id }));
  }, [selectedRows, ids, allIds, isAllChecked, confirmAllChanges]);

  return (
    <Box className='base-table w-full h-[calc(100%-0px)] flex flex-col'>
      <LeadChangeStatusModal
        open={(openModal || !isAllChecked) && actionType === 'change-status'}
        setOpen={() => {
          setOpenModal(false);
          setActionType('');
        }}
        leads={leads}
        refetchFn={refetch}
      />
      <StaffChangeModal
        open={(openModal || !isAllChecked) && actionType === 'change-staff'}
        setOpen={() => {
          setOpenModal(false);
          setActionType('');
        }}
        leads={leads}
      />
      <TaskCreateModal
        open={(openModal || !isAllChecked) && actionType === 'create-task'}
        setOpen={() => {
          setOpenModal(false);
          setActionType('');
        }}
        leads={leads}
      />
      {actionType ? (
        <ConfirmationAllLeadModal
          open={!openModal && isAllChecked && actionType}
          setOpen={setActionType}
          setOpenAction={setOpenModal}
          countOfLeads={rows?.total || 0}
          confirmAllChanges={confirmAllChanges}
          setConfirmAllChanges={setConfirmAllChanges}
        />
      ) : null}
      <TableContainer className={`flex-auto h-[calc(100%-70px)]`}>
        <Table
          stickyHeader
          sx={{ minWidth: 750, height: 'max-content' }}
          aria-labelledby='tableTitle'
          size='small'
        >
          {tableHeadCells && tableColumnCells && (
            <Fragment>
              <TableHead>
                <TableRow className='sticky !z-[1000] top-0'>
                  <TableCell
                    align={'left'}
                    className='!relative !z-30 max-w-16 w-16'
                  >
                    {selectedRows?.length > 1 && view('CRM_CHANGE_ACTIONS') && (
                      <div className='absolute top-0 left-0 bottom-0 h-full z-[999] bg-inherit px-4'>
                        <div className='h-full flex items-center gap-4'>
                          <Checkbox
                            checked={isAllChecked}
                            value={'all'}
                            indeterminate={!isAllChecked}
                            onChange={onChangeActionsAll}
                          />
                          <Button
                            variant='contained'
                            color='error'
                            startIcon={<i className='bi bi-trash3' />}
                            size='small'
                          >
                            {t('common.button.delete')}
                          </Button>
                          <Button
                            variant='contained'
                            color='info'
                            size='small'
                            startIcon={<i className='bi bi-recycle' />}
                            className='whitespace-nowrap'
                            onClick={() => {
                              setActionType('change-status');
                            }}
                          >
                            {t('common.button.changeLeadStatus')}
                          </Button>
                          <Button
                            variant='contained'
                            color='info'
                            size='small'
                            startIcon={<i className='bi bi-person-add' />}
                            className='whitespace-nowrap'
                            onClick={() => {
                              setActionType('change-staff');
                            }}
                          >
                            {t('tasks.actions.changeStaff')}
                          </Button>
                          <Button
                            variant='contained'
                            color='info'
                            size='small'
                            startIcon={
                              <i className='bi bi-file-earmark-plus' />
                            }
                            className='whitespace-nowrap'
                            onClick={() => {
                              setActionType('create-task');
                            }}
                          >
                            {t('common.button.createTask')}
                          </Button>
                        </div>
                      </div>
                    )}
                    <Checkbox
                      checked={isAllChecked}
                      value={'all'}
                      onChange={onChangeAll}
                    />
                  </TableCell>
                  {tableHeadCells?.map((cell, i) => (
                    <TableCell key={i} align={'left'}>
                      {cell?.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {isLoading || isFetching ? (
                <TableBody className='overflow-hidden'>
                  <TableRow>
                    <TableCell colSpan={tableHeadCells.length + 1}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : isError ? (
                <TableBody className='overflow-hidden'>
                  <TableRow>
                    <TableCell colSpan={tableHeadCells.length + 1}>
                      <div className='flex flex-col items-center'>
                        {error?.response?.data?.message && (
                          <span className='text-red-600 font-medium'>
                            {error?.response?.data?.message}
                          </span>
                        )}
                        {error?.response?.data?.details &&
                          error?.response?.data?.details[0]?.message && (
                            <div>
                              <span className='text-red-600 font-medium'>
                                {t('common.errors.message')}
                              </span>
                              <span>
                                {error?.response?.data?.details[0]?.message}
                              </span>
                            </div>
                          )}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : rows && rows?.data && rows?.data?.length > 0 ? (
                <Fragment>
                  <TableBody className='overflow-hidden'>
                    {rows.data.map((row, rowIndex) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={'row-' + rowIndex}
                          htmlFor={`${row?.id}`}
                          component={'label'}
                          className={`!cursor-pointer ${
                            selectedRows?.includes(row?.id)
                              ? 'after:block after:absolute after:top-0 after:left-0 after:w-1 after:h-full after:bg-base-color-light'
                              : ''
                          }`}
                        >
                          <TableCell
                            align={'left'}
                            className='relative max-w-16 !bg-inherit'
                          >
                            {selectedRows?.includes(row?.id) &&
                              selectedRows?.length == 1 &&
                              view('CRM_CHANGE_ACTIONS') && (
                                <div className='absolute top-0 left-16 bottom-0 h-full z-10 bg-inherit px-4'>
                                  <div className='h-full flex items-center gap-4'>
                                    <Button
                                      variant='contained'
                                      color='error'
                                      startIcon={<i className='bi bi-trash3' />}
                                      size='small'
                                    >
                                      {t('common.button.delete')}
                                    </Button>
                                    <Button
                                      variant='contained'
                                      color='info'
                                      size='small'
                                      startIcon={
                                        <i className='bi bi-recycle' />
                                      }
                                      className='whitespace-nowrap'
                                      onClick={() => {
                                        setActionType('change-status');
                                      }}
                                    >
                                      {t('common.button.changeLeadStatus')}
                                    </Button>
                                    <Button
                                      variant='contained'
                                      color='info'
                                      size='small'
                                      startIcon={
                                        <i className='bi bi-person-add' />
                                      }
                                      className='whitespace-nowrap'
                                      onClick={() => {
                                        setActionType('change-staff');
                                      }}
                                    >
                                      {t('tasks.actions.changeStaff')}
                                    </Button>
                                    <Button
                                      variant='contained'
                                      color='info'
                                      size='small'
                                      startIcon={
                                        <i className='bi bi-file-earmark-plus' />
                                      }
                                      className='whitespace-nowrap'
                                      onClick={() => {
                                        setActionType('create-task');
                                      }}
                                    >
                                      {t('common.button.createTask')}
                                    </Button>
                                  </div>
                                </div>
                              )}
                            <Checkbox
                              id={`${row?.id}`}
                              name={`${row?.id}`}
                              value={`${row?.id}`}
                              onChange={onChange}
                              checked={selectedRows?.includes(row?.id)}
                            />
                          </TableCell>
                          {tableColumnCells.map((column, columnIndex) => (
                            <TableCell
                              align={
                                tableHeadCells[columnIndex]?.numeric
                                  ? 'right'
                                  : 'left'
                              }
                              key={'column-' + columnIndex}
                            >
                              {column?.code == 'status' ? (
                                t('boss.crm.general.active')
                              ) : column.type ? (
                                setTableCellType(column, row[column.code], row)
                              ) : column.code == 'name' ? (
                                <Link
                                  className='no-underline text-blue-500'
                                  to={`/admin/crm/lead/edit/${row?.id}`}
                                >
                                  {row[column.code]}
                                </Link>
                              ) : (
                                row[column.code]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Fragment>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={tableHeadCells.length + 1}>
                      <span className='no-data-found-wrapper'>
                        <i className='bi bi-exclamation-octagon text-xl leading-4 mr-1' />{' '}
                        {t('common.global.noDataFound')}
                      </span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Fragment>
          )}
        </Table>
      </TableContainer>
      {rows && rows?.data && rows?.data?.length > 0 && (
        <div className='relative p-3 mb-2 mt-4 flex items-center justify-center base-border bg-white'>
          <Pagination
            count={Math.ceil(rows?.total / rows?.per_page) || 1}
            page={parseInt(searchParams.get('page')) || 1}
            onChange={handleChangePage}
            shape='rounded'
            color='primary'
            showFirstButton
            showLastButton
          />

          <div className='ml-4 w-20'>
            <Select
              value={searchParams.get('perpage') || '200'}
              onChange={handleChangePageSize}
              fullWidth
            >
              <MenuItem value={'200'}>200</MenuItem>
              <MenuItem value={'500'}>500</MenuItem>
              <MenuItem value={'1000'}>1000</MenuItem>
            </Select>
          </div>
          {rows?.total ? (
            <div className='absolute top-1/2 -translate-y-1/2 right-10'>
              {t('common.table.total')}: {rows?.total || '0'}
            </div>
          ) : (
            ''
          )}
        </div>
      )}
      {/* <TaskTypeModal
        open={actionType === 'type'}
        setOpen={setActionType}
        data={selectedRows}
        setData={setActionType}
        refetchFn={refetch}
      />
      <TaskTimeModal
        open={actionType === 'time'}
        setOpen={setActionType}
        data={selectedRows}
        setData={setActionType}
        refetchFn={refetch}
      />
      <TaskStaffModal
        open={actionType === 'staff'}
        setOpen={setActionType}
        data={selectedRows}
        setData={setActionType}
        refetchFn={refetch}
      />
      <TaskDeleteModal
        open={actionType === 'delete'}
        setOpen={setActionType}
        data={selectedRows}
        refetchFn={refetch}
      />
      <TaskCompleteModal
        open={actionType === 'complete'}
        setOpen={setActionType}
        data={selectedRows}
        refetchFn={refetch}
      /> */}
      <TableColumnToggleDrawer
        open={open}
        setOpen={setOpen}
        tableData={tableData}
        setTableHeadCells={setTableHeadCells}
      />
    </Box>
  );
});
