import {
  Button,
  ButtonBase,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { memo, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import FromMultiSelect from 'components/ui/form/FormMultiSelectField';
import useFormSubmit from 'hooks/useFormSubmit';

const PlanSetToHomeModal = ({
  open = false,
  setOpen = () => {},
  blocks = [],
  selected = {},
  setSelected = () => {},
  selectedHomes = [],
  isLoading,
  handleBindHomesToPolygon = () => {},
}) => {
  const { t } = useTranslation();
  const { submit, isSubmitting } = useFormSubmit();
  const [sHomes, setSHomes] = useState(selectedHomes);
  const formik = useFormik({
    initialValues: {
      block_ids: [],
    },
  });

  const handleSave = () => {
    const body = {
      plan_id: selected?.id,
      homes: sHomes?.map((id) => ({ id })),
    };
    submit(
      { type: 'post', contentType: 'simple' },
      body,
      '/admin/plan/set-homes',
      {
        title: t('block.imageAddEditModal.alerts.success', {
          objectName: selected?.objects?.name,
          blockName: '',
        }),
      },
      null,
      true,
      () => {
        setSelected({});
        setOpen(false);
      }
    );
  };

  const homes = useMemo(() => {
    if (!blocks?.length) return [];
    if (!formik.values?.block_ids?.length) return blocks;

    return (
      blocks?.filter((block) =>
        formik?.values?.block_ids?.includes(block?.id)
      ) || []
    );
  }, [blocks, formik?.values?.block_ids]);

  const toggleSelectionItem = (id) => {
    if (sHomes.includes(id)) {
      sHomes.splice(sHomes.indexOf(id), 1);
    } else {
      sHomes.push(id);
    }
    setSHomes(JSON.parse(JSON.stringify(sHomes)));
  };

  const isHomeItemSelected = (homeId) => {
    return sHomes.includes(homeId) ? '!bg-blue-400 !text-white' : '!bg-white';
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setSelected({});
        setOpen(false);
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      disableEscapeKeyDown={true}
      fullWidth={true}
      maxWidth={'lg'}
      PaperProps={{ className: '!base-border !shadow-none' }}
    >
      <DialogTitle
        id='alert-dialog-title'
        className='!bg-gray-100 !text-center'
      >
        <span className='mr-3'>{t('plan.setPlanToHomes')}</span>
        <div className='grid grid-cols-[1fr,100px] gap-4'>
          <FromMultiSelect
            delay={0}
            fieldName='block_ids'
            label={t('common.fields.blocks')}
            formik={formik}
            path={`admin/block/index/${selected?.object_id}`}
            isitemsizesmall={true}
          />
          <div className='flex items-center justify-end pb-1'>
            <Button
              color='success'
              variant='contained'
              size='large'
              onClick={() => handleSave()}
              disabled={!sHomes?.length || isSubmitting}
            >
              {t('common.button.save')}
            </Button>
          </div>
        </div>
      </DialogTitle>
      <DialogContent className='relative'>
        <div className='homes-modal-body-wrapper min-h-[200px] py-2'>
          <div
            className={`px-2 flex ${
              isSubmitting ? 'w-full overflow-hidden' : ''
            }`}
          >
            {isSubmitting ? (
              <div className='absolute z-50 inset-0 bg-[rgba(255,255,255,0.9)] flex items-center justify-center'>
                <CircularProgress size={30} />
              </div>
            ) : null}
            {isLoading ? (
              <div className='circular-progress-box h-[200px] w-full'>
                <CircularProgress size={30} />
              </div>
            ) : (
              homes?.map((block, i) => (
                <div key={i}>
                  <div className='ml-5'>{block?.name}</div>
                  <Blocks
                    homes={block?.homes}
                    isHomeItemSelected={isHomeItemSelected}
                    toggleSelectionItem={toggleSelectionItem}
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(PlanSetToHomeModal);

const Blocks = ({ homes, isHomeItemSelected, toggleSelectionItem }) => {
  const [stages, setStages] = useState([]);
  const [size, setSize] = useState(1);
  useEffect(() => {
    let uniqStages = new Set(homes.map((item) => item.stage));
    let a = Array.from(uniqStages);
    if (a.includes('-1')) {
      a.push('0');
    }
    a.sort((x, y) => x - y);
    setStages([...a.reverse()]);
    setSize(uniqStages.size);
  }, [homes]);

  return (
    <div className='px-2'>
      {stages.map((floorNumber, index) => (
        <div
          className={`sheet-row floor-${floorNumber}`}
          key={`block-home-row-${index}`}
          id={`block-home-row-${index}`}
        >
          <Grid
            container
            rowSpacing={0}
            columns={{
              xs: parseInt(size),
              sm: parseInt(size),
            }}
            className='sheet-home-row !px-2 !py-1 !rounded-md !gap-1 items-center hover:bg-blue-100 !flex-nowrap'
          >
            <Grid
              item={true}
              sm={1}
              xs={1}
              className='sheet-home-cell min-w-[35px] min-h-[35px] !-ml-2.5 !mr-0'
            >
              <ButtonBase
                className='sheet-home-row-floor min-w-[35px] min-h-[35px] h-full w-8 text-sm font-medium !cursor-default'
                disableRipple
              >
                {floorNumber}
              </ButtonBase>
            </Grid>
            {homes.filter((home) => home.stage === floorNumber).length > 0
              ? homes
                  .filter((home) => home.stage === floorNumber)
                  .map((item) => (
                    <Grid
                      item={true}
                      sm={1}
                      xs={1}
                      key={`home-${item.id}`}
                      id={`home-${item.id}`}
                      className={`min-w-[35px] !m-0.5 min-h-[35px] floor-${floorNumber}`}
                    >
                      <ButtonBase
                        className={`home !shadow-sm !rounded-[4px] !shadow-blue-500 !h-[35px] !w-[35px] home-item ${isHomeItemSelected(
                          item.id
                        )}`}
                        id={`home-${item.id}`}
                        onClick={() => toggleSelectionItem(item.id)}
                      >
                        {!(item.stage && parseInt(item.stage) < 0) ? (
                          <div>{item.number}</div>
                        ) : (
                          <div>P</div>
                        )}
                      </ButtonBase>
                    </Grid>
                  ))
              : [1].map((item) => (
                  <Grid
                    item={true}
                    sm={1}
                    xs={1}
                    key={`home-empty-${item}`}
                    id={`home-empty-${item}`}
                    className={`sheet-home-cell sheet-home-empty-cell floor-${floorNumber}`}
                  >
                    <ButtonBase className='home-item is-empty'>
                      <div className='home-item-data'></div>
                    </ButtonBase>
                  </Grid>
                ))}
          </Grid>
        </div>
      ))}
    </div>
  );
};
