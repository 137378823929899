import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import amoCrm from 'assets/images/amo-crm-logo.bmp';
import onlinePbx from 'assets/images/online-pbx.png';
import AmoCrmSettings from './crmColumn elements/CrmAddEditModal';
import OnlinePbxSettings from './crmColumn elements/PbxAddEditModal';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import { useQueries } from 'react-query';

export default function SettingCrmColumn({ data }) {
  const [open, setOpen] = useState('');
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);

  const [amo, pbx] = useQueries([
    {
      queryKey: '/admin/crm-settings/amocrm',
      queryFn: async function () {
        const response = await axiosPrivate.get(`/admin/crm-settings/amocrm`);
        return response.data.data;
      },
      enabled: !hasError,
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
    {
      queryKey: '/admin/crm-settings/onlinepbx',
      queryFn: async function () {
        const response = await axiosPrivate.get(
          '/admin/crm-settings/onlinepbx'
        );
        return response.data.data;
      },
      enabled: !hasError,
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
  ]);

  return (
    <Grid
      item={true}
      sm={1}
      xs={1}
      className={
        'min-w-72 [&>.crm-element-wrapper]:mb-4 !mr-4 !bg-[#FCFCFC] !p-2 !rounded-md !border !border-[#5959591A]'
      }
    >
      <div className='crm-element-wrapper shadow-lg w-full h-32 overflow-hidden rounded-lg relative'>
        <img
          src={amoCrm}
          className='w-full h-full object-cover object-center'
          alt='amo crm'
        />
        <div className='absolute z-10 bottom-2 left-1/2 -translate-x-1/2'>
          <Button
            variant='outlined'
            color='primary'
            className='!border-gray-400 !text-gray-400 hover:!text-white hover:!border-white w-32'
            onClick={() => setOpen('amo')}
          >
            <i
              className={`bi ${amo?.data?.id ? 'bi-gear' : 'bi-plus-circle'}`}
            />
          </Button>
        </div>
      </div>
      <div className='crm-element-wrapper shadow-lg w-full h-32 overflow-hidden rounded-lg relative'>
        <img
          src={onlinePbx}
          className='w-full h-full object-cover object-center'
          alt='amo crm'
        />
        <div className='absolute z-10 bottom-2 left-1/2 -translate-x-1/2'>
          <Button
            variant='outlined'
            color='primary'
            className='!border-gray-400 !text-gray-400 hover:!text-blue-400 hover:!border-blue-400 w-32'
            onClick={() => setOpen('pbx')}
          >
            <i
              className={`bi ${pbx?.data?.id ? 'bi-gear' : 'bi-plus-circle'}`}
            />
          </Button>
        </div>
      </div>
      <AmoCrmSettings
        refetch={amo?.refetch}
        data={amo?.data}
        open={open == 'amo'}
        setOpen={setOpen}
      />
      <OnlinePbxSettings
        refetch={pbx?.refetch}
        data={pbx?.data}
        open={open == 'pbx'}
        setOpen={setOpen}
      />
    </Grid>
  );
}
