import { Button, Drawer } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import ContractStatusFilter from './items/GenericRadiosFilter';
import { memo } from 'react';
import StatusFilter from '../ui/filters/items/StatusFilter';
import { currencyTypeSelectOptions } from 'shared/selectOptionsList';
import StaffFilter from '../ui/filters/items/StaffFilter';
import ObjectsBlockTreeFilter from './items/ObjectsBlockTreeFilter';

const ArrearsFilterDrawer = ({ open, setOpen, isLoading = false }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleResetFiler = () => {
    navigate(location.pathname, { replace: true });
  };

  return (
    <Drawer
      anchor='left'
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { padding: '18px', background: 'transparent', boxShadow: 'none' },
      }}
    >
      <div className='dashboard-filter-drawer-wrapper !pt-2 first-letter bg-white h-full rounded-lg border border-[#E5E9EB]'>
        <div className='mb-3 text-end flex justify-between items-center border-b'>
          <div className='text-[16px] leading-[16px] font-semibold'>
            {t('common.button.filter')}
          </div>
          <Button
            color='error'
            // variant='outlined'
            className='!-mr-[5px]'
            size='small'
            disabled={isLoading}
            onClick={() => handleResetFiler()}
          >
            {t('common.button.clear')}
          </Button>
        </div>
        <ObjectsBlockTreeFilter />
        <StaffFilter
          disabled={isLoading}
          label='common.fields.staff'
          query='staff'
        />
        <StatusFilter label={t('common.fields.contractStatus')} />
        <ContractStatusFilter
          options={currencyTypeSelectOptions}
          label={t('common.fields.currency')}
          queryName='isvalute'
        />
      </div>
    </Drawer>
  );
};
export default memo(ArrearsFilterDrawer);
