import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import FilterAccordion from '../FilterAccordion';
import { memo, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { t } from 'i18next';
import { useQuery } from 'react-query';
import useAxiosPrivate from 'hooks/useAxiosPrivate';

const ObjectsBlockTreeFilter = ({ disabled = false }) => {
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedBlocks, setSelectedBlocks] = useState([]);
  const [selectedObjects, setSelectedObjects] = useState([]);

  const { data: ObjectBlock } = useQuery({
    queryKey: '/dictionary/objects3',
    queryFn: async function () {
      const response = await axiosPrivate.get('/dictionary/objects3');
      return response.data.data;
    },
    enabled: !hasError,
    onError: () => {
      setHasError(true);
    },
    retry: false,
  });

  const onSelectObject = (e, id) => {
    e.stopPropagation();
    if (!selectedObjects?.includes(id)) {
      searchParams.set(`object[${id}]`, id);
      setSelectedObjects((ids) => [...ids, id]);
    } else {
      searchParams.delete(`object[${id}]`);

      setSelectedObjects((ids) => ids?.filter((val) => val !== id));
    }
    setSearchParams(searchParams);
  };
  const onSelectBlock = (e, id) => {
    e.stopPropagation();
    if (!selectedBlocks?.includes(id)) {
      searchParams.set(`block[${id}]`, id);
      setSelectedBlocks((ids) => [...ids, id]);
    } else {
      searchParams.delete(`block[${id}]`);

      setSelectedBlocks((ids) => ids?.filter((val) => val !== id));
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const entries = Object.fromEntries(searchParams);
    const objects = [];
    const blocks = [];
    Object.entries(entries).forEach(([key, value]) => {
      if (key.includes('block[')) blocks?.push(Number(value));
      if (key.includes('object[')) objects?.push(Number(value));
    });
    setSelectedObjects(objects);
    setSelectedBlocks(blocks);
  }, []);

  return (
    <FilterAccordion
      title={t('shaxmatka.homeDetail.residentialComplex')}
      code='home-repair-type'
      className='!mb-0.5'
    >
      <div className='mt-1'>
        {ObjectBlock &&
          ObjectBlock.length > 0 &&
          ObjectBlock.map((object, index) => (
            <FilterAccordion
              key={object?.id}
              title={
                <FormControl
                  fullWidth
                  color='formColor'
                  type='newFilterCheckbox'
                  sx={{
                    marginLeft: '-5px',
                    whiteSpace: 'nowrap',
                    width: '100%',
                  }}
                  key={`object-item-${index}`}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id='home-type-free'
                        name={object?.name}
                        checked={selectedObjects?.includes(object?.id)}
                        onChange={(event) => {
                          onSelectObject(event, object?.id);
                        }}
                        disabled={disabled}
                        size='small'
                      />
                    }
                    label={object?.name}
                  />
                </FormControl>
              }
              code='home-repair-type'
              className='!mb-0.5 !bg-gray-100 [&>.MuiAccordionSummary-root]:!border-none [&>.MuiCollapse-root]:!pl-4'
              removeIcon
            >
              {object?.blocks?.length &&
                object?.blocks?.map((block, index) => (
                  <FormControl
                    fullWidth
                    color='formColor'
                    type='newFilterCheckbox'
                    sx={{ marginLeft: '-5px', whiteSpace: 'nowrap' }}
                    key={`object-item-${index}`}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          id='home-type-free'
                          name={block?.name}
                          checked={selectedBlocks?.includes(block?.id)}
                          onChange={(event) => {
                            onSelectBlock(event, block?.id);
                          }}
                          disabled={disabled}
                          size='small'
                        />
                      }
                      label={block?.name}
                    />
                  </FormControl>
                ))}
            </FilterAccordion>
          ))}
      </div>
    </FilterAccordion>
  );
};
export default memo(ObjectsBlockTreeFilter);
