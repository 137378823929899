/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Fragment, memo, useEffect, useState } from 'react';
import {
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  styled,
  Switch,
} from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { Trans, useTranslation } from 'react-i18next';

import useCurrency from 'hooks/useCurrency';
import REPAIR_TYPE from 'shared/repairTypeList';
import ImagesSwiper from 'components/ImagesSwiper';
import CURRENCY_TYPE from 'shared/currencyTypeList';
import { currencyTypeSelectOptions } from 'shared/selectOptionsList';
import { formatMultiplies, formatSingle } from 'utils/formatOddDecimal';
import EditableNumberField from 'components/ui/editable-fields/EditableNumberField';
import SimpleNumberField from 'components/ui/simple-fields/numeric-format/SimpleNumberField';
import SimpleToggleButtonGroup from 'components/ui/simple-fields/toggle-button-group/SimpleToggleButtonGroup';
import DiscountTable from './DiscountTable';
import DiscountPanel from './DiscountPanel';

const CalculationByHomeData = ({
  homeData = {},
  homesData = [],
  order = 0,
  setData = () => {},
  formik,
  applyDiscount,
  setApplyDiscount,
  selectedDiscount,
  setSelectedDiscount,
}) => {
  const { t } = useTranslation();
  const [fullPrice, setFullPrice] = useState(0);
  const [isrepaired, setIsrepaired] = useState(homeData?.isrepaired);
  const [sellPrice, setSellPrice] = useState(0);
  const [startPrice, setStartPrice] = useState(0);
  const [startPriceTopOrder, setStartPriceTopOrder] = useState(false);
  const [startPricePercentage, setStartPricePercentage] = useState(
    homeData?.start
  );
  const [priceForSquare, setPriceForSquare] = useState(
    homeData?.isrepaired == REPAIR_TYPE.REPAIRED.code
      ? homeData?.repaired
      : homeData?.norepaired
  );
  const square = homeData.square;
  const [discount, setDiscount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [month, setMonth] = useState(29);
  const [priceForMonth, setPriceForMonth] = useState(0);
  const [currency, setCurrency] = useState(
    homeData?.isvalute || CURRENCY_TYPE.SUM.code
  );

  const { currencyData } = useCurrency();

  const handleChangeMonth = (value) => {
    setMonth(parseInt(value));
    setPriceForMonth(formatSingle((sellPrice - startPrice) / parseInt(value)));
  };

  const handleChangeStartPrice = (value) => {
    setStartPrice(value);
    setStartPricePercentage(
      sellPrice
        ? formatSingle(
            (value * 100) / (startPriceTopOrder ? fullPrice : sellPrice),
            3
          )
        : 0
    );
    setPriceForMonth(
      formatSingle(
        ((startPriceTopOrder ? fullPrice : sellPrice) - value) / month
      )
    );
  };

  const handleChangeStartPricePercent = (value) => {
    const startPrice =
      ((startPriceTopOrder ? fullPrice : sellPrice) * value) / 100;
    setStartPrice(startPrice);
    setStartPricePercentage(value);
    setPriceForMonth(formatSingle((sellPrice - startPrice) / month));
  };

  const handleChangeStartPriceOrder = (value) => {
    const startPrice = value
      ? (startPricePercentage * fullPrice) / 100
      : (startPricePercentage * sellPrice) / 100;

    setStartPrice(startPrice);
    setPriceForMonth(formatSingle((sellPrice - startPrice) / month));
    setStartPriceTopOrder(value);
  };

  const handleCurrencyTypeChange = (value) => {
    setCurrency(value);
    if (currencyData && currencyData.sum) {
      if (value == CURRENCY_TYPE.VALUTE.code) {
        setFullPrice(fullPrice / currencyData.sum);
        setSellPrice(sellPrice / currencyData.sum);
        setDiscount(discount / currencyData.sum);
        setStartPrice(startPrice / currencyData.sum);
        setPriceForMonth(priceForMonth / currencyData.sum);
        setPriceForSquare(priceForSquare / currencyData.sum);
      } else {
        setFullPrice(fullPrice * currencyData.sum);
        setSellPrice(sellPrice * currencyData.sum);
        setDiscount(discount * currencyData.sum);
        setStartPrice(startPrice * currencyData.sum);
        setPriceForMonth(priceForMonth * currencyData.sum);
        setPriceForSquare(priceForSquare * currencyData.sum);
      }
    }
  };

  const handleChangeSellPrice = (newSellPrice) => {
    if (newSellPrice <= fullPrice) {
      setDiscount(fullPrice - newSellPrice);
      setDiscountPercentage(
        fullPrice
          ? formatSingle(((fullPrice - newSellPrice) * 100) / fullPrice, 2)
          : 0
      );
      setSellPrice(newSellPrice);
      setPriceForMonth(formatSingle((newSellPrice - startPrice) / month));
      setPriceForSquare(formatSingle(newSellPrice / square));
    }
  };

  const handleChangeDiscount = (disc) => {
    const newDisc = disc || 0;
    if (newDisc <= fullPrice) {
      const newStartPrice =
        (startPricePercentage *
          (fullPrice - (startPriceTopOrder ? 0 : newDisc))) /
        100;
      if (startPricePercentage) {
        setStartPrice(newStartPrice);
      }
      setDiscount(newDisc);
      setDiscountPercentage(
        fullPrice ? formatSingle((newDisc * 100) / fullPrice, 2) : 0
      );
      setSellPrice(() => fullPrice - newDisc);
      if (month == 0) {
        setPriceForMonth(0);
      } else
        setPriceForMonth(
          formatSingle((fullPrice - newDisc - newStartPrice) / month)
        );
      setPriceForSquare(formatSingle((fullPrice - newDisc) / square));
    }
  };

  const handleChangeDiscountPercentage = (per) => {
    const newPer = per * 0.01 || 0;
    if (newPer * fullPrice <= fullPrice) {
      const newStartPrice =
        (fullPrice *
          startPricePercentage *
          (1 - (startPriceTopOrder ? 0 : newPer))) /
        100;
      if (startPricePercentage) {
        setStartPrice(newStartPrice);
      }
      setDiscount(newPer * fullPrice);
      setDiscountPercentage(newPer * 100);
      setSellPrice(() => fullPrice * (1 - newPer));
      if (month == 0) {
        setPriceForMonth(0);
      } else
        setPriceForMonth(
          formatSingle((fullPrice - newPer * fullPrice - newStartPrice) / month)
        );
      setPriceForSquare(
        formatSingle((fullPrice - newPer * fullPrice) / square)
      );
    }
  };

  const handleChangePriceSquare = (newPriceForSquare) => {
    const currPriceByRepaired =
      homeData?.isrepaired == REPAIR_TYPE.REPAIRED.code
        ? parseFloat(homeData?.repaired)
        : parseFloat(homeData?.norepaired);

    const maxPrice =
      homeData?.isvalute == CURRENCY_TYPE.SUM.code &&
      currency == CURRENCY_TYPE.VALUTE.code
        ? currPriceByRepaired / currencyData.sum
        : homeData?.isvalute == CURRENCY_TYPE.VALUTE.code &&
          currency == CURRENCY_TYPE.SUM.code
        ? currPriceByRepaired * currencyData.sum
        : currPriceByRepaired;

    if (newPriceForSquare <= maxPrice) {
      const newDiscount = formatMultiplies(
        [maxPrice - newPriceForSquare, homeData.square],
        2
      );
      setDiscount(newDiscount);
      setDiscountPercentage(formatSingle((newDiscount * 100) / fullPrice, 2));
      const newSellP = formatMultiplies(
        [newPriceForSquare, homeData?.square],
        2
      );
      setSellPrice(newSellP);
      setPriceForMonth(
        formatSingle((startPriceTopOrder ? fullPrice : newSellP) - startPrice) /
          month,
        2
      );
      setPriceForSquare(newPriceForSquare);
    }
  };

  useEffect(() => {
    if (!applyDiscount) {
      let sellP =
        homeData?.isrepaired == REPAIR_TYPE.REPAIRED.code
          ? homeData?.repaired && homeData?.square
            ? formatMultiplies([homeData.repaired, homeData.square], 2)
            : 0
          : homeData?.norepaired && homeData?.square
          ? formatMultiplies([homeData.norepaired, homeData.square], 2)
          : 0;
      if (homeData?.isvalute != currency) {
        if (currency == CURRENCY_TYPE.VALUTE.code) {
          sellP = sellP / currencyData.sum;
        } else {
          sellP = sellP * currencyData.sum;
        }
      }

      setFullPrice(sellP);
      setSellPrice(sellP);
      setMonth(homeData?.blocks?.month || month);
      // setIsrepaired(homeData?.isrepaired || month);
      setDiscount(0);
      setDiscountPercentage(0);
      setPriceForMonth(
        formatSingle(
          (sellP - formatSingle((sellP * +homeData?.start) / 100, 2)) /
            (homeData?.blocks?.month || month)
        )
      );
      setStartPrice(formatSingle((sellP * +homeData?.start) / 100, 2));
      setStartPricePercentage(homeData?.start);
      setPriceForSquare(
        homeData?.isrepaired == REPAIR_TYPE.REPAIRED.code
          ? homeData?.repaired
          : homeData?.norepaired
      );
    }
  }, [applyDiscount, homeData?.isrepaired, isrepaired]);

  useEffect(() => {
    setData({
      isvalute: currency,
      fullPrice,
      sellPrice,
      startPrice,
      startPricePercentage,
      month,
      isrepaired,
      priceForMonth,
      priceForSquare,
      discount,
      discountPercentage,
    });
  }, [
    order,
    currency,
    fullPrice,
    sellPrice,
    startPrice,
    startPricePercentage,
    month,
    priceForMonth,
    priceForSquare,
    discount,
    isrepaired,
    discountPercentage,
  ]);

  const handleChange = (e) => {
    setIsrepaired(e?.target?.value);
  };

  useEffect(() => {
    if (startPricePercentage == 100) {
      setMonth(0);
    } else if (!month) {
      setMonth(homeData?.blocks.month || month);
      setPriceForMonth(
        formatSingle(
          ((startPriceTopOrder ? fullPrice : sellPrice) - startPrice) /
            parseInt(homeData?.blocks.month || month)
        )
      );
    }
  }, [
    startPricePercentage,
    homeData?.blocks.month,
    sellPrice,
    startPrice,
    month,
    startPriceTopOrder,
    fullPrice,
  ]);

  useEffect(() => {
    if (!applyDiscount && formik.values.discountId) {
      setApplyDiscount(true);
    }
  }, [formik.values.discountId]);

  return (
    <div className='calculation-wrapper !border-none !p-0'>
      <div>
        <div className='home-details-header !items-center'>
          {/* Xonadon ma'lumotlari */}
          {t('shaxmatka.moreHomeDetails.selectedHomes')}
          <div className='currency-data'>
            {currency == CURRENCY_TYPE.VALUTE.code && (
              <div className='mr-2 whitespace-nowrap'>
                {t('common.fields.currency')}:{' '}
                <NumericFormat
                  value={currencyData?.sum}
                  allowNegative={false}
                  displayType={'text'}
                  thousandSeparator={' '}
                  decimalScale={1}
                  className='w-full text-right'
                  suffix=' UZS'
                />
              </div>
            )}
            <SimpleToggleButtonGroup
              delay={0}
              duration={0}
              value={currency?.toString()}
              changeFn={handleCurrencyTypeChange}
              options={currencyTypeSelectOptions}
              size='small'
              fullWidth={true}
              buttonClassName='whitespace-nowrap'
            />
          </div>
        </div>
        <div className='flex w-full overflow-x-auto my-scroll py-3 mt-2 gap-4'>
          {homesData?.map((home) => (
            <div
              key={home?.id}
              className='images-wrapper min-w-[332px] !p-4 !shadow-none bg-[#F8F8F8] !rounded-[15px]'
            >
              <div className='w-[300px] h-[250px]'>
                {home?.plan &&
                  (home?.plan?.link ||
                    home?.plan?.link2d ||
                    home?.plan?.location) && (
                    <ImagesSwiper
                      imageURLs={[
                        home?.plan?.link,
                        home?.plan?.link2d,
                        home?.plan?.location,
                      ]}
                    />
                  )}
              </div>
              <div className='home-rooms-and-discount py-3'>
                <span className='home-data-divider mt-3'></span>
              </div>
              <div className='text-sm mt-1 [&>*]:my-1.5 [&>*>*:nth-child(2)]:text-sm [&>*>*:nth-child(2)]:font-bold'>
                <div className='flex justify-between items-center'>
                  <span>{t('common.fields.simpleName')}:</span>
                  <span>{home?.object?.name}</span>
                </div>
                <div className='flex justify-between items-center'>
                  <span>{t('shaxmatka.homeDetail.houseNumber')}:</span>
                  <span>№{home?.number}</span>
                </div>
                <div className='flex justify-between items-center'>
                  <span>{t('common.fields.rooms')}:</span>
                  <span>{home?.rooms}&nbsp;X</span>
                </div>
                <div className='flex justify-between items-center'>
                  <span>{t('common.filter.areaAll')}:</span>
                  <span>
                    {home?.square}&nbsp;
                    <Trans i18nKey='common.global.meter'>
                      m<sup>2</sup>
                    </Trans>
                  </span>
                </div>
                <div className='flex justify-between items-center'>
                  <span>{t('shaxmatka.homeDetail.price')}:</span>
                  <span className='text-green-500'>
                    {homesData?.length === 1 ? (
                      <EditableNumberField
                        initialValue={sellPrice}
                        changeFn={handleChangeSellPrice}
                        decimalScale={1}
                      >
                        <NumericFormat
                          value={sellPrice}
                          allowNegative={false}
                          displayType={'text'}
                          thousandSeparator={' '}
                          decimalScale={1}
                          className='price-text !text-[#16BA5C]'
                          suffix={currency == '1' ? ' $' : ' UZS'}
                        />
                      </EditableNumberField>
                    ) : (
                      <NumericFormat
                        value={home?.square * priceForSquare}
                        allowNegative={false}
                        displayType={'text'}
                        thousandSeparator={' '}
                        decimalScale={1}
                        className='price-text !text-[#16BA5C]'
                        suffix={currency == '1' ? ' $' : ' UZS'}
                      />
                    )}
                  </span>
                </div>
                <div className='flex justify-between items-center'>
                  <span>
                    <Trans i18nKey='shaxmatka.homeDetail.priceForSquare'>
                      m<sup>2</sup> narxi
                    </Trans>
                    :
                  </span>
                  <span className='text-green-500'>
                    {homesData?.length === 1 ? (
                      <EditableNumberField
                        initialValue={priceForSquare}
                        changeFn={handleChangePriceSquare}
                        decimalScale={1}
                      >
                        <NumericFormat
                          value={priceForSquare}
                          allowNegative={false}
                          displayType={'text'}
                          thousandSeparator={' '}
                          decimalScale={1}
                          className='price-text !text-[#16BA5C]'
                          suffix={currency == '1' ? ' $' : ' UZS'}
                        />
                      </EditableNumberField>
                    ) : (
                      <NumericFormat
                        value={priceForSquare}
                        allowNegative={false}
                        displayType={'text'}
                        thousandSeparator={' '}
                        decimalScale={1}
                        className='price-text !text-[#16BA5C]'
                        suffix={currency == '1' ? ' $' : ' UZS'}
                      />
                    )}
                  </span>
                </div>
                <div className='flex justify-between items-center'>
                  <span>{t('shaxmatka.homeDetail.repairType')}:</span>
                  <div className='w-32'>
                    <Select
                      fullWidth
                      value={isrepaired}
                      onChange={handleChange}
                    >
                      <MenuItem value={0}>
                        {t('common.typeList.repairType.notRepaired')}
                      </MenuItem>
                      <MenuItem value={1}>
                        {t('common.typeList.repairType.repaired')}
                      </MenuItem>
                    </Select>
                  </div>
                </div>
              </div>

              {discount > 0 && (
                <Fragment>
                  <div className='w-full relative'>
                    <span className='home-data-divider'></span>
                  </div>
                  <div className='discount-details-wrapper'>
                    <div>
                      <i className='bi bi-tag discount-icon' />{' '}
                      {discountPercentage}
                      {t('shaxmatka.moreHomeDetails.appliedDiscount')}
                    </div>
                    <div>
                      <i className='bi bi-patch-check discount-icon' />{' '}
                      <NumericFormat
                        value={
                          (home?.square *
                            (homeData?.isrepaired == REPAIR_TYPE.REPAIRED.code
                              ? homeData?.repaired
                              : homeData?.norepaired) *
                            discountPercentage) /
                          100
                        }
                        allowNegative={false}
                        displayType={'text'}
                        thousandSeparator={' '}
                        decimalScale={1}
                        className='w-full text-right'
                        suffix={currency == '1' ? ' $' : ' UZS'}
                      />
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className='mt-2'>
        <div className='grid grid-cols-[332px,1fr] gap-4'>
          <div className='!base-border !bg-[#F9F9F9] p-3'>
            <div className='mb-5'>{t('order.view.optionalStartPayment')}</div>
            <FormControlLabel
              control={<IOSSwitch />}
              labelPlacement='start'
              label={t('settings.discounts.addDiscountModal.types.type1')}
              sx={{
                justifyContent: 'space-between',
                marginLeft: '0',
                width: '100%',
                marginBottom: '8px',
              }}
              value={startPriceTopOrder}
              onChange={({ target }) =>
                handleChangeStartPriceOrder(target?.checked)
              }
            />
            <SimpleNumberField
              delay={0}
              duration={0}
              value={startPricePercentage}
              setValue={handleChangeStartPricePercent}
              label={t('common.fields.startPricePercentage')}
              name='startPrice'
              className='!bg-white'
              size='small'
            />
            <div className='my-2.5' />
            <SimpleNumberField
              delay={0}
              duration={0}
              value={startPrice}
              setValue={handleChangeStartPrice}
              label={t('common.fields.startPrice')}
              name='startPrice'
              className='!bg-white'
              size='small'
            />
            <div className='my-2.5' />
            <div className='mb-5'>
              <SimpleNumberField
                delay={0}
                duration={0}
                value={month}
                setValue={handleChangeMonth}
                label={t('shaxmatka.moreHomeDetails.month')}
                className='!bg-white !mt-3.5'
                placeholder={t('shaxmatka.moreHomeDetails.type')}
                name='monthValue'
                size='small'
              />
            </div>
            <div>
              {homesData?.length === 1 && (
                <div className='flex items-center w-full'>
                  <DiscountPanel
                    setApplyDiscount={setApplyDiscount}
                    applyDiscount={applyDiscount}
                    home_id={homeData?.id}
                    formik={formik}
                  />
                </div>
              )}
            </div>
          </div>

          <div>
            <div className='payment-details-wrapper !mt-0 mb-4 !pt-0'>
              {/* <div className='mb-2'>{t('order.view.paymentContracts')}</div> */}
              <Grid
                container
                spacing={1}
                rowSpacing={1}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
              >
                <Grid item={true} md={3} sm={4} xs={12}>
                  <div className='payment-item-wrapper !shadow-none !base-border'>
                    <div className='w-[35px] h-[35px] flex items-center justify-center mb-2.5 rounded-lg bg-[#F0F0FA]'>
                      <i className='bi bi-currency-dollar payment-item-icon !text-[#7947F8]' />
                    </div>
                    <div>
                      <div className='payment-item-label'>
                        {t('payment.add.contract.totalSum')}
                      </div>
                      <NumericFormat
                        value={fullPrice}
                        allowNegative={false}
                        displayType={'text'}
                        thousandSeparator={' '}
                        decimalScale={1}
                        className='w-full text-right'
                        suffix={currency == '1' ? ' $' : ' UZS'}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item={true} md={3} sm={4} xs={12}>
                  <div className='payment-item-wrapper !shadow-none !base-border'>
                    <div className='w-[35px] h-[35px] flex items-center justify-center mb-2.5 rounded-lg bg-[#F0F0FA]'>
                      <i className='bi bi-percent payment-item-icon !text-[#7947F8]' />
                    </div>
                    <div>
                      <div className='payment-item-label'>
                        {t('shaxmatka.moreHomeDetails.discount')}
                      </div>
                      <EditableNumberField
                        initialValue={discount}
                        changeFn={handleChangeDiscount}
                        decimalScale={1}
                      >
                        <NumericFormat
                          value={discount || '0'}
                          allowNegative={false}
                          displayType={'text'}
                          thousandSeparator={' '}
                          decimalScale={1}
                          size={'small'}
                          className='w-full text-right'
                          suffix={currency == '1' ? ' $' : ' UZS'}
                        />
                      </EditableNumberField>
                      (
                      <EditableNumberField
                        initialValue={discountPercentage}
                        changeFn={handleChangeDiscountPercentage}
                        decimalScale={1}
                      >
                        <NumericFormat
                          value={discountPercentage || 0}
                          allowNegative={false}
                          displayType={'text'}
                          thousandSeparator={' '}
                          decimalScale={1}
                          size={'small'}
                          className='w-full text-right'
                          suffix={' %'}
                        />
                      </EditableNumberField>
                      )
                    </div>
                  </div>
                </Grid>
                <Grid item={true} md={3} sm={4} xs={12}>
                  <div className='payment-item-wrapper !shadow-none !base-border'>
                    <div className='w-[35px] h-[35px] flex items-center justify-center mb-2.5 rounded-lg bg-[#F0F0FA]'>
                      <i className='bi bi-wallet2 payment-item-icon !text-[#7947F8]' />
                    </div>
                    <div>
                      <div className='payment-item-label'>
                        {t('shaxmatka.moreHomeDetails.startPrice')}
                      </div>
                      <NumericFormat
                        value={startPrice}
                        allowNegative={false}
                        displayType={'text'}
                        thousandSeparator={' '}
                        decimalScale={1}
                        className='w-full text-right'
                        suffix={currency == '1' ? ' $' : ' UZS'}
                      />
                      ({startPricePercentage}%)
                    </div>
                  </div>
                </Grid>
                <Grid item={true} md={3} sm={4} xs={12}>
                  <div className='payment-item-wrapper !shadow-none !base-border'>
                    <div className='w-[35px] h-[35px] flex items-center justify-center mb-2.5 rounded-lg bg-[#F0F0FA]'>
                      <i className='bi bi-calendar payment-item-icon !text-[#7947F8]' />
                    </div>
                    <div>
                      <div className='payment-item-label'>
                        {t('shaxmatka.moreHomeDetails.monthlyPrice')}(
                        {t('shaxmatka.moreHomeDetails.monthValue', {
                          value: month,
                        })}
                        )
                      </div>
                      <NumericFormat
                        value={priceForMonth}
                        allowNegative={false}
                        displayType={'text'}
                        thousandSeparator={' '}
                        decimalScale={1}
                        className='w-full text-right'
                        suffix={currency == '1' ? ' $' : ' UZS'}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <DiscountTable
              applyDiscount={applyDiscount}
              startPrice={startPrice}
              setStartPrice={setStartPrice}
              currency={currency}
              setCurrency={setCurrency}
              startPricePercentage={startPricePercentage}
              setStartPricePercentage={setStartPricePercentage}
              discount={discount}
              setDiscount={setDiscount}
              discountPercentage={discountPercentage}
              setDiscountPercentage={setDiscountPercentage}
              sellPrice={sellPrice}
              setSellPrice={setSellPrice}
              priceForMonth={priceForMonth}
              setPriceForMonth={setPriceForMonth}
              month={month}
              setMonth={setMonth}
              discountId={formik.values?.discountId || null}
              fullPrice={fullPrice}
              setPriceForSquare={setPriceForSquare}
              square={homeData?.square}
              selectedDiscount={selectedDiscount}
              setSelectedDiscount={setSelectedDiscount}
              defaultStartPrice={homeData?.start}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(CalculationByHomeData);

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#65C466',
        opacity: 1,
        border: 0,
        ...theme.applyStyles('dark', {
          backgroundColor: '#2ECA45',
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[600],
      }),
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
      ...theme.applyStyles('dark', {
        opacity: 0.3,
      }),
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    ...theme.applyStyles('dark', {
      backgroundColor: '#39393D',
    }),
  },
}));
