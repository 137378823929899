import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormTreeSelectField from 'components/ui/form/FormTreeSelectField';
import FormTextField from 'components/ui/form/FormTextField';
import useFormSubmit from 'hooks/useFormSubmit';

const isValidUrl = (url) => {
  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
};

const validationSchema = yup.object({
  link: yup
    .string()
    .test('is-url-valid', 'settings.crm.validation.notUrl', (value) =>
      isValidUrl(value)
    )
    .required('settings.crm.validation.baseUrl'),
  api_key: yup.string().required('settings.crm.validation.token'),
  income_id: yup
    .string()
    .required('settings.crm.validation.incomeCrmBlockItemId'),
  missed_id: yup
    .string()
    .required('settings.crm.validation.messedCrmBlockItemId'),
});

const OnlinePbxSettings = (props) => {
  const { open, setOpen, refetch = () => {}, data } = props;
  const { t } = useTranslation();
  const { submit, isSubmitting } = useFormSubmit();

  const formik = useFormik({
    initialValues: {
      link: '',
      income_crm_block_id: '',
      income_id: '',
      missed_crm_block_id: '',
      missed_id: '',
      api_key: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      submit(
        { type: 'post', contentType: 'simple' },
        values,
        '/admin/crm-settings/setpbx',
        values.client_id,
        null,
        true,
        handleFinishRequest
      );
    },
  });

  useEffect(
    () => {
      if (data?.id) {
        formik.setValues({
          link: data?.link,
          income_crm_block_id: data?.pipe_income?.crm_block_id,
          income_id: data?.income_id,
          missed_crm_block_id: data?.pipe_missed?.crm_block_id,
          missed_id: data?.missed_id,
          api_key: data?.api_key,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, open]
  );

  const handleFinishRequest = () => {
    refetch();
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      PaperProps={{ className: '!base-border !shadow-none' }}
      disableEscapeKeyDown={true}
    >
      <DialogTitle id='alert-dialog-title'>
        {data?.id ? (
          <span>{t('settings.crm.modal.editOnlinePbx')}</span>
        ) : (
          <span>{t('settings.crm.modal.addOnlinePbx')}</span>
        )}
        <div className='close-btn-wrapper'>
          <IconButton variant='onlyIcon' color='primary' onClick={handleClose}>
            <i className='bi bi-x' />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent className='!max-w-[600px]'>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={{ xs: 2, sm: 3, lg: 3 }}
            rowSpacing={1}
            columns={{ xs: 12, sm: 12, lg: 12 }}
          >
            <Grid item={true} lg={12} sm={12} xs={12}>
              <FormTextField
                duration={0}
                label={t('common.fields.url')}
                fieldName='link'
                formik={formik}
              />
            </Grid>

            <Grid item={true} lg={12} sm={12} xs={12}>
              <FormTextField
                duration={0}
                label={t('common.fields.token')}
                fieldName='api_key'
                formik={formik}
                multiline
                rows={3}
              />
            </Grid>
            <Grid item={true} lg={12} sm={12} xs={12}>
              <span className='text-sm'>
                {t('common.fields.incomeCallsPipe')}
              </span>
              <div className='border border-gray-400 mt-2 p-[5px] rounded-lg [&>*]:!m-0'>
                <FormTreeSelectField
                  parentFieldName='income_crm_block_id'
                  childFieldName='income_id'
                  label=''
                  childOptionField={'pipes'}
                  formik={formik}
                  parentLabel={'name'}
                  parentValue={'id'}
                  childLabel={'name'}
                  childValue={'id'}
                  variant='standard'
                  path='/crm/block'
                  size='small'
                  onChange={() => {}}
                  progress={false}
                />
              </div>
            </Grid>
            <Grid item={true} lg={12} sm={12} xs={12}>
              <span className='text-sm'>
                {t('common.fields.missedCallsPipe')}
              </span>
              <div className='border border-gray-400 mt-2 p-[5px] rounded-lg [&>*]:!m-0'>
                <FormTreeSelectField
                  parentFieldName='missed_crm_block_id'
                  childFieldName='missed_id'
                  label=''
                  childOptionField={'pipes'}
                  formik={formik}
                  parentLabel={'name'}
                  parentValue={'id'}
                  childLabel={'name'}
                  childValue={'id'}
                  variant='standard'
                  path='/crm/block'
                  size='small'
                  onChange={() => {}}
                  progress={false}
                />
              </div>
            </Grid>

            <Grid item={true} sm={12} xs={12}>
              <FormActionButtons
                duration={0}
                isSubmitting={isSubmitting}
                formType='dialog'
                setOpen={setOpen}
                reset={formik.resetForm}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default memo(OnlinePbxSettings);
