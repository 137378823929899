import React, { memo, useEffect } from 'react';
import image404 from 'assets/images/errors/404.svg';
import usePrevNext from 'hooks/usePrevNext';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useTopPanel from 'hooks/useTopPanel';
import { useNavigate } from 'react-router-dom';
import useMenu from 'hooks/useMenu';

const NotFoundPage = () => {
  const { prev } = usePrevNext();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { setComponent } = useTopPanel();
  const { hasMenu } = useMenu();

  useEffect(() => {
    setComponent(
      <div className='component-title'>
        {t('common.errorPage.notFound.title')}
      </div>
    );
  }, [i18n.language]);

  const goToMain = () => {
    if (hasMenu('MAINPAGE')) navigate('/admin/dashboard');
    else prev();
  };

  return (
    <div className='not-found-page flex items-center justify-center flex-col h-screen'>
      <img
        src={image404}
        alt='Page Not Found'
        className='sm:w-[600px] w-[400px] sm:px-10 px-5'
      />
      <Button
        color='primary'
        variant='contained'
        className='!-mt-4 lg:!text-xl font-medium px-2 py-1 animate-[bounce_2s_infinite]'
        onClick={() => goToMain()}
      >
        {t('common.errorPage.notFound.goMain')}
      </Button>
    </div>
  );
};

export default memo(NotFoundPage);
