import {
  CircularProgress,
  IconButton,
  InputAdornment,
  MenuItem,
  Popper,
  TextField,
} from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useDebounce from 'hooks/useDebounce';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'shared/colors';

const BankCustomerSearch = ({ formik }) => {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [isMouseOverList, setIsMouseOverList] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNoDataFound, setIsNoDataFound] = useState(false);
  const [searchTxt, setSearchTxt] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  let anchorEl = document.getElementById('crm-search-field');

  const searchTxtDebounced = useDebounce(searchTxt, 300);

  const handleKeyDown = async (event) => {
    if (event.keyCode == 13) {
      await handleSearch();
    }
  };

  const handleSearch = async () => {
    if (searchTxtDebounced && searchTxtDebounced.length >= 3) {
      setIsLoading(true);
      setIsNoDataFound(false);
      try {
        const response = await axiosPrivate.get(
          `/admin/bank/search?search=${searchTxtDebounced}`,
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );
        if (
          response &&
          response.data &&
          response.data.status &&
          response.data.data
        ) {
          setSearchResults(response.data.data);
          setIsNoDataFound(response.data.data.length == 0 || false);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const onClick = (item) => {
    setSearchTxt(item?.fullname);
    setOpen(false);
    formik.setFieldValue('contract_id', item?.id);
  };

  useEffect(() => {
    if (searchTxtDebounced) {
      handleSearch();
    } else {
    }
  }, [searchTxtDebounced]);

  return (
    <div className='crm-lead-search-wrapper w-[full]'>
      <TextField
        color='secondary'
        variant='outlined'
        fullWidth
        id='crm-search-field'
        name='crm-search-field'
        label={t('common.global.search')}
        value={searchTxt}
        onChange={(event) => setSearchTxt(event.target.value)}
        onKeyDown={handleKeyDown}
        autoComplete='off'
        onFocus={() => setOpen(true)}
        onBlur={() => {
          if (!isMouseOverList) {
            setOpen(false);
          }
        }}
        className='h-[40px]'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end' className='custom-endAdornment'>
              {searchTxt ? (
                <IconButton
                  onClick={() => {
                    setSearchTxt('');
                    setSearchResults([]);
                  }}
                  variant='onlyIcon'
                >
                  <i className='bi bi-x end-adornment-close-button' />
                </IconButton>
              ) : (
                <IconButton variant='onlyIcon' disabled disableRipple>
                  <i className='bi bi-search text-base leading-4' />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        sx={{
          '& .MuiOutlinedInput-root.Mui-focused i': {
            color: colors.baseColorLight,
          },
          '& .MuiOutlinedInput-root i': {
            color: colors.grayColor,
          },
        }}
      />

      <Popper
        zIndex={10000}
        sx={{ zIndex: 10000, width: '452px' }}
        open={open}
        anchorEl={anchorEl}
        className='lead-search-popper-wrapper'
        onMouseOver={() => setIsMouseOverList(true)}
        onMouseOut={() => setIsMouseOverList(false)}
      >
        {isLoading ? (
          <div className='flex items-center justify-center my-[14px]'>
            <CircularProgress size={20} color='inherit' />
          </div>
        ) : searchResults && searchResults.length > 0 ? (
          searchResults.map((item, index) => (
            <MenuItem key={index} onClick={() => onClick(item)}>
              <div className='text-[12px] w-full'>
                <div className='flex justify-between items-center'>
                  <span>{t('common.table.custom')}:</span>
                  <span>{item?.fullname?.replaceAll('-', ' ')}</span>
                </div>
                <div className='flex justify-between items-center'>
                  <span>{t('common.fields.phone')}:</span>
                  <span>{item?.phone}</span>
                </div>
                <div className='flex justify-between items-center'>
                  <span>{t('common.table.paymentContract')}:</span>
                  <span>{item?.name}</span>
                </div>
              </div>
            </MenuItem>
          ))
        ) : (
          isNoDataFound && (
            <div>
              <span className='no-data-found-wrapper select-box my-2'>
                <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
                {t('common.global.noDataFound')}
              </span>
            </div>
          )
        )}
      </Popper>
    </div>
  );
};
export default memo(BankCustomerSearch);
