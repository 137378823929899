import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { useFormik } from 'formik';
import { memo, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';

import FormCurrencyField from 'components/ui/form/FormCurrencyField';
import { currencyTypeSelectOptions } from 'shared/selectOptionsList';
import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormSimpleSelectField from 'components/ui/form/FormSimpleSelectField';
import useCurrency from 'hooks/useCurrency';
import useFormSubmit from 'hooks/useFormSubmit';
import getShaxmatkaFieldDataByBlocks from 'utils/getShaxmatkaFieldDataByBlocks';
import CurrencySubContent from 'components/ui/text-formats/CurrencySubContent';

const priceEditValidationSchema = yup.object({
  isvalute: yup.string().optional(),
  repaired: yup.number().required('shaxmatka.validation.repaired'),
  norepaired: yup.number().required('shaxmatka.validation.norepaired'),
  deal_price: yup.number().required('shaxmatka.validation.dealPrice'),
});

export default memo(function ShaxmatkaPriceEditForm({
  blocks = [],
  objectId = '',
  setHomeExpanded = () => {},
  handleCloseHomeDetail = () => {},
  refetch = () => {},
}) {
  const { submit, isSubmitting } = useFormSubmit();
  const { t } = useTranslation();
  const { currencyData } = useCurrency();
  const [selectedBlocks, setSelectedBlocks] = useState(
    blocks.map((item) => item?.id)
  );
  const [selectedStages, setSelectedStages] = useState([]);
  const [selectedSquares, setSelectedSquares] = useState([]);

  const stages = useMemo(() => {
    let result = getShaxmatkaFieldDataByBlocks(blocks, 'stage');
    setSelectedStages([...result]);
    return result;
  }, [blocks]);

  const squares = useMemo(() => {
    let result = getShaxmatkaFieldDataByBlocks(blocks, 'square');
    setSelectedSquares([...result]);
    return result;
  }, [blocks]);

  const formik = useFormik({
    initialValues: {
      isvalute: '0',
      repaired: '',
      norepaired: '',
      deal_price: null,
    },
    validationSchema: priceEditValidationSchema,
    onSubmit: async (values) => {
      let newValues = {
        repaired: values.repaired,
        norepaired: values.norepaired,
        deal_price: values.deal_price,
        isvalute: values.isvalute,
        squares: selectedSquares,
        stages: selectedStages,
        blocks: selectedBlocks,
      };
      submit(
        { type: 'post', contentType: 'simple' },
        newValues,
        `/admin/home/updateprice/${objectId}`,
        'Belgilangan parametrli xonadonlar',
        null,
        true,
        handleResponse
      );
    },
  });

  const handleResponse = () => {
    setHomeExpanded(false);
    refetch?.();
  };

  const handleCurrencyChange = (value) => {
    formik.setFieldValue('isvalute', value, true);
    if (currencyData && currencyData.sum) {
      if (value == '1') {
        formik.setFieldValue(
          'repaired',
          parseFloat((formik.values.repaired || '0') / currencyData.sum),
          true
        );
        formik.setFieldValue(
          'norepaired',
          parseFloat((formik.values.norepaired || '0') / currencyData.sum),
          true
        );
        formik.setFieldValue(
          'deal_price',
          parseFloat((formik.values.deal_price || '0') / currencyData.sum),
          true
        );
      } else if (value == '0') {
        formik.setFieldValue(
          'repaired',
          parseFloat((formik.values.repaired || '0') * currencyData.sum),
          true
        );
        formik.setFieldValue(
          'norepaired',
          parseFloat((formik.values.norepaired || '0') * currencyData.sum),
          true
        );
        formik.setFieldValue(
          'deal_price',
          parseFloat((formik.values.deal_price || '0') * currencyData.sum),
          true
        );
      }
    }
  };

  const isBlockSelected = (blockId) => selectedBlocks.includes(blockId);

  const handleBlockChange = (blockId) => {
    if (isBlockSelected(blockId)) {
      selectedBlocks.splice(selectedBlocks.indexOf(blockId), 1);
    } else {
      selectedBlocks.push(blockId);
    }
    setSelectedBlocks([...selectedBlocks]);
  };

  const isStageSelected = (blockId) => selectedStages.includes(blockId);

  const handleStageChange = (stage) => {
    if (isStageSelected(stage)) {
      selectedStages.splice(selectedStages.indexOf(stage), 1);
    } else {
      selectedStages.push(stage);
    }
    setSelectedStages([...selectedStages]);
  };

  const handleSquaresChange = (event) => {
    setSelectedSquares(event?.target?.value || []);
  };

  return (
    <div className='sheet-actions-body'>
      <div className='absolute top-0 right-0'>
        <IconButton
          variant='onlyIcon'
          color='primary'
          onClick={() => handleCloseHomeDetail()}
        >
          <i className='bi bi-x' />
        </IconButton>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} rowSpacing={1} columns={{ xs: 12, sm: 12 }}>
          <Grid item={true} sm={12}>
            {t('common.fields.blocks')}
            {blocks && blocks.length > 0 && (
              <div className='blocks-name-wrapper price-form-item-wrapper'>
                {blocks.map((item) => (
                  <Button
                    className={`block-item field-item${
                      isBlockSelected(item.id) ? ' item-selected' : ''
                    }`}
                    color='primary'
                    variant={
                      isBlockSelected(item.id) ? 'contained' : 'outlined'
                    }
                    key={`price-edit-form-block-${item.id}`}
                    onClick={() => handleBlockChange(item.id)}
                  >
                    {item?.name}
                  </Button>
                ))}
              </div>
            )}
          </Grid>
          <Grid item={true} sm={12}>
            <div className='price-form-item-divider'></div>
          </Grid>
          <Grid item={true} sm={12}>
            {t('common.fields.stages')}
            {stages && stages.length > 0 && (
              <div className='stages-name-wrapper price-form-item-wrapper'>
                {stages.map((item) => (
                  <Button
                    className={`stage-item field-item${
                      isStageSelected(item) ? ' item-selected' : ''
                    }`}
                    color='primary'
                    variant={isStageSelected(item) ? 'contained' : 'outlined'}
                    key={`price-edit-form-stage-${item}`}
                    onClick={() => handleStageChange(item)}
                  >
                    {item}
                  </Button>
                ))}
              </div>
            )}
          </Grid>
          <Grid item={true} sm={12}>
            <div className='price-form-item-divider'></div>
          </Grid>
          <Grid item={true} sm={12} sx={{ marginBottom: '-0.5rem' }}>
            <FormControl fullWidth color='formColor'>
              <InputLabel id='squares-select-label'>
                {t('common.fields.squares')}
              </InputLabel>
              <Select
                labelId='squares-select-label'
                id='squares-select-multiple-chip'
                multiple
                value={selectedSquares}
                onChange={handleSquaresChange}
                input={
                  <OutlinedInput
                    id='squares-select-multiple-chip'
                    variant='filterField'
                    label={t('common.fields.squares')}
                  />
                }
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 0.5,
                    }}
                  >
                    {selected.map((value) => (
                      <Chip
                        key={`square-${value}`}
                        label={value}
                        variant='tableBadge'
                        color='primary'
                        onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                        size='small'
                      />
                    ))}
                  </Box>
                )}
                color='formColor'
                variant='outlined'
                MenuProps={{
                  id: 'squares-select-menu',
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                  disableScrollLock: true,
                }}
                endAdornment={
                  <InputAdornment
                    position='end'
                    className='custom-endAdornment'
                  >
                    <IconButton
                      onClick={() => setSelectedSquares([])}
                      size='small'
                      variant='onlyIcon'
                      sx={{
                        display: selectedSquares.length > 0 ? '' : 'none',
                      }}
                      className='select-box-end-adornment-button'
                    >
                      <i className='bi bi-x end-adornment-close-button' />
                    </IconButton>
                  </InputAdornment>
                }
              >
                {squares && squares.length > 0 ? (
                  squares.map((item, index) => (
                    <MenuItem
                      value={item}
                      key={`square-${index + 1}`}
                      className='small-size'
                    >
                      {item}
                    </MenuItem>
                  ))
                ) : (
                  <div>
                    <span className='no-data-found-wrapper select-box'>
                      <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
                      {t('common.global.noDataFound')}
                    </span>
                  </div>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item={true} sm={12} sx={{ marginBottom: '-0.5rem' }}>
            <FormSimpleSelectField
              delay={0}
              fieldName='isvalute'
              formik={formik}
              label={t('common.fields.currency')}
              options={currencyTypeSelectOptions}
              itemValue='code'
              itemLabel='label'
              changeFn={handleCurrencyChange}
            />
          </Grid>

          <Grid item={true} sm={12} sx={{ marginBottom: '-0.5rem' }}>
            <FormCurrencyField
              delay={0}
              fieldName='repaired'
              formik={formik}
              label={
                <span>
                  <Trans i18nKey='common.fields.repairedPrice'>
                    Ta'mirlangan narxi(1 m<sup>2</sup>)
                  </Trans>
                </span>
              }
              decimalScale={1}
            />
            {formik.values.isvalute == '1' &&
              !isNaN(formik.values.repaired) && (
                <CurrencySubContent value={formik.values.repaired || '0'} />
              )}
          </Grid>

          <Grid item={true} sm={12} sx={{ marginBottom: '-0.5rem' }}>
            <FormCurrencyField
              delay={0}
              fieldName='norepaired'
              formik={formik}
              label={
                <span>
                  <Trans i18nKey='common.fields.noRepairedPrice'>
                    Ta'mirlanmagan narxi(1 m<sup>2</sup>)
                  </Trans>
                </span>
              }
              decimalScale={1}
            />
            {formik.values.isvalute == '1' &&
              !isNaN(formik.values.norepaired) && (
                <CurrencySubContent value={formik.values.norepaired || '0'} />
              )}
          </Grid>

          <Grid item={true} sm={12} sx={{ marginBottom: '-0.5rem' }}>
            <FormCurrencyField
              delay={0}
              fieldName='deal_price'
              formik={formik}
              label={
                <span>
                  <Trans i18nKey='common.fields.dealPrice'>
                    Kelishilgan holda Ta'mirlangan narxi (1 m<sup>2</sup>)
                  </Trans>
                </span>
              }
              decimalScale={1}
            />
            {formik.values.isvalute == '1' &&
              !isNaN(formik.values.deal_price) && (
                <CurrencySubContent value={formik.values.deal_price || '0'} />
              )}
          </Grid>

          <Grid item={true} sm={12} xs={12}>
            <FormActionButtons
              delay={0}
              isSubmitting={isSubmitting}
              onlySave
              className='mt-0'
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
});
