import React, { useEffect, memo, useState, useMemo } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { useQueries } from 'react-query';

import FormActionButtons from 'components/ui/form/FormActionButtons';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import FormTextField2 from 'components/ui/form/FormTextField2';
import FormDateField2 from 'components/ui/form/FormDateField2';
import useFormSubmit from 'hooks/useFormSubmit';
import FormSimpleSelectField from 'components/ui/form/FormSimpleSelectField';
import FormCurrencyField2 from 'components/ui/form/FormCurrencyField2';

const validationSchema = yup.object({
  // name: yup
  //   .string()
  //   .min(3, { label: 'object.validation.nameMin', value: 3 })
  //   .required('object.validation.name'),
  // region_id: yup.string().required('object.validation.regionId'),
  // companies_id: yup.string().required('object.validation.companiesId'),
  // city: yup.string().required('object.validation.city'),
  // address: yup.string().required('object.validation.address'),
  // start: yup
  //   .date()
  //   .nullable()
  //   .typeError('object.validation.startValid')
  //   .required('object.validation.start'),
  // end: yup
  //   .date()
  //   .nullable()
  //   .min(yup.ref('start'), 'object.validation.endMin')
  //   .typeError('object.validation.endValid')
  //   .required('object.validation.end'),
  // stage: yup.number().required('object.validation.stage'),
  // padez: yup.number().required('object.validation.padez'),
});

const ObjectAddVariableModal = ({ open, setOpen, selected, setSelected }) => {
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const { submit, isSubmitting } = useFormSubmit();
  const { id: object_id } = selected;
  const formik = useFormik({
    initialValues: {
      object_id: '',
      page: 'OBJECT',
      variables: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      submit(
        { type: 'post', contentType: 'simple' },
        values,
        'admin/variables/updatevariables',
        values.name,
        null,
        true,
        () => {
          handleClose();
        }
      );
    },
  });
  const [vars, values] = useQueries([
    {
      queryKey: ['/admin/variables/pagevariables?page=OBJECT'],
      queryFn: async function () {
        const response = await axiosPrivate.get(
          '/admin/variables/pagevariables?page=OBJECT'
        );
        return response.data.data;
      },
      enabled: !hasError,
      onError: () => {
        setHasError(true);
      },
      retry: false,
    },
    {
      queryKey: [
        '/admin/variables/objectvariables?page=OBJECT&object_id={object_id}',
        object_id,
      ],
      queryFn: async function () {
        const response = await axiosPrivate.get(
          `/admin/variables/objectvariables?page=OBJECT&object_id=${object_id}`
        );
        return response.data.data;
      },
      enabled: !hasError && !!object_id,
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
  ]);

  const fields = useMemo(() => {
    return vars?.data?.map((variable) => {
      return {
        name: variable?.name,
        value: values?.data?.find((v) => v?.variable_id == variable?.id)?.value,
        type: variable?.type,
        required: variable?.required,
        options: variable?.options?.map((o) => o?.value),
        variable_id: variable?.id,
      };
    });
  }, [vars.data, values.data]);

  useEffect(() => {
    if (selected?.id) {
      formik.setFieldValue('object_id', selected?.id);
    }
    if (fields?.length) {
      const variables = fields?.map((d) => ({
        variable_id: d?.variable_id,
        value: d?.value,
      }));
      formik.setFieldValue('variables', variables);
    }
  }, [fields, selected]);

  const handleClose = () => {
    setOpen(false);
    setSelected({});
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
      className='!base-border !shadow-none'
      PaperProps={{
        className: '!shadow-none !base-border',
      }}
    >
      <DialogTitle
        id='alert-dialog-title'
        className='!bg-[#CDCDCDC7] !text-center !mb-2 !border-b !border-b-[#E6E6E6]'
      >
        <span className='pr-5 font-semibold text-[21px] text-[#374957]'>
          {t('settings.variables.title')}
        </span>
      </DialogTitle>

      <DialogContent>
        {values?.isLoading || vars?.isLoading ? (
          <div className='circular-progress-box py-5'>
            <CircularProgress size={35} />
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              spacing={{ xs: 2, sm: 3, lg: 3 }}
              rowSpacing={1}
              columns={{ xs: 12, sm: 12, lg: 12 }}
            >
              {fields?.map((field, i) => (
                <Grid key={i} item={true} lg={12} sm={12} xs={12}>
                  {field?.type === 'select' && (
                    <FormSimpleSelectField
                      label={field?.name}
                      delay={0}
                      duration={0}
                      withNestedField
                      fieldName={`variables.${i}.value`}
                      formik={formik}
                      options={field?.options}
                      itemValue='name'
                    />
                  )}
                  {field?.type === 'text' && (
                    <FormTextField2
                      label={field?.name}
                      delay={0}
                      duration={0}
                      fieldName={`variables.${i}.value`}
                      formik={formik}
                      multiline
                      rows={3}
                    />
                  )}
                  {field?.type === 'string' && (
                    <FormTextField2
                      label={field?.name}
                      delay={0}
                      duration={0}
                      fieldName={`variables.${i}.value`}
                      formik={formik}
                    />
                  )}
                  {field?.type === 'date' && (
                    <FormDateField2
                      label={field?.name}
                      delay={0}
                      duration={0}
                      fieldName={`variables.${i}.value`}
                      formik={formik}
                      type='number'
                    />
                  )}
                  {field?.type === 'number' && (
                    <FormCurrencyField2
                      label={field?.name}
                      delay={0}
                      duration={0}
                      fieldName={`variables.${i}.value`}
                      formik={formik}
                      type='number'
                    />
                  )}
                </Grid>
              ))}

              <Grid item={true} sm={12} xs={12}>
                <div className='flex justify-end'>
                  <FormActionButtons
                    delay={1.1}
                    isSubmitting={isSubmitting}
                    formType='dialog'
                    setOpen={setOpen}
                    reset={formik.resetForm}
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default memo(ObjectAddVariableModal);
