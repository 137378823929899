import { Button } from '@mui/material';
import useTopPanel from 'hooks/useTopPanel';
import React, { useEffect, useState } from 'react';
import BlockAddModal from './BlockAddModal';
import BaseTable from 'components/ui/tables/BaseTable';
import { useTranslation } from 'react-i18next';
import BlockImageModal from './BlockImageModal';
import EntranceAddModal from './EntranceAddModal';
import SearchInputNavbar from 'components/SearchInputNavbar';

const Block = () => {
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setComponent(<div className='component-title'>{t('block.title')}</div>);
  }, [i18n.language]);

  const [refetch, setRefetch] = useState(false);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const [openEntranceModal, setOpenEntranceModal] = useState(false);
  const [openBlockImageModal, setOpenBlockImageModal] = useState(false);
  const [blockData, setBlockData] = useState({});
  const [open, setOpen] = useState(false);

  return (
    <div className='component-list-wrapper'>
      <div className='component-list-header mb-2'>
        <div className='header-actions-container pt-2 pb-2 flex flex-row items-center'>
          {/* <div className='header-actions filter-box flex items-center'>
                    <Button
                      variant='base'
                      startIcon={<i className='bi bi-filter' />}
                      className='!mr-2'
                      onClick={() =>
                        setExpanded((prev) => !prev)
                      }
                    >
                      {t('common.button.filter')}
                    </Button>
                  </div> */}
          <div className='grow'>
            <SearchInputNavbar inputKey='name' fullWidth />
          </div>
          <div className='header-actions action-buttons-box flex items-center justify-center ml-4'>
            <Button
              variant='base'
              onClick={() => {
                setRefetch(true);
              }}
              disable={`${refetch}`}
            >
              <i
                className={`bi bi-arrow-repeat${
                  refetch ? ' animate-spin' : ''
                }`}
              />
            </Button>
            <Button
              variant='base'
              color='success'
              className='!ml-2'
              onClick={() => setOpenBlockModal(true)}
            >
              <i className='bi bi-plus-circle' />
            </Button>
            <Button
              variant='base'
              className='!ml-2'
              onClick={() => setOpen(true)}
            >
              <i className='bi bi-gear' />
            </Button>
          </div>
        </div>
      </div>

      <div className='component-table-wrapper h-[calc(100vh-160px)]'>
        <BaseTable
          emitRefetch={{ refetch, setRefetch }}
          dataPath='dictionary/blocks'
          emitTableColumns={{ open, setOpen }}
          tableName='block'
          headCells={[
            { code: 'name', label: t('common.table.name') },
            { code: 'room_number', label: t('common.table.homesOnFloor') },
            { code: 'rooms', label: t('common.table.homesAll') },
            { code: 'objects', label: t('common.table.objectName') },
          ]}
          columns={[
            { code: 'name' },
            { code: 'room_number' },
            {
              code: 'rooms',
              type: 'multiply',
              childStrings: ['stage', ''],
              fields: ['objects', 'room_number'],
            },
            { code: 'objects', type: 'nested', childStr: 'name' },
          ]}
          actionsList={[
            {
              icon: 'bi bi-building-add',
              btnColor: 'info',
              setOpen: setOpenEntranceModal,
              setData: setBlockData,
            },
            {
              icon: 'bi bi-images',
              btnColor: 'info',
              setOpen: setOpenBlockImageModal,
              setData: setBlockData,
            },
          ]}
        />
      </div>

      {openBlockImageModal && (
        <BlockImageModal
          open={openBlockImageModal}
          setOpen={setOpenBlockImageModal}
          blockData={blockData}
        />
      )}

      {openEntranceModal && (
        <EntranceAddModal
          open={openEntranceModal}
          setOpen={setOpenEntranceModal}
          data={blockData}
          setRefetch={setRefetch}
        />
      )}
      {openBlockModal && (
        <BlockAddModal
          open={openBlockModal}
          setOpen={setOpenBlockModal}
          setRefetch={setRefetch}
        />
      )}
    </div>
  );
};

export default Block;
