import React, { memo } from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import { t } from 'i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import useFormSubmit from 'hooks/useFormSubmit';
import { useAppInfo } from 'context';
import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormSelectField from 'components/ui/form/FormSelectField';

const validationSchema = yup.object({
  staff_id: yup.number().required('change.tab.contract.validation.staffId'),
});

export default memo(function StaffChangeModal({ open, setOpen, leads }) {
  const [{ refetchTaskCount }] = useAppInfo();
  const { submit, isSubmitting } = useFormSubmit();

  const formik = useFormik({
    initialValues: {
      staff_id: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (leads?.length) {
        const body = { ...values, leads };
        submit(
          { type: 'post', contentType: 'simple' },
          body,
          '/crm/crm-lead/changestaff',
          "Mas'ul xodim o'zgartirildi",
          null,
          true,
          () => {
            refetchTaskCount?.();
            setOpen(null);
            formik.resetForm();
          }
        );
      }
    },
  });

  const onClose = () => {
    setOpen(null);
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='lg'
      PaperProps={{ className: '!base-border !shadow-none !overflow-hidden' }}
    >
      <DialogTitle
        id='alert-dialog-title'
        className='!bg-[#CDCDCDC7] !text-center !border-b !border-b-[#E6E6E6]'
      >
        <span className='pr-5 font-semibold text-[21px] text-[#374957]'>
          {t('tasks.staff.title')}
        </span>
      </DialogTitle>
      <div className='py-4 px-8 min-w-[600px]'>
        <form onSubmit={formik.handleSubmit}>
          <div className='flex gap-2 items-center'>
            <div className='rounded-full w-12 h-12 bg-blue-50 flex  items-center justify-center'>
              <div className='rounded-full w-8 h-8 bg-blue-100 flex  items-center justify-center'>
                <i className='bi bi-exclamation-circle text-lg text-blue-600'></i>
              </div>
            </div>

            <span>{t('tasks.staff.description')}</span>
          </div>
          <div className='flex justify-center flex-col gap-4'>
            <FormSelectField
              delay={0}
              duration={0}
              label={t('common.fields.staff')}
              fieldName={'staff_id'}
              formik={formik}
              path={'/dictionary/staffes'}
            />
            <div className='flex justify-between items-center'>
              <div>
                {t('sidebar.admin.lead')}: {leads?.length}
              </div>
              <FormActionButtons
                delay={0}
                isSubmitting={isSubmitting}
                formType='dialog'
                setOpen={setOpen}
                reset={formik.resetForm}
              />
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  );
});
