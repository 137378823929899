import { Button, Drawer } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { memo, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import DateRangeFilter from '../ui/filters/items/DateRangeFilter';
import GenericSelectFilter from '../ui/filters/items/GenericSelectFilter';
import useAxiosPrivate from 'hooks/useAxiosPrivate';

const SentMessageFilterDrawer = ({ open, setOpen, isLoading = false }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const handleResetFiler = () => {
    navigate(location.pathname, { replace: true });
  };

  // const { data: typeData } = useQuery({
  //   queryKey: 'smsList',
  //   queryFn: async function () {
  //     const response = await axiosPrivate.get('/admin/sms/list');
  //     return response?.data?.data;
  //   },
  //   enabled: !hasError,
  //   onError: () => {
  //     setHasError(true);
  //   },
  //   retry: false,
  // });

  const options = useMemo(() => {
    return [
      {
        id: 'STORED',
        name: t('common.typeList.sentMessages.STORED'),
      },
      {
        id: 'DELIVERED',
        name: t('common.typeList.sentMessages.DELIVERED'),
      },
      {
        id: 'REJECTED',
        name: t('common.typeList.sentMessages.REJECTED'),
      },
    ];
  }, []);

  return (
    <Drawer
      anchor='left'
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: { padding: '18px', background: 'transparent', boxShadow: 'none' },
      }}
    >
      <div className='dashboard-filter-drawer-wrapper !pt-2 first-letter bg-white h-full rounded-lg border border-[#E5E9EB]'>
        <div className='mb-3 text-end flex justify-between items-center border-b'>
          <div className='text-[16px] leading-[16px] font-semibold'>
            {t('common.button.filter')}
          </div>
          <Button
            color='error'
            // variant='outlined'
            className='!-mr-[5px]'
            size='small'
            disabled={isLoading}
            onClick={() => handleResetFiler()}
          >
            {t('common.button.clear')}
          </Button>
        </div>
        <DateRangeFilter
          size='small'
          disabled={isLoading}
          labels={{
            start: t('common.filter.dateFrom'),
            end: t('common.table.till'),
          }}
        />
        <GenericSelectFilter
          options={options}
          query={'type'}
          label={t('common.fields.type')}
          size='small'
        />
      </div>
    </Drawer>
  );
};
export default memo(SentMessageFilterDrawer);
