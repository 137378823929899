/* eslint-disable eqeqeq */
import moment from 'moment';
import MessageActionsMenu from '../actions-menu/MessageActionsMenu';
import { memo } from 'react';
import CRMTaskForm from './CRMTaskForm';
import { useTranslation } from 'react-i18next';

const CRMTaskMessage = ({
  view = () => {},
  data = {},
  refetchFn = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={'task-wrapper flex items-center relative'}
      id={`task-${data?.id}`}
    >
      <div
        className={`task-icon text-base leading-4 border-2 ${
          data?.status == 1 ? 'border-red-600' : 'border-gray-400'
        } text-gray-400 rounded-full w-[42px] h-[42px] min-w-[42px] min-h-[42px] flex items-center justify-center mr-3`}
      >
        <i
          className={`bi ${
            data?.status == 1 ? 'bi-clock text-red-600' : 'bi-journal-bookmark'
          } text-lg leading-none`}
        />
      </div>
      <div className='task-details w-full'>
        <div className='text-[12px] text-gray-500 flex flex-col'>
          <div>
            {moment(data?.created_at).format('DD.MM.YYYY HH:mm')},{' '}
            {t('crm.leadHistory.staff')}:{' '}
            <span className='font-semibold text-gray-700'>{data?.name}</span>
            ,&nbsp;{t('crm.leadHistory.date')}:{' '}
            <span className='font-semibold text-gray-700'>
              {moment(data?.date).format('DD.MM.YYYY HH:mm')}
            </span>
          </div>
        </div>
        <div
          className={`my-1  ${
            data?.status == 2 ? 'line-through text-gray-400' : ''
          }`}
        >
          {data?.subt?.name || ''}
          {data?.subt?.name && data?.title ? ' - ' : ''}
          {data?.title}
        </div>
        {data?.status == 1 && <CRMTaskForm refetch={refetchFn} data={data} />}
      </div>
      {view('CUSTOMS_DELETE_TASK') && (
        <MessageActionsMenu itemId={data?.id} refetchFn={refetchFn} />
      )}
    </div>
  );
};
export default memo(CRMTaskMessage);
