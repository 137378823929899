import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import React from 'react';
import { useTranslation } from 'react-i18next';

import BaseTable from 'components/ui/tables/BaseTable';

const SentMessages = (props) => {
  const { open, setOpen, data } = props;
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
      PaperProps={{
        className: '!base-border !shadow-none min-w-[75vw]',
      }}
    >
      <DialogTitle
        id='alert-dialog-title'
        className='!text-center !mb-5 !bg-[#CDCDCDC7]'
      >
        <span className='mr-4'>{t('settings.sentMessages.title')}</span>
      </DialogTitle>

      <DialogContent>
        <div className='component-table-wrapper h-[calc(100vh-180px)]'>
          <BaseTable
            emitRefetch={{ refetch: false, setRefetch: () => {} }}
            dataPath={`admin/contract/sms/${data?.id}`}
            tableName='sent-messages-on-modal'
            headCells={[
              { code: 'phone', label: t('common.table.receiver') },
              { code: 'staff', label: t('common.table.sender') },
              { code: 'status', label: t('common.table.status') },
              { code: 'text', label: t('common.table.sms') },
            ]}
            columns={[
              { code: 'phone' },
              { code: 'staff', type: 'nested', childStr: 'name' },
              { code: 'status' },
              { code: 'text' },
            ]}
            noActions
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SentMessages;
