import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueries } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import ShaxmatkaFilter from 'components/ui/shaxmatka-filters/ShaxmatkaFilter';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useFormSubmit from 'hooks/useFormSubmit';
import useNotification from 'hooks/useNotification';
import useTopPanel from 'hooks/useTopPanel';
import HOME_TYPE from 'shared/homeTypeList';
import ShaxmatkaBlocksTable from './shaxmatka-parts/block/ShaxmatkaBlocksTable';
import ShaxmatkaHomeDetail from './shaxmatka-parts/home/ShaxmatkaHomeDetail';
import Shaxmatka1 from './shaxmatka-parts/Shaxmatka1';
import Shaxmatka2 from './shaxmatka-parts/Shaxmatka2';
import ShaxmatkaModeSelect from './shaxmatka-parts/ShaxmatkaModeSelect';
import ShaxmatkaStatuses from './shaxmatka-parts/ShaxmatkaStatuses';
import ShaxmatkaTopPanel from './shaxmatka-parts/ShaxmatkaTopPanel';
import ShaxmatkaTypeMenu from './shaxmatka-parts/ShaxmatkaTypeMenu';
import ShaxmatkaVisualView from './shaxmatka-parts/ShaxmatkaVisualView';
import ShaxmatkaEditHome from './shaxmatkaSideModals/ShaxmatkaEditHome';
import ShaxmatkaPriceEditForm from './shaxmatkaSideModals/ShaxmatkaPriceEditForm';

const validationSchema = yup.object({
  number: yup.string().optional(),
  rooms: yup.string().required('shaxmatka.validation.rooms'),
  square: yup.number().required('shaxmatka.validation.square'),
  islive: yup.string().required('shaxmatka.validation.islive'),
  isrepaired: yup.string().required('shaxmatka.validation.isrepaired'),
  start: yup.number().required('shaxmatka.validation.start'),
});

const ShaxmatkaBlock = () => {
  const { t, i18n } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const { setComponent } = useTopPanel();
  const [shaxmatkaMode, setShaxmatkaMode] = useState('VIEW');
  const [resetFilter, setResetFilter] = useState(false);
  const [filterExpanded, setFilterExpanded] = useState(false);
  const [homeExpanded, setHomeExpanded] = useState(false);
  const [blocks, setBlocks] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [editTypeMultiple, setEditTypeMultiple] = useState(false);
  const [selectedHomes, setSelectedHomes] = useState([]);
  const sendNotification = useNotification();
  const { submit, isSubmitting } = useFormSubmit();
  const [isMultipleSubmitting, setIsMultipleSubmitting] = useState(false);
  const anchorSettingsRef = useRef(null);
  const [settingsMenuValue, setSettingsMenuValue] = useState('number');
  const [settingsMenu, setSettingsMenu] = useState(false);
  const [searchParams] = useSearchParams();

  const formik = useFormik({
    initialValues: {
      number: '',
      rooms: '',
      square: '',
      islive: null,
      isrepaired: null,
      start: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let newValues = {
        rooms: values.rooms,
        number: values.number,
        square: values.square,
        start: values.start,
        isrepaired: values.isrepaired,
        islive: values.islive,
      };
      if (!editTypeMultiple && selectedHomes.length == 1) {
        submit(
          { type: 'put', contentType: 'formData' },
          newValues,
          '/admin/home',
          'Xona',
          selectedHomes[0]?.id,
          false,
          handleResponse
        );
      } else if (editTypeMultiple) {
        setIsMultipleSubmitting(true);
        try {
          const multipleResponse = await Promise.all(
            selectedHomes.map((item) =>
              axiosPrivate.post(
                `/admin/home/update/${item?.id}`,
                parseToFormData(getMultipleHomeNewValuesByPlanActive(values)),
                {
                  headers: { 'Content-Type': 'multipart/form-data' },
                }
              )
            )
          );
          handleMultipleResponse(multipleResponse);
        } catch (error) {
          sendNotification({
            msg: error?.response?.data?.message || error?.message,
            variant: 'error',
          });
          setIsMultipleSubmitting(false);
        }
      }
    },
  });

  const [objectQuery, blocksQuery] = useQueries([
    {
      queryKey: ['/admin/object/edit/'],
      queryFn: async function () {
        const response = await axiosPrivate.get(
          `/admin/object/edit/${searchParams.get('objectId')}`
        );
        return response.data.data;
      },
      enabled: false,
      retry: false,
    },
    {
      queryKey: ['/admin/home/padezd/'],
      queryFn: async function () {
        const response = await axiosPrivate.get(
          `/admin/home/padezd/${searchParams.get('objectId')}${setBlocksById()}`
        );
        return response.data.data;
      },
      onSuccess: (data) => {
        setBlocks(JSON.parse(JSON.stringify(data)));
      },
      enabled: false,
      retry: false,
    },
  ]);

  const setBlocksById = () => {
    let entries = Object.fromEntries(searchParams);
    let objectsQuery = '';
    Object.keys(entries).forEach((item) => {
      if (item.includes('block')) {
        objectsQuery += `${item}=${entries[item]}&`;
      }
    });
    return objectsQuery.length > 0 ? `?${objectsQuery}` : '';
  };

  const getMultipleHomeNewValuesByPlanActive = (values) => {
    const newValues = {
      rooms: values.rooms,
      square: values.square,
      start: values.start,
      isrepaired: values.isrepaired,
      islive: values.islive,
      _method: 'put',
    };

    return newValues;
  };

  const parseToFormData = (values) => {
    let formData = new FormData();
    for (let key in values) {
      formData.append(key, values[key]);
    }
    return formData;
  };

  const resetSelection = () => {
    Array.from(document.getElementsByClassName('home-item')).forEach((item) =>
      item.classList.remove('item-selected')
    );
  };

  const resetFilledHomeValues = (homeId, padezdIndex, blockIndex) => {
    if (homeId) {
      blocks[blockIndex]?.padezd?.[padezdIndex].homes.splice(
        blocks[blockIndex]?.padezd?.[padezdIndex]?.homes.findIndex(
          (item) => item.id == homeId
        ),
        1,
        blocksQuery.data[blockIndex]?.padezd?.[padezdIndex]?.homes.filter(
          (item) => item.id == homeId
        )[0]
      );
      setBlocks([...blocks]);
    }
  };

  const checkRoomActive = (id, padezdIndex, blockIndex) => {
    if (shaxmatkaMode == 'VIEW') {
      return true;
    }
    if (
      blocksQuery.data[blockIndex]?.padezd?.[padezdIndex]?.homes.filter(
        (item) => item.id == id
      )[0].status != HOME_TYPE.ACTIVE.code
    ) {
      sendNotification({
        msg: t('shaxmatka.alerts.warning.invalidHome'),
        variant: 'warning',
      });
      return false;
    }
    return true;
  };

  const setHomeValuesToForm = useCallback(
    (homeId, padezdIndex, blockIndex) => {
      const home = blocksQuery.data[blockIndex]?.padezd?.[
        padezdIndex
      ]?.homes.filter((item) => item.id == homeId)[0];
      const newValues = {
        rooms: home.rooms || '',
        number: home.number || '',
        square: home.square || '',
        islive: home.islive ?? '',
        isrepaired: home.isrepaired ?? '',
        start: home.start || '',
      };

      formik.setValues(newValues);
    },
    [blocksQuery.data, formik]
  );

  const toggleSelectionItem = useCallback(
    (id, padezdIndex, blockIndex) => {
      if (shaxmatkaMode === 'VIEW') {
        document
          .querySelectorAll('.home-item.item-selected')
          .forEach((item) => item.classList.remove('item-selected'));
        if (selectedHomes.find((item) => item.id == id)) {
          setHomeExpanded(false);
          setSelectedHomes([]);
        } else {
          document
            .querySelector(
              `.block-${blockIndex}-${padezdIndex}-home#home-${id}`
            )
            .classList.toggle('item-selected');
          setHomeExpanded(true);
          setSelectedHomes([{ id, padezdIndex, blockIndex }]);
        }
      } else {
        if (checkRoomActive(id, padezdIndex, blockIndex)) {
          if (editTypeMultiple) {
            document
              .querySelector(
                `.block-${blockIndex}-${padezdIndex}-home#home-${id}`
              )
              .classList.toggle('item-selected');
            if (selectedHomes.find((item) => item.id == id)) {
              selectedHomes.splice(
                selectedHomes.map((el) => el.id).indexOf(id),
                1
              );
              resetFilledHomeValues(id, padezdIndex, blockIndex);
            } else {
              if (selectedHomes.length == 0) {
                setMultipleHomeValuesToForm(id, padezdIndex, blockIndex);
              } else {
                setFormDataToHome({ id, padezdIndex, blockIndex });
                setBlocks([...blocks]);
              }
              selectedHomes.push({ id, padezdIndex, blockIndex });
            }
            setSelectedHomes(selectedHomes);
            selectedHomes.length > 0
              ? setHomeExpanded(true)
              : setHomeExpanded(false);
          } else {
            Array.from(document.getElementsByClassName('home-item')).forEach(
              (item) =>
                item.id != `home-${id}` &&
                item.classList.remove('item-selected')
            );
            document
              .querySelector(
                `.block-${blockIndex}-${padezdIndex}-home#home-${id}`
              )
              ?.classList.toggle('item-selected');
            formik.resetForm();
            if (selectedHomes.find((item) => item.id == id)) {
              setHomeExpanded(false);
              setSelectedHomes([]);
              resetFilledHomeValues(id, [padezdIndex], blockIndex);
            } else {
              setHomeExpanded(true);
              resetFilledHomeValues(
                selectedHomes[0]?.id,
                selectedHomes[0]?.padezdIndex,
                selectedHomes[0]?.blockIndex
              );
              setSelectedHomes([{ id, padezdIndex, blockIndex }]);
              setHomeValuesToForm(id, padezdIndex, blockIndex);
            }
          }
        }
      }
    },
    [shaxmatkaMode, editTypeMultiple, selectedHomes, setHomeValuesToForm]
  );

  const toggleVisualViewHomeItem = (id, padezdId, blockId) => {
    if (blocksQuery.data && blocksQuery.data.length > 0) {
      const foundBlockIndex = blocksQuery.data?.findIndex(
        (item) => item?.id == blockId
      );
      if (foundBlockIndex > -1) {
        const padezdIndex = blocksQuery.data[
          foundBlockIndex
        ]?.padezd?.findIndex((item) => item?.id == padezdId);
        if (selectedHomes.find((item) => item.id == id)) {
          setHomeExpanded(false);
          setSelectedHomes([]);
        } else {
          setHomeExpanded(true);
          setSelectedHomes([
            { id: id, padezdIndex, blockIndex: foundBlockIndex },
          ]);
        }
      } else {
        setHomeExpanded(false);
        setSelectedHomes([]);
      }
    }
  };

  const handleEditType = (value) => {
    selectedHomes.forEach((selectedHome) => {
      blocks[selectedHome.blockIndex]?.padezd?.[
        selectedHome?.padezdIndex
      ]?.homes?.splice(
        blocks[selectedHome.blockIndex]?.padezd?.[
          selectedHome?.padezdIndex
        ]?.homes?.findIndex((item) => item.id == selectedHome.id),
        1,
        blocksQuery.data[selectedHome.blockIndex]?.padezd?.[
          selectedHome?.padezdIndex
        ]?.homes?.filter((item) => item.id == selectedHome.id)[0]
      );
    });
    setBlocks([...blocks]);
    resetSelection();
    setSelectedHomes([]);
    formik.resetForm();
    setEditTypeMultiple(value);
    if (!value) {
      setHomeExpanded(false);
    }
  };

  const handleResponse = (response) => {
    blocksQuery.refetch();
    formik.resetForm();
    resetSelection();
    setSelectedHomes([]);
    setHomeExpanded(false);
  };

  const handleMultipleResponse = (multipleResponse) => {
    let result = multipleResponse.reduce((acc, curr) => {
      return acc && curr && curr.data && curr.data.status;
    }, true);
    if (result) {
      sendNotification({
        msg: t('shaxmatka.alerts.success.homesUpdated'),
        variant: 'success',
      });
      formik.resetForm();
      resetSelection();
      setSelectedHomes([]);
      blocksQuery.refetch();
      setHomeExpanded(false);
    }
    setIsMultipleSubmitting(false);
  };

  const setMultipleHomeValuesToForm = (homeId, padezdIndex, blockIndex) => {
    let home = blocksQuery.data[blockIndex]?.padezd?.[
      padezdIndex
    ]?.homes.filter((item) => item.id == homeId)[0];
    let newValues = {
      rooms: home.rooms || '',
      square: home.square || '',
      islive: home.islive ?? '',
      isrepaired: home.isrepaired ?? '',
      start: home.start || '',
    };

    formik.setValues(newValues);
  };

  const setFormDataToHome = (selectedH) => {
    let selectedHomeData =
      blocks[selectedH.blockIndex]?.padezd?.[selectedH?.padezdIndex]?.homes[
        blocks[selectedH.blockIndex]?.padezd?.[
          selectedH?.padezdIndex
        ]?.homes.findIndex((item) => item.id == selectedH.id)
      ];
    let formikNewValues = {
      rooms: formik.values.rooms,
      square: formik.values.square,
      start: formik.values.start,
      isrepaired: formik.values.isrepaired,
      islive: formik.values.islive,
    };

    if (
      blocks[selectedH.blockIndex] &&
      blocks[selectedH.blockIndex] &&
      blocks[selectedH.blockIndex].padezd &&
      blocks[selectedH.blockIndex].padezd[selectedH.padezdIndex] &&
      blocks[selectedH.blockIndex].padezd[selectedH.padezdIndex].homes
    ) {
      blocks[selectedH.blockIndex].padezd[selectedH.padezdIndex].homes[
        blocks[selectedH.blockIndex]?.padezd?.[
          selectedH?.padezdIndex
        ].homes.findIndex((item) => item.id == selectedH.id)
      ] = {
        ...selectedHomeData,
        ...formikNewValues,
      };
    }
  };

  const setSingleHomeDataFromForm = () => {
    if (selectedHomes.length > 0) {
      setFormDataToHome(selectedHomes[0]);
      setBlocks([...blocks]);
    }
  };

  const setMultipleHomeDataFromForm = () => {
    if (selectedHomes.length > 0) {
      selectedHomes.forEach((selectedHome) => {
        setFormDataToHome(selectedHome);
      });
      setBlocks([...blocks]);
    }
  };

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
    setResetFilter(true);
    setFilterExpanded(false);
    handleCloseHomeDetail();
  };

  const handleCloseHomeDetail = () => {
    resetSelection();
    setSelectedHomes([]);
    setHomeExpanded(false);
  };

  const handleRefetchBlocks = () => {
    resetSelection();
    setSelectedHomes([]);
    setHomeExpanded(false);
    blocksQuery.refetch();
  };

  useEffect(() => {
    let entries = Object.fromEntries(searchParams);
    if (Object.keys(entries).includes('objectId')) {
      objectQuery.refetch();
      blocksQuery.refetch();
      formik.resetForm();
      resetSelection();
      setSelectedHomes([]);
      setFilterExpanded(false);
      setHomeExpanded(false);
    }
  }, [searchParams]);

  useEffect(() => {
    setComponent(<ShaxmatkaTopPanel />);
  }, [i18n.language]);

  useEffect(() => {
    if (!editTypeMultiple) {
      setSingleHomeDataFromForm();
    } else {
      setMultipleHomeDataFromForm();
    }
  }, [formik.values]);

  useEffect(() => {
    if (shaxmatkaMode == 'VIEW') {
      if (editTypeMultiple) {
        // setHomeExpanded(false);
        selectedHomes.forEach((item) =>
          resetFilledHomeValues(item.id, item.blockIndex)
        );
        setEditTypeMultiple(false);
        resetSelection();
        if (selectedHomes?.[0]) {
          setSelectedHomes([selectedHomes?.[0]] || []);
          document
            .querySelector(`.home-item#home-${selectedHomes?.[0]?.id}`)
            ?.classList?.add('item-selected');
        }
      }
    } else if (shaxmatkaMode == 'EDIT' && selectedHomes?.[0]) {
      // setSelectedHomes([]);
      // setHomeExpanded(false);
      // resetSelection();
      if (selectedHomes?.[0]) {
        const { blockIndex, padezdIndex, id } = selectedHomes?.[0];
        const home = blocksQuery.data[blockIndex]?.padezd?.[
          padezdIndex
        ]?.homes.filter((item) => item?.id == id)?.[0];
        const newValues = {
          rooms: home?.rooms || '',
          number: home?.number || '',
          square: home?.square || '',
          islive: home?.islive ?? '',
          isrepaired: home?.isrepaired ?? '',
          start: home?.start || '',
        };
        formik.setValues(newValues);
      }
    }
  }, [shaxmatkaMode]);

  return (
    <div className='flex'>
      <div
        className={`sheet-filter-wrapper${filterExpanded ? ' is-full' : ''}`}
      >
        {filterExpanded && Object.keys(blocks).length > 0 && (
          <div className='sheet-filter-body'>
            <div className='absolute top-0 right-0'>
              <IconButton
                variant='onlyIcon'
                color='primary'
                onClick={() => setFilterExpanded((prev) => !prev)}
              >
                <i className='bi bi-x' />
              </IconButton>
            </div>
            <ShaxmatkaFilter
              blocks={blocks}
              filterExpanded={filterExpanded}
              resetFilter={resetFilter}
              setResetFilter={setResetFilter}
            />
          </div>
        )}
      </div>
      <div
        className={`sheet-type-tabs${
          filterExpanded
            ? homeExpanded
              ? ' is-mini-dual'
              : ' is-mini'
            : homeExpanded
            ? ' is-mini'
            : ''
        }`}
      >
        <div className='flex mb-1 -mt-1 items-center'>
          {!filterExpanded && tabValue != 3 && (
            <Button
              variant={filterExpanded ? 'filterContained' : 'filterOutlined'}
              color='primary'
              startIcon={<i className='bi bi-filter' />}
              onClick={() => setFilterExpanded((prev) => !prev)}
              disabled={
                (blocksQuery.isLoading || blocksQuery.isFetching) &&
                (objectQuery.isLoading || objectQuery.isFetching)
              }
              size='small'
            >
              {t('common.button.filter')}
            </Button>
          )}
          <div className='sheet-top-action-wrapper'>
            <ShaxmatkaStatuses
              isLoading={
                (blocksQuery.isLoading || blocksQuery.isFetching) &&
                (objectQuery.isLoading || objectQuery.isFetching)
              }
              blocks={blocks}
            />
            <div className='flex items-center justify-end my-1'>
              <ShaxmatkaModeSelect
                value={shaxmatkaMode}
                setValue={setShaxmatkaMode}
              />
              <ShaxmatkaTypeMenu
                tabValue={tabValue}
                changeFn={handleTabChange}
              />
              {tabValue == 0 && (
                <div className='ml-2'>
                  <Button
                    variant='action'
                    color='primary'
                    ref={anchorSettingsRef}
                    onClick={() => setSettingsMenu((prev) => !prev)}
                    className='!w-9 !h-9'
                  >
                    <i className='bi bi-three-dots-vertical' />
                  </Button>
                  <Menu
                    open={settingsMenu}
                    anchorEl={anchorSettingsRef.current}
                    disableScrollLock={true}
                    onClose={() => setSettingsMenu(false)}
                  >
                    <MenuItem
                      onClick={() => {
                        setSettingsMenuValue('number');
                        setSettingsMenu(false);
                      }}
                      className={`small-size${
                        settingsMenuValue == 'number' ? ' is-active' : ''
                      }`}
                    >
                      {t('common.filter.number')}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setSettingsMenuValue('rooms');
                        setSettingsMenu(false);
                      }}
                      className={`small-size${
                        settingsMenuValue == 'rooms' ? ' is-active' : ''
                      }`}
                    >
                      {t('common.filter.roomsNumber')}
                    </MenuItem>
                  </Menu>
                </div>
              )}
            </div>
          </div>
        </div>

        {tabValue == 0 && (
          <Shaxmatka1
            isLoading={blocksQuery?.isLoading || blocksQuery?.isFetching}
            isEditing={shaxmatkaMode == 'EDIT'}
            blocks={blocks}
            toggleSelectionItem={toggleSelectionItem}
            settingsMenuValue={settingsMenuValue}
          />
        )}
        {tabValue == 1 && (
          <Shaxmatka2
            blocksQuery={blocksQuery}
            objectQuery={objectQuery}
            blocks={blocks}
            toggleSelectionItem={toggleSelectionItem}
          />
        )}
        {tabValue == 2 && (
          <div className='pb-4 pt-2'>
            <ShaxmatkaBlocksTable
              isLoading={
                (blocksQuery.isLoading || blocksQuery.isFetching) &&
                (objectQuery.isLoading || objectQuery.isFetching)
              }
              isError={blocksQuery.isError || objectQuery.isError}
              blocks={blocks}
              objectData={objectQuery?.data}
              toggleSelectionItem={toggleSelectionItem}
            />
          </div>
        )}
        {tabValue == 3 && (
          <ShaxmatkaVisualView toggleHomeItem={toggleVisualViewHomeItem} />
        )}
      </div>
      <div className={`sheet-actions-area${homeExpanded ? ' is-full' : ''}`}>
        {homeExpanded &&
          (shaxmatkaMode == 'EDIT' ? (
            <ShaxmatkaEditHome
              editTypeMultiple={editTypeMultiple}
              handleEditType={handleEditType}
              formik={formik}
              isSubmitting={isSubmitting}
              isMultipleSubmitting={isMultipleSubmitting}
              handleCloseHomeDetail={handleCloseHomeDetail}
            />
          ) : shaxmatkaMode == 'PRICE_EDIT' ? (
            <ShaxmatkaPriceEditForm
              blocks={blocks}
              objectId={searchParams.get('objectId')}
              setHomeExpanded={setHomeExpanded}
              handleCloseHomeDetail={handleCloseHomeDetail}
              refetch={blocksQuery.refetch}
            />
          ) : (
            <div className='sheet-actions-body'>
              <div className='absolute top-0 right-0'>
                <IconButton
                  variant='onlyIcon'
                  color='primary'
                  onClick={() => handleCloseHomeDetail()}
                >
                  <i className='bi bi-x' />
                </IconButton>
              </div>
              <ShaxmatkaHomeDetail
                selectedHome={selectedHomes}
                blocks={blocksQuery.data}
                object={objectQuery.data}
                refetchFn={handleRefetchBlocks}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default memo(ShaxmatkaBlock);
