/* eslint-disable eqeqeq */
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  InputAdornment,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import CRMCallAudioMessage from './message-types/CRMCallAudioMessage';
import CRMTaskMessage from './message-types/CRMTaskMessage';
// import CommentDatePicker from './comment-box-items/CommentDatePicker';
import CommentTypeSelect from './comment-box-items/CommentTypeSelect';
import CommentStaffSelect from './comment-box-items/CommentStaffSelect';
import useAuth from 'hooks/useAuth';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useNotification from 'hooks/useNotification';
import CRMLeadCommentMessage from './message-types/CRMLeadCommentMessage';
import CRMTasksModal from './CRMTasksModal';
import CRMDataChangeMessage from './message-types/CRMDataChangeMessage';
import useMenu from 'hooks/useMenu';
import CommentTaskTypesSelect from './comment-box-items/CommentTaskTypesSelect';
import { useAppInfo } from 'context';
import MDatePicker from 'components/ui/form/mDatePicker/MDatePicker';
import FeatureTasks from './FeatureTasks';
import CRMEvents from './message-types/CRMEvents';
import CRMMessage from './message-types/CRMMessage';

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    padding: '2px',
  },
});

const ClientHistory = () => {
  const [{ refetchTaskCount }] = useAppInfo();
  const { t } = useTranslation();
  const { id } = useParams();
  const [{ user }] = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openTasks, setOpenTasks] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const sendNotification = useNotification();
  const [selectedType, setSelectedType] = useState({});
  const [selectedStaff, setSelectedStaff] = useState(user?.user);
  const [selectedTaskType, setSelectedTaskType] = useState({});
  const today = moment()?.startOf('day')?.format('YYYY-MM-DD HH:mm');
  const [commentDate, setCommentDate] = useState(today);
  const [commentText, setCommentText] = useState('');
  const [leadHistory, setLeadHistory] = useState({});
  const [leadTasks, setLeadTasks] = useState([]);
  const [featureTasks, setFeatureTasks] = useState([]);
  const { hasChild } = useMenu();
  const [hasError, setHasError] = useState(false);
  const [mPickerVisible, setMPickerVisible] = useState(false);

  const view = (code) => hasChild('CUSTOMS', code);

  const { isLoading, isFetching, refetch } = useQuery({
    queryKey: ['/admin/custom/history/{id}', id],
    queryFn: async function () {
      const response = await axiosPrivate.get(`/admin/custom/history/${id}`);
      return response.data.data;
    },
    onSuccess: (data) => {
      if (data && data?.history) {
        setLeadHistory(sortHistoryData(data?.history));
        if (data && data?.lead && data?.lead?.tasks) {
          setLeadTasks(sortHistoryData({ ...data?.lead?.tasks }));
          const featureTasks = data?.lead?.tasks?.filter(
            (task) =>
              task?.date && moment(task?.date, 'YYYY-MM-DD').diff(moment()) > 0
          );
          setFeatureTasks(featureTasks);
        }
      }
    },
    enabled: !hasError && !!id,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const sortHistoryData = (data) => {
    const uniqDay = Array.from(
      new Set(
        Object.values(data).map((item) =>
          moment(item?.created_at || item?.date).format('DD_MM_YYYY')
        )
      )
    ).sort((a, b) => moment(a, 'DD_MM_YYYY').diff(moment(b, 'DD_MM_YYYY')));

    const sortedData = Object.keys(data)
      .map((item) => data[item])
      .sort((a, b) =>
        moment(a?.created_at || a?.date).diff(b?.created_at || b?.date)
      );

    const newData = [];
    const events = { type: 'events', created_at: null, list: [] };
    sortedData?.forEach((data) => {
      if (data?.type !== 'events') {
        newData.push(data);
        if (events?.list?.length) {
          newData.push({ ...events, list: [...events.list] });
          events.list.length = 0;
          events.created_at = null;
        }
      } else if (
        !events?.created_at ||
        moment(events?.created_at).format('MM-DD-YYYY HH:mm') ===
          moment(data?.date).format('MM-DD-YYYY HH:mm')
      ) {
        events.list.push(data);
        events.created_at = data?.date;
      } else {
        newData.push({ ...events, list: [...events.list] });
        events.list.length = 0;
        events.created_at = null;

        events.list.push(data);
        events.created_at = data?.date;
      }
    });
    if (events?.list?.length) {
      newData.push({ ...events, list: [...events.list] });
      events.list.length = 0;
      events.created_at = null;
    }
    let formedData = {};
    for (let i = 0; i < uniqDay.length; i++) {
      formedData[uniqDay[i]] = newData.filter(
        (sD) =>
          moment(sD?.created_at).format('DD_MM_YYYY') == uniqDay[i] &&
          (!sD?.date || moment(sD?.date, 'YYYY-MM-DD').diff(moment()) <= 0)
      );
    }
    return formedData;
  };

  const sendComment = async () => {
    if (
      selectedType?.code &&
      selectedStaff?.id &&
      // commentText &&
      (selectedType?.code == 'TASK' ? moment(commentDate).isValid() : true)
    ) {
      try {
        setIsSubmitting(true);
        const response = await axiosPrivate.post(
          selectedType?.code == 'TASK'
            ? `/admin/custom/taskstore/${id}`
            : `/admin/custom/addcomment/${id}`,
          selectedType?.code == 'TASK'
            ? JSON.stringify({
                custom_id: id,
                user_id: selectedStaff?.id,
                title: commentText,
                date: commentDate
                  ? moment(commentDate).format('YYYY-MM-DD HH:mm')
                  : null,
                type_id: selectedTaskType?.id,
              })
            : JSON.stringify({
                custom_id: id,
                user_id: selectedStaff?.id,
                comment: commentText,
              }),
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );
        if (response.data && response.data.status) {
          refetch();
          setCommentText('');
          // setCommentDate(today);
          refetchTaskCount?.();
        }
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
        sendNotification({
          msg: error?.response?.data?.message || error?.message,
          variant: 'error',
        });
      }
    } else {
      sendNotification({
        msg:
          selectedType?.code == 'TASK'
            ? "Iltimos, topshiriqni yaratish uchun kerakli maydonlarni to'ldiring!"
            : "Iltimos, xabarnomani yaratish uchun kerakli maydonlarni to'ldiring!",
        variant: 'warning',
      });
    }
  };

  const cancelSend = () => {
    setCommentText('');
  };

  const onChangeMPicker = (date, start, end) => {
    const newDate = moment(date + 'T' + start ?? end, 'YYYY-MM-DDTHH:mm');
    setCommentDate(newDate);
    // setCreateDedline({
    //   start: date + ' ' + start,
    //   end: date + ' ' + end
    // });
  };

  const handleMPicker = () => {
    setMPickerVisible(true);
  };

  const onRemoveTime = () => {
    const newDate = moment(commentDate, 'YYYY-MM-DDTHH:mm')?.startOf('day');
    setCommentDate(newDate);
  };

  useEffect(() => {
    const scrollToBottom = () => {
      const element = document.getElementById('lead-history-details-wrapper');
      if (element?.scrollHeight) {
        element.style.scrollBehavior = 'smooth';
        element.scrollTop = element.scrollHeight;
      }
    };

    const timeout = setTimeout(scrollToBottom, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [leadHistory]);

  const { data: actionList } = useQuery({
    queryKey: ['/admin/event/actions'],
    queryFn: async function () {
      const response = await axiosPrivate.get('/admin/event/actions');
      return response.data.data;
    },

    enabled: !hasError && !!id,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });
  const { data: blockList } = useQuery({
    queryKey: ['/crm/block'],
    queryFn: async function () {
      const response = await axiosPrivate.get('/crm/block');
      return response.data.data;
    },

    enabled: !hasError && !!id,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });
  const { data: userList } = useQuery({
    queryKey: ['/dictionary/staffes'],
    queryFn: async function () {
      const response = await axiosPrivate.get('/dictionary/staffes');
      return response.data.data;
    },

    enabled: !hasError && !!id,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const getActionName = useCallback(
    (event) => {
      return actionList?.find((action) => action?.key === event)?.name || '';
    },
    [actionList]
  );

  const pipesList = useMemo(
    () =>
      blockList
        ?.map(({ pipes, name }) =>
          pipes?.map((p) => ({ ...p, blockName: name }))
        )
        ?.flat(Infinity) || [],
    [blockList]
  );

  const getStaffName = useCallback(
    (id) => {
      return userList?.find((user) => user?.id === id)?.name || '';
    },
    [userList]
  );

  const getVaronkaColorName = useCallback(
    (id) => {
      return pipesList?.find((pipe) => pipe?.id === id) || '';
    },
    [pipesList]
  );

  return (
    <div className='lead-history-items-wrapper !w-full'>
      {isLoading || isFetching ? (
        <div className='lead-history-details-wrapper'>
          <div className='circular-progress-box py-5'>
            <CircularProgress size={35} />
          </div>
        </div>
      ) : (
        <div
          className={`lead-history-details-wrapper !h-full ${
            view('CUSTOMS_ADD_TASK') || view('CUSTOMS_ADD_COMMENT')
              ? ''
              : 'is-new'
          }`}
          id='lead-history-details-wrapper'
        >
          <div className='pb-[340px]'>
            {leadHistory && Object.keys(leadHistory).length > 0 ? (
              Object.keys(leadHistory).map((historyItem, historyIndex) => (
                <div
                  className='date-history-wrapper'
                  key={`date-history-${historyIndex}`}
                >
                  <div className='date-time-title text-center'>
                    {moment(historyItem, 'DD_MM_YYYY').format('YYYY-MM-DD')}
                  </div>
                  <div className='date-items-wrapper'>
                    {leadHistory[historyItem] &&
                      leadHistory[historyItem].length > 0 &&
                      leadHistory[historyItem].map((item, itemIndex) =>
                        item?.type == 'events' ? (
                          <CRMEvents
                            key={`item-details-${itemIndex}`}
                            data={item}
                            getActionName={getActionName}
                            getStaffName={getStaffName}
                            getVaronkaColorName={getVaronkaColorName}
                          />
                        ) : (
                          <div
                            className={`item-details-wrapper !base-border !rounded-[20px] ${
                              item.type == 'task' &&
                              item?.status == 1 &&
                              moment(item?.date)?.isBefore()
                                ? '!border-red-600'
                                : ''
                            }`}
                            key={`item-details-${itemIndex}`}
                          >
                            {item.type == 'call_history' && (
                              <CRMCallAudioMessage data={item} />
                            )}
                            {item.type == 'task' && (
                              <CRMTaskMessage
                                view={view}
                                data={item}
                                refetchFn={refetch}
                              />
                            )}
                            {item.type == 'comment' && (
                              <CRMLeadCommentMessage data={item} />
                            )}
                            {item.type == 'lead_history' && (
                              <CRMDataChangeMessage data={item} />
                            )}
                            {item.type == 'sms' && <CRMMessage data={item} />}
                          </div>
                        )
                      )}
                  </div>
                </div>
              ))
            ) : (
              <div className='no-data-placeholder'>
                <span className='no-data-found-wrapper bg-transparent !text-base-color-light p-4 rounded-lg'>
                  <i className='bi bi-exclamation-octagon text-xl leading-4 mr-1' />{' '}
                  {t('common.global.noDataFound')}
                </span>
              </div>
            )}
          </div>
        </div>
      )}

      {(view('CUSTOMS_ADD_TASK') || view('CUSTOMS_ADD_COMMENT')) && (
        <div className='absolute z-50 bottom-0 right-[6px] rounded-lg shadow-[0_-1px_5px_0_rgba(0,0,0,0.3)] left-0 bg-[#f0f2f5]'>
          <div className='max-h-[120px] overflow-y-auto my-scroll px-3 grid grid-cols-1 items-end gap-2'>
            {featureTasks?.length > 0 && !(isLoading || isFetching) && (
              <div className='pt-3'>
                <FeatureTasks tasks={featureTasks} refetch={refetch} />
              </div>
            )}
          </div>
          <div className='lead-history-input-wrapper'>
            <div className='input-body'>
              <div className='flex items-center justify-between'>
                <div className='input-top-actions-wrapper'>
                  <CommentTypeSelect
                    selectedType={selectedType}
                    setSelectedType={setSelectedType}
                  />
                  <div className='divider'></div>
                  {selectedType?.code == 'TASK' && (
                    <>
                      <ClickAwayListener
                        onClickAway={() => setMPickerVisible(false)}
                      >
                        <div>
                          <NoMaxWidthTooltip
                            title={
                              <MDatePicker
                                commentDate={commentDate}
                                setCommentDate={setCommentDate}
                                visibleTime={true}
                                onChangeMPicker={onChangeMPicker}
                              />
                            }
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={() => setMPickerVisible(false)}
                            open={mPickerVisible}
                            placement='top'
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                          >
                            <TextField
                              className={'!w-[100px] !h-[30px]'}
                              onClick={handleMPicker}
                              value={
                                commentDate
                                  ? moment(commentDate)?.format('DD.MM.YYYY')
                                  : ''
                              }
                              sx={{
                                '.MuiInputBase-root': {
                                  height: 30,
                                },
                              }}
                            />
                          </NoMaxWidthTooltip>
                        </div>
                      </ClickAwayListener>
                      {moment(commentDate)
                        ?.format('DD.MM.YYYY HH:mm')
                        ?.endsWith('00:00') ? null : (
                        <>
                          <div className='divider'></div>
                          <TextField
                            className={'!w-[80px] !h-[30px]'}
                            onClick={handleMPicker}
                            value={
                              commentDate
                                ? moment(commentDate)?.format(
                                    'DD.MM.YYYY HH:mm'
                                  ) == moment(today)?.format('DD.MM.YYYY HH:mm')
                                  ? ''
                                  : moment(commentDate)?.format('HH:mm')
                                : ''
                            }
                            sx={{
                              '.MuiInputBase-root': {
                                height: 30,
                                paddingRight: '5px',
                              },
                            }}
                            InputProps={{
                              readOnly: true,
                              endAdornment: (
                                <InputAdornment
                                  position='end'
                                  className='!cursor-pointer'
                                  onClick={onRemoveTime}
                                >
                                  <i className='bi bi-x' />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </>
                      )}
                      <div className='divider'></div>
                    </>
                  )}

                  <CommentStaffSelect
                    selectedStaff={selectedStaff}
                    setSelectedStaff={setSelectedStaff}
                  />

                  {selectedType?.code == 'TASK' && (
                    <Fragment>
                      <div className='divider'></div>
                      <CommentTaskTypesSelect
                        selectedTaskType={selectedTaskType}
                        setSelectedTaskType={setSelectedTaskType}
                      />
                    </Fragment>
                  )}
                </div>
              </div>

              <div className='input-text-area'>
                <TextField
                  color='formColor'
                  variant='outlined'
                  fullWidth
                  id='comment-text-area-field'
                  name='comment-text-area-field'
                  className='comment-text-area-field'
                  label=''
                  multiline={true}
                  disabled={isLoading || isFetching}
                  rows={3}
                  value={commentText}
                  onChange={(event) => {
                    setCommentText(event.target.value);
                  }}
                  autoComplete='off'
                />
                {!commentText && (
                  <span className='comment-text-area-field-placeholder'>
                    {t('crm.leadHistory.type')}
                  </span>
                )}
              </div>
              <div className='input-action-buttons-wrapper'>
                <Button
                  color='success'
                  variant='contained'
                  size='small'
                  className='!bg-[#16BA5C] !mr-2 !font-normal'
                  onClick={() => sendComment()}
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <CircularProgress
                      size={15}
                      color='inherit'
                      className='mr-1'
                    />
                  )}
                  {t('common.button.send')}
                </Button>
                <Button
                  variant='contained'
                  color='success'
                  size='small'
                  onClick={() => cancelSend()}
                  disabled={isSubmitting}
                  className='!bg-[#FF6D6D] !font-normal'
                >
                  {t('common.button.cancel')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {openTasks && (
        <CRMTasksModal
          open={openTasks}
          setOpen={setOpenTasks}
          tasks={leadTasks}
          refetchFn={refetch}
        />
      )}
    </div>
  );
};
export default ClientHistory;
