import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import useTopPanel from 'hooks/useTopPanel';
import CRMBlockColumns from './crm-parts/block-columns/CRMBlockColumns';
import CRMTopPanel from './crm-parts/top-panel/CRMTopPanel';
import useMenu from 'hooks/useMenu';
import TableList from './crm-parts/table-list';

const CRM = () => {
  const { setComponent } = useTopPanel();
  const { t } = useTranslation();
  const { hasChild } = useMenu();
  const [openSettings, setOpenSettings] = useState(false);
  const [showType, setShowType] = useState(
    localStorage.getItem('crm-show-type') || 'column'
  );

  const view = (code) => hasChild('CRM', code);

  const handleAlignment = (event, type) => {
    if (type) {
      setShowType(type);
      localStorage.setItem('crm-show-type', type);
    }
  };

  useEffect(() => {
    setComponent(
      <div className='flex items-center gap-4'>
        {t('sidebar.admin.crm')}
        <div className='flex items-center justify-center'>
          <ToggleButtonGroup
            value={showType}
            exclusive
            onChange={handleAlignment}
            className='!shadow-none h-12 !border-none'
          >
            <ToggleButton value='column'>
              <i className='bi bi-layout-three-columns' />
            </ToggleButton>
            <ToggleButton value='sheet'>
              <i className='bi bi-table' />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    );

    return () => setComponent('');
  }, [showType]);

  return (
    <div className='component-list-wrapper rounded-lg'>
      <CRMTopPanel
        addBlock={view('CRM_ADD_BLOCK')}
        addLead={true}
        openSettings={openSettings}
        setOpenSettings={setOpenSettings}
      />
      <div className='crm-wrapper'>
        {showType !== 'sheet' ? (
          <CRMBlockColumns
            openSettings={openSettings}
            setOpenSettings={setOpenSettings}
          />
        ) : (
          <TableList />
        )}
      </div>
    </div>
  );
};
export default memo(CRM);
