/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  FormControl,
  LinearProgress,
  linearProgressClasses,
  Menu,
  MenuItem,
  styled,
} from '@mui/material';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import formLocalizedHelperText from 'utils/formLocalizedHelperText';

const FormTreeSelectField = ({
  size,
  label,
  formik,
  parentLabel,
  parentValue,
  childLabel,
  childValue,
  childOptionField,
  parentFieldName,
  childFieldName,
  path,
  onChange,
  progress = true,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);
  const [selectedObjects, setSelectedObjects] = useState(
    formik?.values?.[parentFieldName]
  );
  const [objects, setObjects] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedObjects(formik?.values?.[parentFieldName]);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedObjects('');
  };

  const { values, setFieldValue } = formik;

  const { isLoading, isFetching } = useQuery({
    queryKey: path,
    queryFn: async function () {
      const response = await axiosPrivate.get(path);
      return response.data.data;
    },
    onSuccess: (data) => {
      setObjects(data);
    },
    enabled: !hasError,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const onClickParent = (id) => {
    setSelectedObjects((prev) => (prev !== id ? id : 'close-it'));
  };

  const onSelectBlock = (id, block) => {
    setFieldValue(parentFieldName, selectedObjects);
    setFieldValue('isfinished', block?.isfinished);
    setFieldValue(childFieldName, id);
    onChange?.(block);
    handleClose();
  };

  const parentValueName = useMemo(() => {
    if (values?.[parentFieldName]) {
      const parent = objects.find(
        (o) => o?.id?.toString() === values?.[parentFieldName]?.toString()
      );
      return parent?.[parentLabel] || '';
    }
    return '';
  }, [values?.[parentFieldName], objects]);

  const childValueName = useMemo(() => {
    if (values?.[childFieldName] && values?.[parentFieldName]) {
      const parent = objects.find(
        (o) =>
          o?.[parentValue]?.toString() === values?.[parentFieldName]?.toString()
      );

      const child = parent?.[childOptionField]?.find(
        (o) =>
          o?.[childValue]?.toString() === values?.[childFieldName]?.toString()
      );

      return child?.[childLabel] || '';
    }
    return '';
  }, [values?.[childFieldName], values?.[parentFieldName], objects]);

  const childColor = useMemo(() => {
    if (values?.[childFieldName] && values?.[parentFieldName]) {
      const parent = objects.find(
        (o) =>
          o?.[parentValue]?.toString() === values?.[parentFieldName]?.toString()
      );

      const child = parent?.[childOptionField]?.find(
        (o) =>
          o?.[childValue]?.toString() === values?.[childFieldName]?.toString()
      );

      return child?.color || '#87898E';
    }
    return '';
  }, [values?.[childFieldName], values?.[parentFieldName], objects]);

  const percentage = useMemo(() => {
    if (values?.[childFieldName] && values?.[parentFieldName]) {
      const parent = objects.find(
        (o) =>
          o?.[parentValue]?.toString() === values?.[parentFieldName]?.toString()
      );

      const child = parent?.[childOptionField]?.findIndex(
        (o) =>
          o?.[childValue]?.toString() === values?.[childFieldName]?.toString()
      );
      const percent =
        ((child + 1) * 100) / (parent?.[childOptionField]?.length || 1);
      return percent || 0;
    }
    return 0;
  }, [values?.[childFieldName], values?.[parentFieldName], objects]);

  return (
    <FormControl fullWidth color='formColor'>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant='outlined'
        size={size}
        sx={{
          border: 0,
          borderRadius: 0,
          paddingRight: 0,
          borderColor: '#000',
          justifyContent: 'space-between',
          '&:hover, &focus': {
            border: 0,
          },
        }}
        endIcon={<i className='bi bi-caret-down-fill !text-[12px] !pr-[8px]' />}
        className={`${
          formik.touched[childFieldName] &&
          Boolean(formik.errors[childFieldName])
            ? '!text-red-600'
            : '!text-black'
        } !justify-between !relative !pl-0`}
      >
        <div className='flex justify-start !max-w-[calc(100%-30px)] gap-2 relative !overflow-hidden !text-ellipsis !whitespace-nowrap !pr-[15px]'>
          {parentValueName || childValueName
            ? parentValueName + ' / ' + childValueName
            : label || t('common.filter.select')}
        </div>
      </Button>
      {formik.touched[childFieldName] &&
      Boolean(formik.errors[childFieldName]) ? (
        <span className='text-red-600 text-[12px]'>
          {formLocalizedHelperText(formik.errors[childFieldName])}
        </span>
      ) : null}
      {progress ? (
        <div className='mt-2'>
          <BorderLinearProgress
            variant='determinate'
            bgcolor={childColor}
            value={percentage}
          />
        </div>
      ) : null}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className='!shadow-none'
        PaperProps={{
          sx: { marginTop: '4px', width: '300px' },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: { padding: 0 },
        }}
      >
        {isLoading || isFetching ? (
          <div className='circular-progress-box'>
            <CircularProgress size={25} />
          </div>
        ) : objects && objects.length > 0 ? (
          objects.map((item, index) => (
            <Accordion
              expanded={item?.[parentValue] == selectedObjects}
              onChange={() => onClickParent(item?.[parentValue])}
              className='!shadow-none !p-0 !m-0'
              key={item?.[parentValue]}
            >
              <AccordionSummary
                className='flex-row-reverse  !p-0 !m-0'
                expandIcon={<i className='bi bi-caret-down-fill !px-3' />}
                onClick={(e) => e.stopPropagation()}
                aria-controls='panel1-content'
                id='panel1-header'
              >
                <MenuItem
                  value={item?.id}
                  key={`object-${index + 1}`}
                  fullWidth
                  className={`!w-full !h-full !m-0 !text-[12px] !normal-case`}
                >
                  {item?.[parentLabel]}
                </MenuItem>
              </AccordionSummary>
              <AccordionDetails className='!p-0 !pl-10'>
                {item?.[childOptionField]?.map((child) => {
                  return (
                    <div
                      className='flex'
                      key={`block-${index}-${child?.[childValue]}`}
                    >
                      <div className='!min-w-[18px]'>
                        {values?.[childFieldName]?.toString() ===
                        child?.[childValue]?.toString() ? (
                          <i className='bi bi-check' />
                        ) : (
                          ''
                        )}
                      </div>
                      <MenuItem
                        className={`grow !text-[12px] !normal-case`}
                        sx={{
                          background: child?.color || '#87898E',
                          color: 'white',
                          fontWeight: 600,
                          '&:hover': {
                            opacity: 0.9,
                            background: child?.color || '#87898E',
                            color: 'black',
                          },
                          '&:focus': {
                            opacity: 0.9,
                            background: child?.color || '#87898E',
                            color: 'black',
                          },
                        }}
                        value={child?.[childValue]}
                        onClick={() =>
                          onSelectBlock(child?.[childValue], child)
                        }
                      >
                        {child?.[childLabel]}
                      </MenuItem>
                    </div>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <div>
            <span className='no-data-found-wrapper select-box'>
              <i className='bi bi-exclamation-octagon text-lg mr-1' />{' '}
              {t('common.global.noDataFound')}
            </span>
          </div>
        )}
      </Menu>
    </FormControl>
  );
};

const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, bgcolor = '#87898E' }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
      ...theme.applyStyles('dark', {
        backgroundColor: theme.palette.grey[200],
      }),
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: bgcolor,
      ...theme.applyStyles('dark', {
        backgroundColor: bgcolor,
      }),
    },
  })
);

export default memo(FormTreeSelectField);
