import { Button } from '@mui/material';
import { useQuery } from 'react-query';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BaseTable from 'components/ui/tables/BaseTable';
import useTopPanel from 'hooks/useTopPanel';
import SearchInputNavbar from 'components/SearchInputNavbar';
import BlockAddEditImageModal from './AddEditImageModal';
import LightBox from 'components/ui/dialogs/LightBox';
import IMAGE_TYPE from 'shared/imageTypeList';
import PlanSetToHomeModal from './PlanSetToHomeModal';
import useAxiosPrivate from 'hooks/useAxiosPrivate';

const homeImageType = IMAGE_TYPE['2D'].code;

const Plans = () => {
  const axiosPrivate = useAxiosPrivate();
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();
  const [refetch, setRefetch] = useState(false);
  const [blocks, setBlocks] = useState(false);
  const [openHomeLayoutImageDialog, setOpenHomeLayoutImageDialog] =
    useState(false);
  const [openBlockAddEditImageModal, setOpenBlockAddEditImageModal] =
    useState(false);
  const [openSetPlanModal, setOpenSetPlanModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setComponent(<div className='component-title'>{t('plan.title')}</div>);
  }, [i18n.language]);

  const images = useMemo(() => {
    const imgs = [];
    const titles = [];
    const captions = [];
    if (selected?.link) {
      const link = `${process.env.REACT_APP_BACKEND_URL}/${selected?.link}`;
      imgs.push(link);
      titles.push('3d image');
      captions.push('3d image');
    }
    if (selected?.link2d) {
      const link = `${process.env.REACT_APP_BACKEND_URL}/${selected?.link2d}`;
      imgs.push(link);
      titles.push('2d image');
      captions.push('2d image');
    }
    if (selected?.location) {
      const link = `${process.env.REACT_APP_BACKEND_URL}/${selected?.location}`;
      imgs.push(link);
      titles.push('location');
      captions.push('location');
    }
    return { imgs, titles, captions };
  }, [selected?.link, selected?.link2d, selected?.location]);

  const { isLoading, isFetching } = useQuery({
    queryKey: ['blockSelect', selected?.object_id, openSetPlanModal],
    queryFn: async function () {
      const response = await axiosPrivate.get(
        `/admin/home/object/${selected?.object_id}`
      );
      return response.data.data;
    },
    onSuccess: (data) => {
      if (data && data.length > 0) {
        setBlocks(data);
      } else {
        setBlocks([]);
      }
    },
    enabled: openSetPlanModal && !!selected?.object_id,
    retry: false,
  });

  return (
    <div className='component-list-wrapper'>
      <div className='component-list-header mb-2'>
        <div className='header-actions-container pt-2 pb-2 flex flex-row items-center'>
          {/* <div className='header-actions filter-box flex items-center'>
                <Button
                  variant='base'
                  startIcon={<i className='bi bi-filter' />}
                  className='!mr-2'
                  onClick={() =>
                    setExpanded((prev) => !prev)
                  }
                >
                  {t('common.button.filter')}
                </Button>
              </div> */}
          <div className='grow'>
            <SearchInputNavbar inputKey='name' fullWidth />
          </div>
          <div className='header-actions action-buttons-box flex items-center justify-center ml-4'>
            <Button
              variant='base'
              onClick={() => {
                setRefetch(true);
              }}
              disable={`${refetch}`}
            >
              <i
                className={`bi bi-arrow-repeat${
                  refetch ? ' animate-spin' : ''
                }`}
              />
            </Button>
            <Button
              variant='base'
              color='success'
              className='!ml-2'
              onClick={() => setOpenBlockAddEditImageModal(true)}
            >
              <i className='bi bi-plus-circle' />
            </Button>
            <Button
              variant='base'
              className='!ml-2'
              onClick={() => {
                setSelected({});
                setOpen(true);
              }}
            >
              <i className='bi bi-gear' />
            </Button>
          </div>
        </div>
      </div>

      <div className='component-table-wrapper h-[calc(100vh-160px)]'>
        <BaseTable
          emitRefetch={{ refetch, setRefetch }}
          dataPath='admin/plan/index'
          emitTableColumns={{ open, setOpen }}
          tableName='plan'
          headCells={[
            { code: 'name', label: t('common.table.name') },
            { code: 'objects', label: t('common.table.objectName') },
          ]}
          columns={[
            { code: 'name' },
            { code: 'objects', type: 'nested', childStr: 'name' },
          ]}
          actionsList={[
            {
              icon: 'bi bi-building-add',
              btnColor: 'info',
              setOpen: setOpenSetPlanModal,
              setData: setSelected,
            },
            {
              icon: 'bi bi-images',
              btnColor: 'info',
              setOpen: setOpenHomeLayoutImageDialog,
              setData: setSelected,
            },
            {
              icon: 'bi bi-pencil-square',
              btnColor: 'info',
              setOpen: setOpenBlockAddEditImageModal,
              setData: setSelected,
            },
          ]}
        />
      </div>

      {openSetPlanModal && (
        <PlanSetToHomeModal
          open={openSetPlanModal}
          setOpen={setOpenSetPlanModal}
          selected={selected}
          setSelected={setSelected}
          blocks={blocks}
          isLoading={isLoading || isFetching}
          setRefetch={setRefetch}
        />
      )}

      {openBlockAddEditImageModal && (
        <BlockAddEditImageModal
          open={openBlockAddEditImageModal}
          setOpen={setOpenBlockAddEditImageModal}
          setSelected={setSelected}
          itemData={selected}
          refetchFn={() => setRefetch(true)}
        />
      )}
      {openHomeLayoutImageDialog && images?.imgs?.length > 0 ? (
        // yet-another-react-lightbox
        <LightBox
          isOpen={openHomeLayoutImageDialog}
          style={{ zIndex: 1040 }}
          captions={images?.captions}
          titles={images?.titles}
          images={images?.imgs}
          photoIndex={homeImageType == IMAGE_TYPE['2D'].code ? 1 : 0}
          setIsOpen={setOpenHomeLayoutImageDialog}
        />
      ) : null}
    </div>
  );
};

export default memo(Plans);
