import React, { useEffect, memo, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import FormAutocompleteField from 'components/ui/form/FormAutocompleteField';
import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormDateField from 'components/ui/form/FormDateField';
import FormTextField from 'components/ui/form/FormTextField';
import useAxiosPrivate from 'hooks/useAxiosPrivate';

import FormNumberField from 'components/ui/form/FormNumberField';
import useFormSubmit from 'hooks/useFormSubmit';
import useTopPanel from 'hooks/useTopPanel';

const validationSchema = yup.object({
  name: yup
    .string()
    .min(3, { label: 'object.validation.nameMin', value: 3 })
    .required('object.validation.name'),
  region_id: yup.string().required('object.validation.regionId'),
  companies_id: yup.string().required('object.validation.companiesId'),
  city: yup.string().required('object.validation.city'),
  address: yup.string().required('object.validation.address'),
  start: yup
    .date()
    .nullable()
    .typeError('object.validation.startValid')
    .required('object.validation.start'),
  end: yup
    .date()
    .nullable()
    .min(yup.ref('start'), 'object.validation.endMin')
    .typeError('object.validation.endValid')
    .required('object.validation.end'),
  stage: yup.number().required('object.validation.stage'),
  padez: yup.number().required('object.validation.padez'),
});

const ObjectAddModal = ({ open, setOpen, refetch }) => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [hasError, setHasError] = useState(false);
  const { submit, isSubmitting } = useFormSubmit();
  const { setComponent } = useTopPanel();

  const formik = useFormik({
    initialValues: {
      name: '',
      region_id: '',
      companies_id: '',
      city: '',
      address: '',
      start: null,
      end: null,
      stage: '',
      padez: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (id)
        submit(
          { type: 'put', contentType: 'formData' },
          values,
          '/admin/object',
          values.name,
          id,
          false,
          () => {
            handleClose();
            refetch?.();
          }
        );
      else
        submit(
          { type: 'post', contentType: 'formData' },
          values,
          '/admin/object',
          values.name,
          null,
          false,
          () => {
            handleClose();
            refetch?.();
          }
        );
    },
  });
  const { isLoading, isFetching } = useQuery({
    queryKey: 'objectSingle',
    queryFn: async function () {
      const response = await axiosPrivate.get(`/admin/object/edit/${id}`);
      return response.data.data;
    },
    onSuccess: (data) => {
      formik.setValues({
        name: data.name,
        region_id: data.region_id,
        companies_id: data.companies_id,
        city: data.city,
        address: data.address,
        start: data.start,
        end: data.end,
        stage: data.stage,
        padez: data.padez,
      });
    },
    enabled: !hasError && !!id,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  useEffect(() => {
    setComponent(
      <div className='component-title'>
        {id ? (
          <span>{t('object.editTitle')}</span>
        ) : (
          <span>{t('object.addTitle')}</span>
        )}
      </div>
    );
  }, [i18n.language]);

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='lg'
      disableEscapeKeyDown={true}
      className='!base-border !shadow-none'
      PaperProps={{
        sx: { boxShadow: 'none', borderRadius: '25px', maxWidth: 671 },
      }}
    >
      <DialogTitle
        id='alert-dialog-title'
        className='!bg-[#CDCDCDC7] !text-center !border-b !border-b-[#E6E6E6]'
      >
        <span className='pr-5 font-semibold text-[21px] text-[#374957]'>
          {t('object.addTitle')}
        </span>
      </DialogTitle>

      <DialogContent>
        {isLoading || isFetching ? (
          <div className='circular-progress-box py-5'>
            <CircularProgress size={35} />
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              spacing={{ xs: 2, sm: 3, lg: 3 }}
              rowSpacing={1}
              columns={{ xs: 12, sm: 12, lg: 12 }}
            >
              <Grid item={true} lg={6} sm={6} xs={12}>
                <FormTextField
                  delay={0.1}
                  label={t('common.fields.objectName')}
                  fieldName='name'
                  formik={formik}
                />
              </Grid>

              <Grid item={true} lg={6} sm={6} xs={12}>
                <FormAutocompleteField
                  delay={0.2}
                  fieldName='companies_id'
                  label={t('common.fields.company')}
                  formik={formik}
                  path={'/dictionary/companies'}
                />
              </Grid>

              <Grid item={true} lg={6} sm={6} xs={12}>
                <FormAutocompleteField
                  delay={0.3}
                  fieldName='region_id'
                  label={t('common.fields.region')}
                  formik={formik}
                  path={'/dictionary/regions'}
                />
              </Grid>

              <Grid item={true} lg={6} sm={6} xs={12}>
                <FormTextField
                  delay={0.4}
                  label={t('common.fields.city')}
                  fieldName='city'
                  formik={formik}
                />
              </Grid>

              <Grid item={true} lg={12} sm={12} xs={12}>
                <FormTextField
                  delay={0.5}
                  label={t('common.fields.address')}
                  fieldName='address'
                  formik={formik}
                />
              </Grid>

              <Grid item={true} lg={6} sm={6} xs={12}>
                <FormDateField
                  delay={0.6}
                  label={t('common.fields.startTime')}
                  formik={formik}
                  fieldName='start'
                />
              </Grid>

              <Grid item={true} lg={6} sm={6} xs={12}>
                <FormDateField
                  delay={0.7}
                  label={t('common.fields.endTime')}
                  formik={formik}
                  fieldName='end'
                />
              </Grid>

              <Grid item={true} lg={6} sm={6} xs={12}>
                <FormNumberField
                  delay={0.8}
                  label={t('common.fields.stage')}
                  fieldName='stage'
                  formik={formik}
                />
              </Grid>

              <Grid item={true} lg={6} sm={6} xs={12}>
                <FormNumberField
                  delay={0.9}
                  label={t('common.fields.blocksNumber')}
                  fieldName='padez'
                  formik={formik}
                />
              </Grid>

              <Grid item={true} sm={12} xs={12}>
                <div className='flex justify-end'>
                  <FormActionButtons
                    delay={1.1}
                    isSubmitting={isSubmitting}
                    formType='dialog'
                    setOpen={setOpen}
                    reset={formik.resetForm}
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default memo(ObjectAddModal);
