import { Button, Tab, Tabs } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useTopPanel from 'hooks/useTopPanel';
import TaskFilterDrawer from 'components/dashboard/TaskFilterDrawer';
import Columns from './items/Columns';
import Table from './items/Table';
import Calendar from './items/Calendar';
import BotSettingsModal from './items/BotSettingsModal';

const Tasks = () => {
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [openBot, setOpenBot] = useState(false);
  const [tabIndex, setTabIndex] = useState(1);

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    setComponent(
      <div className='component-title'>{t('sidebar.admin.tasks')}</div>
    );
  }, [i18n.language]);

  return (
    <div className='component-list-wrapper'>
      <div className='component-list-header mb-2'>
        <div className='header-actions-container py-3 flex flex-row items-center'>
          <div className='header-actions filter-box flex items-center base-border bg-white px-4 py-0 w-full grow'>
            <div>
              <Tabs
                value={tabIndex}
                onChange={handleChangeTab}
                sx={{ minHeight: 45 }}
              >
                <Tab
                  label={t('tasks.tabs.column')}
                  icon={<i className='bi bi-layout-three-columns' />}
                  sx={{ minHeight: 45 }}
                  iconPosition='end'
                  value={1}
                />
                <Tab
                  label={t('tasks.tabs.table')}
                  icon={<i className='bi bi-table' />}
                  sx={{ minHeight: 45 }}
                  iconPosition='end'
                  value={2}
                />
                <Tab
                  label={t('tasks.tabs.calendar')}
                  icon={<i className='bi bi-calendar-day' />}
                  sx={{ minHeight: 45 }}
                  iconPosition='end'
                  value={3}
                />
              </Tabs>
            </div>
          </div>
          <div className='ml-3 flex gap-2 justify-end items-center'>
            <Button
              variant='base'
              color='primary'
              startIcon={<i className='bi bi-filter' />}
              onClick={() => setExpanded(true)}
            >
              {t('common.button.filter')}
            </Button>
            <Button variant='base' onClick={() => setOpenBot(true)}>
              <i className='bi bi-android2' />
            </Button>
          </div>
        </div>
      </div>
      {tabIndex === 1 && <Columns />}
      {tabIndex === 2 && <Table />}
      {tabIndex === 3 && <Calendar />}
      <TaskFilterDrawer open={expanded} setOpen={setExpanded} />
      <BotSettingsModal open={openBot} setOpen={setOpenBot} />
    </div>
  );
};
export default memo(Tasks);
