import moment from 'moment';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
// import { patternFormatter } from 'react-number-format';

const CRMMessage = ({ data = {} }) => {
  const { t } = useTranslation();
  return (
    <div className='data-change-wrapper flex items-center'>
      <div
        className={`data-change-icon text-base leading-4 border-2 ${
          data?.status === 'DELIVERED' ? 'border-teal-400' : 'border-orange-400'
        } text-teal-500 rounded-full w-[42px] h-[42px] min-w-[42px] min-h-[42px] flex items-center justify-center mr-3`}
      >
        {data?.status === 'DELIVERED' ? (
          <i className='bi bi-envelope-check text-lg leading-4'></i>
        ) : (
          <i className='bi bi-envelope-arrow-up text-lg leading-4 text-orange-500'></i>
        )}
      </div>
      <div className='data-change-details w-full'>
        <div className='text-[12px] text-gray-500'>
          {moment(data?.created_at).format('DD.MM.YYYY HH:mm')},{' '}
          {data?.staff?.id ? data?.staff?.name : t('common.table.system')}{' '}
          {t('crm.leadHistory.fromUser')} {data?.phone}{' '}
          {t('crm.leadHistory.to')}
        </div>
        <div className='mt-2'>
          <span className='change-value old-value break-all py-1 px-1.5 mx-1 rounded-lg text-gray-500 text-[13px] leading-4'>
            {data?.text}
          </span>
        </div>
      </div>
    </div>
  );
};
export default memo(CRMMessage);
