import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { memo, useEffect, useState } from 'react';

import CrmFilterDrawer from 'components/dashboard/CrmFilterDrawer';
import StaffFilter from 'components/ui/filters/items/StaffFilter';
import CRMBlocksMenu from './blocks/CRMBlocksMenu';
import CRMActionsDropDown from './CRMActionsDropDown';
import CRMLeadAdd from './lead-actions/CRMLeadAdd';
import CRMLeadSearch from './lead-actions/CRMLeadSearch';
import useAuth from 'hooks/useAuth';
import useSearch from 'hooks/useSearch';
import useSetQuery from 'hooks/useSetQuery';
import useMenu from 'hooks/useMenu';

const CRMTopPanel = ({
  addBlock = false,
  addLead = false,
  openSettings,
  setOpenSettings,
}) => {
  const { t } = useTranslation();
  const [openFilter, setOpenFilter] = useState(false);
  const query = useSearch();
  const setQuery = useSetQuery();
  const [{ user }] = useAuth();
  const { hasChild } = useMenu();

  const view = (code) => hasChild('CRM', code);

  useEffect(() => {
    if (view('AUTO_FILTER_STAFF') && !query.get('staff')) {
      setQuery('staff', user?.user?.id);
    }
  }, []);

  return (
    <div className='crm-top-panel-wrapper  min-h-[56px] !w-[calc(100%+32px)] px-4 bg-[#F8F9FB] border-b border-b-[#E5E9EB] -mt-2 -ml-4 !-mr-4'>
      <CRMBlocksMenu addAllowed={addBlock} openSettings={openSettings} />
      {!openSettings && (
        <>
          <div className='ml-2'>
            <Button
              variant='contained'
              onClick={() => setOpenFilter(!openFilter)}
              color='info'
              className='h-10 !min-w-[40px] !w-[40px]'
            >
              {/* <span className="text-base font-normal">
						{t("common.button.filter")}
					</span> */}
              <i className='bi bi-filter text-xl' />
            </Button>
          </div>
          <div className='flex ml-24 items-center [&>*]:mr-3'>
            <div className='2xl:w-[200px] w-[150px]'>
              <StaffFilter />
            </div>
            <div>
              <CRMLeadSearch />
            </div>
            <div>
              <CRMLeadAdd />
            </div>
            <div>
              <CRMActionsDropDown />
            </div>
            {view('CRM_ADD_BLOCK_ITEM') && (
              <div>
                <Button
                  variant='contained'
                  onClick={() => setOpenSettings((prv) => !prv)}
                  color='info'
                >
                  {t('common.button.columnSettings')}
                </Button>
              </div>
            )}

            <CrmFilterDrawer open={openFilter} setOpen={setOpenFilter} />
          </div>
        </>
      )}
      {openSettings && (
        <div className='flex grow justify-end gap-3'>
          <Button
            variant='contained'
            color='success'
            size='small'
            onClick={() => setOpenSettings(false)}
            // disabled={isSubmitting}
            className='!bg-[#FF6D6D] !font-normal'
          >
            {t('common.button.cancel')}
          </Button>
          <Button
            color='success'
            variant='contained'
            size='small'
            className='!bg-[#16BA5C] !mr-2 !font-normal'
            // onClick={() => sendComment()}
            // disabled={isSubmitting}
          >
            {/* {isSubmitting && (
                    <CircularProgress
                      size={15}
                      color='inherit'
                      className='mr-1'
                    />
                  )} */}
            {t('common.button.save')}
          </Button>
        </div>
      )}
    </div>
  );
};
export default memo(CRMTopPanel);
