import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTopPanel from 'hooks/useTopPanel';
import {
  ShowroomPanel,
  // ShowroomTopFilter,
  ShowroomTopPanel,
} from './components';

export default memo(function Showroom() {
  const { setComponent } = useTopPanel();
  const { t, i18n } = useTranslation();
  const [tabValue, setTabValue] = useState(
    localStorage.getItem('showroom-tab') || '1'
  );

  useEffect(() => {
    setComponent(<div className='component-title'>{t('showroom.title')}</div>);
  }, [i18n.language]);

  return (
    <div className='h-full'>
      <div className='base-border px-4 py-1 bg-white'>
        <ShowroomTopPanel tabValue={tabValue} setTabValue={setTabValue} />
      </div>
      <div className='h-[calc(100%-80px)]'>
        {/* {tabValue == 3 && (
          <ShaxmatkaVisualView toggleHomeItem={toggleVisualViewHomeItem} />
        )} */}
        {/* {tabValue != 3 && <ShowroomPanel tabValue={tabValue} />} */}
        <ShowroomPanel tabValue={tabValue} />
      </div>
    </div>
  );
});
